import React, { useEffect, useState, memo } from "react";
import "./databaseTable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { GoArrowDownLeft } from "react-icons/go";
import { GoArrowUpRight } from "react-icons/go";
import { Console } from "console";
import { relative } from "path";
interface tableProps {
  dataRow: any;
  loading: boolean;
}
const CustomNoRowsOverlay = () => (
  <div style={{ background:'#F6F8FA', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
    <p>No records found</p>
  </div>
);
const DatabaseTable = memo((props: tableProps) => {
  const { dataRow } = props;
  const capitalizeFirstLetterOfEachWord = (str:any) => {
    // return str.replace(/\b\w/g, (char:any) => char.toUpperCase());
    return str
    .toLowerCase()
    .replace(/\b\w/g, (char:any) => char.toUpperCase());
  };
  const columns = [
    { field: "sno", headerName: "S.NO", width: 50,cellClassName: 'custom-cell' },
    { field: "id", headerName: "ID", width: 150,cellClassName: 'custom-cell' },
    { field: "createdAt", headerName: "Created At", width: 200,cellClassName: 'custom-cell' },
    { field: "customer", headerName: "Name", width: 200,cellClassName: 'custom_cell_name',
    renderCell: (params: any) => (
     <>
      <div className="custom_cell_style">
     {capitalizeFirstLetterOfEachWord(params?.row?.customer)}
        
      </div>
      </>
    ),
  },
    { field: "type", headerName: "Type", width: 200,cellClassName: 'custom-cell',
    renderCell: (params: any) => {
      // <div>--</div>
      let filteredData = params?.row.type;
      if(filteredData == 'Debit'){
        return <span style={{color:'red',fontFamily:'lexend'}} className="debit flex"> <GoArrowDownLeft className="mr-2"/> {filteredData}</span>;
      }else{
        return <span style={{color:'green',fontFamily:'lexend'}} className="credit flex"><GoArrowUpRight className="mr-2" /> {filteredData}</span>;
      }
   
    }
  },
    { field: "razorPayId", headerName: "Transaction Id", width: 250,cellClassName: 'custom-cell',
  
    renderCell: (params: any) => {
      // <div>--</div>
      let filteredData = params?.row?.razorPayId;
      return filteredData == 'undefined' ? (
        <div>-</div>
      ): ( <span style={{fontFamily:'lexend',fontWeight:'400'}}>{params?.row?.razorPayId}</span> )
      // return currentStatus === true ? (
      //   <div>-</div>
      // ) : (
      //   <span>{params.row.endTime}</span>
      // );
    }
  },
    { field: "amount", headerName: "Amount", width: 150,cellClassName: 'custom-cell' },

    // { field: "orderId", headerName: "Charge Id", width: 150 },

    { field: "detail", headerName: "Detail", width: 300,cellClassName: 'custom-cell',
  
    renderCell: (params: any) => {
      console.log("params",params);
      
      let filteredData = params?.formattedValue;
      return filteredData == 'undefined' ? (
        <div>-</div>
      ): ( <span style={{fontFamily:'lexend',fontWeight:'400'}}>{filteredData}</span> ) 
  }

    }
    // { field: "chargeBy", headerName: "Charge By", width: 100 },

    // { field: "date", headerName: "Date", width: 100 },
  ];
  return (
    <div className="databaseTable" style={{fontFamily:'lexend'}}>
      <DataGrid
        rows={dataRow}
        columns={columns}
        pageSize={100}
        hideFooterPagination
        hideFooter
        rowsPerPageOptions={[5]}
        checkboxSelection={false}
        disableSelectionOnClick
        autoHeight
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        loading={props.loading}
      />
    </div>
  );
});

export default DatabaseTable;
