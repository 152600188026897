import { TextField, Chip } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import DatePicker from "react-datepicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { memo, useEffect, useState } from "react";
import DatabaseTable from "./databasetable";
import "./bookings.scss";
import moment from "moment";
import Widget from "./widget";
const Bookings = memo(() => {
  
  const [tableData, setTableData] = useState<any>([]);
  const [immutatedTableData, setImmutedTableData] = useState<any>([]);

  const currentUser: any = Parse.User.current();
  const [tableLoading, setTableLoading] = useState(false);

  // Open Custom Date
  const [startDateFilter, setStartDateFilter] = useState<any>("");
  const [endDateFilter, setEndDateFilter] = useState<any>("");
  const [openCustom, setOpenCustom] = useState<boolean>(false);
  const [dateChips, setDateFilterChips] = useState<any[]>([]);
  const [dateRangeChipVisible, setDateRangeChipVisible] =
    useState<boolean>(false);
  const [SingleDateChips, setSingleDateFilterChips] = useState<string | null>(
    null
  );
  const [total_Bookings, setTotalBookings] = useState({
    thisMonth: 0,
    lastMonth: 0,
    total: 0,
  });
  const [upComing_Bookings, setUpComingBookings] = useState({
    thisMonth: 0,
    lastMonth: 0,
    total: 0,
  });
  const [cancelled_Bookings, setCancelled_Bookings] = useState({
    thisMonth: 0,
    lastMonth: 0,
    total: 0,
  });
  const [expired_Bookings, setExpired_Bookings] = useState({
    thisMonth: 0,
    lastMonth: 0,
    total: 0,
  });
  const [selectedType, setSelectedType] = useState("");
  const [dateFilter, setDateFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  // Open Custom Date
  const handleCustomChange = () => {
    setOpenCustom(!openCustom);
  };
  const handleDateChange = (value: string) => {
    // Find if the selected date is already in the chips
    const existingChip = dateChips.find((chip) => chip.label === value);

    // If the selected date is not in the chips, update the chips array
    if (!existingChip) {
      // Remove the previous date chip if it exists
      const updatedChips = dateChips.filter((chip) => chip.id !== dateFilter);

      // Add the selected date to the chips array
      setDateFilterChips([...updatedChips, { id: value, label: value }]);
    }

    // Update the dateFilter state
    setDateFilter((prevValue) => (prevValue === value ? "" : value));
  };

  const handleStatusChange = (value: string) => {
    setStatusFilter(value);
  };

  // Date Filter code
  const dateRangeChange = (dates: any) => {
    const [start, end] = dates;
    setStartDateFilter(start);
    setEndDateFilter(end);
    setDateRangeChipVisible(!!start && !!end);
    setSingleDateFilterChips(start);
  };

  // Chip delete
  const handleDeleteDateChip = (chipId: string) => {
    setDateFilterChips((prevChips) =>
      prevChips.filter((chip) => chip.id !== chipId)
    );
    setDateFilter("");

    // Uncheck the radio button when deleting the chip
    setSelectedType("");
  };

  const handleSingleDeleteDateChip = (chipId: string) => {
    setStartDateFilter(null);
    setEndDateFilter(null);
    setSingleDateFilterChips(null);
  };

  const handleDateRangeDelete = () => {
    setStartDateFilter(null);
    setEndDateFilter(null);
    setDateRangeChipVisible(false);
  };

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const handleFilterButtonClick = () => {
    // Toggle filter visibility
    setIsFilterVisible((prev) => !prev);
  };

  const [selectedValue, setSelectedValue] = useState("a");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

// ! ||--------------------------------------------------------------------------------||
// ! ||                              Get All Booking Data                              ||
// ! ||--------------------------------------------------------------------------------||
  const getAllBookingsData = () => {
    setTableLoading(true);
    const parseQuery = new Parse.Query("Bookings");
    parseQuery.descending("createdAt");
    parseQuery.include("User");
    parseQuery.include("ChargePoint");
    parseQuery.limit(100);
    if (currentUser) {
      var innerQuery = new Parse.Query("Chargers");

      innerQuery.equalTo("CPO", currentUser.get("CPO"));
      parseQuery.matchesQuery("ChargePoint", innerQuery);
    }

    if (dateFilter) {
      if (dateFilter === "Today") {
        parseQuery.greaterThanOrEqualTo(
          "Date",
          new Date(moment().startOf("day").toString())
        );
        parseQuery.lessThanOrEqualTo(
          "Date",
          new Date(moment().add(1, "day").startOf("day").toString())
        );
      } else if (dateFilter === "This Week") {
        parseQuery.greaterThanOrEqualTo(
          "Date",
          new Date(moment().startOf("week").toString())
        );
        parseQuery.lessThanOrEqualTo(
          "Date",
          new Date(moment().endOf("week").toString())
        );
      } else if (dateFilter === "This Month") {
        parseQuery.greaterThanOrEqualTo(
          "Date",
          new Date(moment().startOf("month").toString())
        );
        parseQuery.lessThanOrEqualTo(
          "Date",
          new Date(moment().endOf("month").toString())
        );
      } else if (dateFilter === "This Year") {
        parseQuery.greaterThanOrEqualTo(
          "Date",
          new Date(moment().startOf("year").toString())
        );
        parseQuery.lessThanOrEqualTo(
          "Date",
          new Date(moment().endOf("year").toString())
        );
      }
    }

    if (startDateFilter) {
      // Apply single date filter
      parseQuery.greaterThanOrEqualTo(
        "Date",
        new Date(moment(startDateFilter).startOf("day").toString())
      );
      parseQuery.lessThanOrEqualTo(
        "Date",
        new Date(moment(startDateFilter).endOf("day").toString())
      );
    }
    if (startDateFilter && endDateFilter) {
      parseQuery.greaterThanOrEqualTo("Date", new Date(startDateFilter));
      parseQuery.greaterThanOrEqualTo("Date", new Date(startDateFilter));
      const adjustedEndDate = moment(endDateFilter).endOf("day").toDate();
      parseQuery.lessThanOrEqualTo("Date", adjustedEndDate);
    }

    parseQuery.find().then((result: any[]) => {
      let newRow: any[] = [];
      let totalBookings = 0;
      let upComingBooking = 0;
      let cancelledBooking = 0;
      let expiredBooking = 0;
      const currentDate = moment();
      result.forEach((item, index) => {
        totalBookings++;
        const cancelled = item.get("isCancelled");
        if (cancelled) {
          cancelledBooking++;
        }

        const bookingDate = new Date(item.get("Date"));
        const startTimes = item.get("StartTime");
        const formattedDate = bookingDate.toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });

        // Convert startTime to 24-hour format for proper parsing
        const formattedTime = moment(startTimes, ["h:mm A"]).format("HH:mm");
        const formattedDateTime = `${formattedDate} ${formattedTime}`;

        const bookingStartTime = moment(formattedDateTime, "MMM D, YYYY HH:mm");
        if (bookingStartTime.isBefore(currentDate) && cancelled == false) {
          expiredBooking++;
        }

        if (bookingStartTime.isAfter(currentDate) && cancelled == false) {
          upComingBooking++;
        }

        const Duration = item.get("Duration");
        const hoursMatch = Duration.match(/(\d+)\s*Hours?/i);
        const minutesMatch = Duration.match(/(\d+)\s*Mins?/i);

        const removeHours = hoursMatch ? parseInt(hoursMatch[1], 10) * 60 : 0;
        const removeMinutes = minutesMatch ? parseInt(minutesMatch[1], 10) : 0;

        const totalMinutes = removeHours + removeMinutes;

        const newDateTime = moment(formattedDateTime, "MMM D, YYYY HH:mm").add(
          totalMinutes,
          "minutes"
        );

        const formattedEndDateTime = moment(newDateTime, ["h:mm A"]).format(
          "MMM D, YYYY HH:mm"
        );
        let url = "/images/placeholder.png";
        let file = item.get("Image");
        if (file != null) {
          url = file.url();
        }
        let logoUrl = "/images/placeholder.png";

        let logoFile = item.get("Brand") ? item.get("Brand").get("Logo") : "";
        if (logoFile) {
          logoUrl = logoFile.url();
        }

        newRow.push({
          id: index + 1,
          name: `${item.get("User")?.get("FullName")}`,
          mobileNumber: `${
            item.get("User")?.get("Phone")
              ? item.get("User")?.get("Phone")
              : "-"
          }  `,
          startTime: `${formattedDateTime}`,
          endTime: `${formattedEndDateTime}`,
          serial: `${item.get("ChargePoint")?.get("Serial")}`,
          duration: `${item.get("Duration")}`,
          status: `${
            moment().isAfter(item.get("createdAt")) && !item.get("isCancelled")
          }`,
          upcoming: `${
            moment().isBefore(formattedDateTime) && !item.get("isCancelled")
          }`,

          expired: `${
            moment().isAfter(formattedDateTime) && !item.get("isCancelled")
          }`,
          cancelled: `${item.get("isCancelled")}`,
          obj: item,
        });
      });
      setTableData(newRow);
      setImmutedTableData(newRow);

      setTableLoading(false);

      setTotalBookings({
        thisMonth: 0,
        lastMonth: 0,
        total: totalBookings,
      });
      setCancelled_Bookings({
        thisMonth: 0,
        lastMonth: 0,
        total: cancelledBooking,
      });
      setExpired_Bookings({
        thisMonth: 0,
        lastMonth: 0,
        total: expiredBooking,
      });
      setUpComingBookings({
        thisMonth: 0,
        lastMonth: 0,
        total: upComingBooking,
      });

      let ScheduledCount = sessionStorage.setItem(
        "ScheduledCount",
        upComingBooking.toString()
      );

      if (statusFilter) {
        if (statusFilter == "Upcoming") {
          const filteredData = newRow.filter(
            (item: any) => item.upcoming === "true"
          );
          setTableData(filteredData);
        } else if (statusFilter == "Expired") {
          const filteredData = newRow.filter(
            (item: any) => item.expired === "true"
          );
          setTableData(filteredData);
        } else if (statusFilter == "Cancelled") {
          const filteredData = newRow.filter(
            (item: any) => item.cancelled === "true"
          );
          setTableData(filteredData);
        }
      }
    });
  };

// ! ||--------------------------------------------------------------------------------||
// ! ||                               Get Widget Function                              ||
// ! ||--------------------------------------------------------------------------------||
  const getWidgetsData = async () => {
    const parseQuery = new Parse.Query("Bookings");
    parseQuery.descending("createdAt");
    parseQuery.include("User");
    parseQuery.include("ChargePoint");

    // dateFilter
    if (dateFilter) {
      if (dateFilter === "Today") {
        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment().startOf("day").toString())
        );
        parseQuery.lessThanOrEqualTo(
          "createdAt",
          new Date(moment().add(1, "day").startOf("day").toString())
        );
      } else if (dateFilter === "This Week") {
        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment().startOf("week").toString())
        );
        parseQuery.lessThanOrEqualTo(
          "createdAt",
          new Date(moment().endOf("week").toString())
        );
      } else if (dateFilter === "This Month") {
        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment().startOf("month").toString())
        );
        parseQuery.lessThanOrEqualTo(
          "createdAt",
          new Date(moment().endOf("month").toString())
        );
      } else if (dateFilter === "This Year") {
        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment().startOf("year").toString())
        );
        parseQuery.lessThanOrEqualTo(
          "createdAt",
          new Date(moment().endOf("year").toString())
        );
      }
    }

    if (startDateFilter) {
      // Apply single date filter
      parseQuery.greaterThanOrEqualTo(
        "createdAt",
        new Date(moment(startDateFilter).startOf("day").toString())
      );
      parseQuery.lessThanOrEqualTo(
        "createdAt",
        new Date(moment(startDateFilter).endOf("day").toString())
      );
    }
    if (startDateFilter && endDateFilter) {
      parseQuery.greaterThanOrEqualTo("createdAt", new Date(startDateFilter));
      parseQuery.greaterThanOrEqualTo("createdAt", new Date(startDateFilter));
      const adjustedEndDate = moment(endDateFilter).endOf("day").toDate();
      parseQuery.lessThanOrEqualTo("createdAt", adjustedEndDate);
    }
    parseQuery.limit(5000);
    await parseQuery.find().then((result) => {
      let totalBooking = 0;
      let upComingBooking = 0;
      let cancelledBooking = 0;
      const currentDate = moment();

      result.forEach((item: any, index) => {
        totalBooking++;
        const cancelled = item.get("isCancelled");
        if (cancelled == true) {
          cancelledBooking++;
        }
        const bookingDate = new Date(item.get("Date"));
        const startTimes = item.get("StartTime");
        const formattedDate = bookingDate.toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });

        const formattedTime = moment(startTimes, ["h:mm A"]).format("HH:mm");
        const formattedDateTime = `${formattedDate} ${formattedTime}`;

        const bookingStartTime = moment(formattedDateTime, "MMM D, YYYY HH:mm");

        if (bookingStartTime.isAfter(currentDate)) {
          upComingBooking++;
        }
      });
    });
  };

  useEffect(() => {
    getAllBookingsData();
  }, [dateFilter, statusFilter, startDateFilter, endDateFilter]);
  useEffect(() => {
    getWidgetsData();
  }, [statusFilter, dateFilter, startDateFilter, endDateFilter]);

  return (
    <>
      <div className="transaction-container">
        <div className="transaction_table_container flex">
          <div style={{ width: "100%" }}>
            <div className="flex justify-between mx-6 my-5 ">
              <h2 className="transaction_heading mt-2 ml-4">Bookings</h2>
              <button
                className="filter_button rounded-full p-3 border-2 border-black-600"
                onClick={handleFilterButtonClick}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M3 3H15V4.629C14.9999 5.02679 14.8418 5.40826 14.5605 5.6895L11.25 9V14.25L6.75 15.75V9.375L3.39 5.679C3.13909 5.40294 3.00004 5.0433 3 4.67025V3Z"
                    stroke="#111111"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>

            <div className="flex filter -mt-4 ml-8 mb-4">
              <form>
                <label
                  className={`${
                    statusFilter === "Upcoming" ? "Upcoming_Label" : "Label"
                  }`}
                >
                  <input
                    className={`${
                      statusFilter === "Upcoming"
                        ? "Upcoming_Custom_Radio"
                        : "Custom_Radio"
                    }`}
                    type="radio"
                    name="radio"
                    checked={statusFilter === "Upcoming"}
                    onChange={() => handleStatusChange("Upcoming")}
                  />
                  <span
                    className={`${
                      statusFilter === "Upcoming"
                        ? "Upcoming_Custom_label"
                        : "Custom_label"
                    }`}
                  >
                    Upcoming
                  </span>
                </label>

                <label
                  className={`${
                    statusFilter === "Expired" ? "Expired_Label" : "Label"
                  }`}
                >
                  <input
                    className={`${
                      statusFilter === "Expired"
                        ? "Expired_Custom_Radio"
                        : "Custom_Radio"
                    }`}
                    type="radio"
                    name="radio"
                    onChange={() => handleStatusChange("Expired")}
                  />
                  <span
                    className={`${
                      statusFilter === "Expired"
                        ? "Expired_Custom_label"
                        : "Custom_label"
                    }`}
                  >
                    Expired
                  </span>
                </label>

                <label
                  className={`${
                    statusFilter === "Cancelled" ? "Cancelled_Label" : "Label"
                  }`}
                >
                  <input
                    className={`${
                      statusFilter === "Cancelled"
                        ? "Cancelled_Custom_Radio"
                        : "Custom_Radio"
                    }`}
                    type="radio"
                    name="radio"
                    onChange={() => handleStatusChange("Cancelled")}
                  />
                  <span
                    className={`${
                      statusFilter === "Cancelled"
                        ? "Cancelled_Custom_label"
                        : "Custom_label"
                    }`}
                  >
                    Cancelled
                  </span>
                </label>
              </form>
              {!dateRangeChipVisible && SingleDateChips && startDateFilter && (
                <Chip
                  label={`Date : ${moment(startDateFilter).format(
                    "DD-MM-YYYY"
                  )}`}
                  onDelete={handleSingleDeleteDateChip}
                  variant="outlined"
                  sx={{ marginLeft: "5px" }}
                />
              )}

              {dateChips.map((chip) => (
                <Chip
                  key={chip.id}
                  label={chip.label}
                  onDelete={() => handleDeleteDateChip(chip.id)}
                  variant="outlined"
                  sx={{ marginLeft: "5px" }}
                />
              ))}

              {dateRangeChipVisible && (
                <Chip
                  label={`Date Between : ${moment(startDateFilter).format(
                    "DD-MM-YYYY"
                  )} - ${moment(endDateFilter).format("DD-MM-YYYY")}`}
                  onDelete={handleDateRangeDelete}
                  variant="outlined"
                  sx={{ marginLeft: "5px" }}
                />
              )}
            </div>

            {/* Widgets */}
            <div className="flex ml-3">
              <Widget type="Total_Bookings" data={total_Bookings} />
              <Widget type="Upcoming_Bookings" data={upComing_Bookings} />
              <Widget type="Cancelled_Bookings" data={cancelled_Bookings} />
              <Widget type="Expired_Bookings" data={expired_Bookings} />
            </div>
            <div className="table_Users mx-3">
              {/* Table */}
              <DatabaseTable
                dataRow={tableData}
                refresh={() => getAllBookingsData()}
                loading={tableLoading}
              />
            </div>
          </div>
          {/* Filter Code */}
          {isFilterVisible && (
            <div className="filters lg:w-1/3 flex flex-col  border-2 border-black-400">
              <div className="filter_header z-50 sticky top-0 right-0 flex justify-between px-4 py-5 mb-8">
                <h1 className="font-semibold font-lexend text-lg">Filters</h1>
                <button
                  style={{
                    background: "#1AC47D",
                    width: "35px",
                    height: "35px",
                    padding: "5px",
                    borderRadius: "50%",
                    border: "3px solid #1AAD70",
                  }}
                  onClick={handleFilterButtonClick}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M3.33301 8.00033L6.66634 11.3337L13.333 4.66699"
                      stroke="white"
                      stroke-width="1.33333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>

              <div className="px-4">
                <div className="-mt-4">
                  <h1 className="font-semibold font-lexend text-lg">Date</h1>
                  <div className="flex">
                    <input
                      type="radio"
                      className="mr-3"
                      checked={dateFilter === "Today"}
                      onChange={() => handleDateChange("Today")}
                    />
                    <label htmlFor="" className="font-lexend">
                      Today
                    </label>
                  </div>
                  <div className="flex">
                    <input
                      type="radio"
                      className="mr-3"
                      checked={dateFilter === "This Week"}
                      onChange={() => handleDateChange("This Week")}
                    />
                    <label htmlFor="" className="font-lexend">
                      This Week
                    </label>
                  </div>
                  <div className="flex">
                    <input
                      type="radio"
                      className="mr-3"
                      checked={dateFilter === "This Month"}
                      onChange={() => handleDateChange("This Month")}
                    />
                    <label htmlFor="" className="font-lexend">
                      This Month
                    </label>
                  </div>
                  <div className="flex">
                    <input
                      type="radio"
                      className="mr-3"
                      checked={dateFilter === "This Year"}
                      onChange={() => handleDateChange("This Year")}
                    />
                    <label htmlFor="" className="font-lexend">
                      This Year
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      className="rounded-checkbox accent-black-500 mr-3"
                      // checked={dateFilter === "Custom"}
                      onChange={handleCustomChange}
                    />
                    <label htmlFor="" className="font-lexend">
                      Custom Date
                    </label>
                  </div>
                </div>
                {/* Open custom date picker for select range */}
                {openCustom && (
                  <div className="dateSec mb-4">
                    <h1 className="mt-4 mb-3 font-lexend">Custom</h1>
                    <div className="start_dateContainer mt-4">
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <MuiDatePicker
                          label="Start Date"
                          value={startDateFilter}
                          onChange={dateRangeChange}
                          disabled
                          className="mt-4"
                          renderInput={(params) => (
                            <TextField {...params} error={false} />
                          )}
                          inputFormat="DD-MM-YYYY"
                        />
                      </LocalizationProvider>
                    </div>
                    <div className="endDate_container mt-4">
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <MuiDatePicker
                          label="End Date"
                          disabled
                          value={endDateFilter}
                          onChange={(item) => {
                            setEndDateFilter(item || "");
                            setDateRangeChipVisible(
                              !!startDateFilter && !!item
                            );
                          }}
                          renderInput={(params) => (
                            <TextField {...params} error={false} />
                          )}
                          inputFormat="DD-MM-YYYY"
                        />
                      </LocalizationProvider>
                    </div>

                    <div className="rangePicker_container mt-4">
                      {/* DatePicker code */}
                      <DatePicker
                        selected={startDateFilter}
                        onChange={dateRangeChange}
                        startDate={startDateFilter}
                        endDate={endDateFilter}
                        selectsRange
                        inline
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        minDate={new Date(2022, 0, 1)}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
});

export default Bookings;
