import { Autocomplete, TextField, Checkbox, Chip, List } from "@mui/material";
import moment from "moment";
import { memo, useCallback, useEffect, useState } from "react";
import "./energy.scss";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import TripOriginIcon from "@mui/icons-material/TripOrigin";
import Widget from "./widget";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Bar,
  BarChart,
} from "recharts";
import Accordion, { AccordionSlots } from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fade from "@mui/material/Fade";
import ReactApexChart from "react-apexcharts";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ColorScaleLegend from "./apexChart";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
interface optionObj {
  id: string;
  label: string;
}

interface UserFilter {
  id: string;
  label: string;
}
interface LocationFilter {
  id: string;
  label: string;
}

const Energy = memo(() => {
  // Accordion Code
  const [expanded, setExpanded] = useState(false);
  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  // Widget Data
  const [energyConsumed, setEnergyConsumed] = useState({
    thisMonth: 0,
    lastMonth: 0,
    total: 0,
  });

  const [avEnergy, setAvEnergy] = useState(0);
  const [energyCost, setEnergyCost] = useState(0);
  const [chartData, setChartData] = useState<any>(0);
  const [lineChartData, setLineChartData] = useState<any>(0);
  const [avCostPerKw, setAvCostPerKw] = useState(0);
  const [allOperators, setAllOperators] = useState<any | null>([]);
  const [dateChips, setDateFilterChips] = useState<any[]>([]);
  const [locationFilter, setLocationFilter] = useState<LocationFilter[]>([]);
  const [locationFilterId, setLocationFilterId] = useState({
    id: "",
    label: "",
  });
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                             Function to get all CPO                            ||
  // ! ||--------------------------------------------------------------------------------||
  const getAllCPO = () => {
    const Vendors = Parse.Object.extend("ChargePointOperators");
    const parseQuery = new Parse.Query(Vendors);

    parseQuery.limit(50);
    parseQuery.find().then((result) => {
      let vendorsArray: any = [];
      result.forEach((item) => {
        let ab = {
          id: item.id,
          label: item.get("Name"),
        };
        vendorsArray.push(ab);
      });
      setAllOperators(vendorsArray);
    });
  };
  useEffect(() => {
    getAllCPO();
  }, []);

  const getLocationData = (locationId: string, locationType: string) => {
    const ChargePoints = Parse.Object.extend("Locations");

    const parseQuery = new Parse.Query(ChargePoints);

    if (locationId) {
      parseQuery.equalTo("objectId", locationId);
    }
    if (locationType) {
      parseQuery.equalTo("LocationType", locationType);
    }
    parseQuery.limit(50);
    parseQuery.find().then((result) => {
      let totalLocations = 0;
      let totalElectricityTariff = 0;
      result.forEach((item, index) => {
        totalLocations = totalLocations + 1;

        totalElectricityTariff =
          totalElectricityTariff + item.get("ElectricityTariff");
      });

      setAvCostPerKw(totalElectricityTariff / totalLocations);
    });
  };

  const currentUser: any = Parse.User.current();
  const capitalizeFirstLetterOfEachWord = (str:any) => {
    // return str.replace(/\b\w/g, (char:any) => char.toUpperCase());
    return str
    .toLowerCase()
    .replace(/\b\w/g, (char:any) => char.toUpperCase());
  };

  const getWidgetsData = (
    // userId: string,
    // locationId: string,
    locationType: string,
    currentType: string,
    date: string,
    startDate: string | Date,
    endDate: string | Date,
    operatorId: any
  ) => {
    const ChargeSession = Parse.Object.extend("ChargeSession");
    const parseQuery = new Parse.Query(ChargeSession);
    parseQuery.include("ChargePoint");
    // if (userId) {
    //   let User = Parse.User;
    //   let userObj = User.createWithoutData(userId);
    //   parseQuery.equalTo("User", userObj);
    // }

    if (userIds.length > 0) {
      parseQuery.containedIn("User", userIds);
    }

    if (locationIds.length > 0) {
      parseQuery.containedIn("Location", locationIds);
    }

    // if (locationId) {
    //   var Location = Parse.Object.extend("Locations");
    //   var locationObj = Location.createWithoutData(locationId);
    //   parseQuery.equalTo("Location", locationObj);
    // }
    if (currentUser && !JSON.parse(userDetail).isSuperAdmin) {
      var innerQuery = new Parse.Query("Chargers");

      innerQuery.equalTo("CPO", currentUser.get("CPO"));
      parseQuery.matchesQuery("ChargePoint", innerQuery);
    }
    if (JSON.parse(userDetail).isSuperAdmin && operatorId) {
      var innerQuery = new Parse.Query("Chargers");

      innerQuery.equalTo("CPO", {
        __type: "Pointer",
        className: "ChargePointOperators",
        objectId: operatorId,
      });
      parseQuery.matchesQuery("ChargePoint", innerQuery);
    }
    if (locationType) {
      var innerQuery = new Parse.Query("Locations");
      innerQuery.equalTo("LocationType", locationType);
      parseQuery.matchesQuery("Location", innerQuery);
    }
    if (currentType) {
      var innerQuery = new Parse.Query("Chargers");

      var moreInnerQuery = new Parse.Query("ConnectorTypes");
      moreInnerQuery.equalTo("CurrentType", currentType);

      innerQuery.matchesQuery("ConnectorType", moreInnerQuery);
      parseQuery.matchesQuery("ChargePoint", innerQuery);
    }

    if (date) {
      if (date === "Today") {
        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment().startOf("day").toString())
        );
        parseQuery.lessThanOrEqualTo(
          "createdAt",
          new Date(moment().add(1, "day").startOf("day").toString())
        );
      } else if (date === "This Week") {
        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment().startOf("week").toString())
        );
        parseQuery.lessThanOrEqualTo(
          "createdAt",
          new Date(moment().endOf("week").toString())
        );
      } else if (date === "This Month") {
        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment().startOf("month").toString())
        );
        parseQuery.lessThanOrEqualTo(
          "createdAt",
          new Date(moment().endOf("month").toString())
        );
      } else if (date === "This Year") {
        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment().startOf("year").toString())
        );
        parseQuery.lessThanOrEqualTo(
          "createdAt",
          new Date(moment().endOf("year").toString())
        );
      }
    }

    if (startDate) {
      parseQuery.greaterThanOrEqualTo("createdAt", new Date(startDate));
    }
    if (endDate) {
      parseQuery.lessThanOrEqualTo("createdAt", new Date(endDate));
    }
    parseQuery.include("Location");
    parseQuery.ascending("createdAt");
    parseQuery.limit(5000);
    parseQuery.find().then((result) => {
      type Person = {
        name: string;
        Energy: any;
      };
      var dateArray: any[] = [];
      var tempData: Person[] = [];
      var lineData: any = [];
      let totalEnergyCost = 0;

      let totalEnergy = 0;
      let thisMonthMoney = 0;
      let lastMonthMoney = 0;
      let totalSession = 0;

      let totalElectricityTarrif = 0;
      result.forEach((item, index) => {
        let dateM = item.createdAt;

        let dateValue = moment(dateM).format("MMM YY");
        if (date === "Today") {
          dateValue = moment(dateM).format("MMM YY hh:mm A");
        }
        if (date === "This Week") {
          dateValue = moment(dateM).format("DD MMM YY ");
        }
        if (date === "This Month") {
          dateValue = moment(dateM).format("DD MMM YY ");
        }
        let currentEnergy = item.get("TotalEnergyConsumed")
          ? item.get("TotalEnergyConsumed")
          : 0;
        let currentAvEnergyCost = item.get("TotalEnergyConsumed")
          ? item.get("TotalEnergyConsumed") / totalSession
          : 0;
        totalSession = totalSession + 1;
        totalEnergyCost = item.get("TotalEnergyConsumed")
          ? item.get("TotalEnergyConsumed") *
              item.get("Location").get("ElectricityTariff") +
            totalEnergyCost
          : 0 + totalEnergyCost;
        totalEnergy = item.get("TotalEnergyConsumed")
          ? item.get("TotalEnergyConsumed") + totalEnergy
          : 0 + totalEnergy;
        totalElectricityTarrif = item.get("Location").get("ElectricityTariff")
          ? item.get("Location").get("ElectricityTariff") +
            totalElectricityTarrif
          : 0 + totalElectricityTarrif;
        if (!dateArray.includes(dateValue)) {
          dateArray.push(dateValue);
          let newItem = {
            name: dateValue,
            Energy: parseFloat(currentEnergy.toFixed(2)),
          };
          let oldItem = {
            name: dateValue,
            Energy: parseFloat(currentEnergy.toFixed(2)),
            AverageEnergyConsumed: parseFloat(currentAvEnergyCost.toFixed(2)),
          };
          tempData.push(newItem);
          lineData.push(oldItem);
        } else {
          tempData.forEach((el: { name: string; Energy: any }) => {
            if (el.name === dateValue) {
              var sessions = el.Energy;
              el.Energy = parseFloat((sessions + currentEnergy).toFixed(2));
            } else {
              return;
            }
          });
          lineData.forEach(
            (el: { name: string; Energy: any; AverageEnergyConsumed: any }) => {
              if (el.name === dateValue) {
                var sessions = el.Energy;

                el.Energy = parseFloat((sessions + currentEnergy).toFixed(2));
                el.AverageEnergyConsumed = parseFloat(
                  (totalEnergy / totalSession).toFixed(2)
                );
              } else {
                return;
              }
            }
          );
        }
      });
      setLineChartData(lineData);
      setChartData(tempData);
      setAvEnergy(totalEnergy / totalSession);
      setEnergyCost(totalEnergyCost);
      setEnergyConsumed({
        thisMonth: thisMonthMoney,
        lastMonth: lastMonthMoney,
        total: totalEnergy,
      });
    });
  };

  // Filters Data
  const [allLocations, setAllLocations] = useState<any | null>([]);
  const [allUsers, setAllUsers] = useState<any | null>([]);
  const [allLocationType, setAllLocationType] = useState<any | null>([]);
  const [userIds, setUserIds] = useState<any[]>([]);
  const [locationIds, setLocationIds] = useState<any[]>([]); // Array of Location Filter

  let userDetail: any = sessionStorage.getItem("user-details");
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                       Function to get all Locations List                       ||
  // ! ||--------------------------------------------------------------------------------||
  const getAllLocations = () => {
    const chargersQuery = new Parse.Query("Chargers");
    if (currentUser) {
      chargersQuery.equalTo("CPO", currentUser.get("CPO"));
    }

    chargersQuery.find().then((chargeResult) => {
      let locArray: any = [];
      chargeResult.map((chargePoint) => {
        if (!locArray.includes(chargePoint.get("Location").id)) {
          locArray.push(chargePoint.get("Location").id);
        }
      });

      const locationQuery = new Parse.Query("Locations");
      !JSON.parse(userDetail).isSuperAdmin &&
        locationQuery.containedIn("objectId", locArray);
      locationQuery.find().then((result) => {
        let addressArray: any[] = [];

        result.forEach((item) => {
          let locPoint = item.get("GeoLocation");
          addressArray.push({
            id: item.id,
            label: item.get("Name"),
          });
          setAllLocations(addressArray);
        });
      });
    });
  };

  const getAllLocationType = () => {
    const Locations = Parse.Object.extend("Locations");
    const parseQuery = new Parse.Query(Locations);

    parseQuery.limit(50);
    parseQuery.find().then((result) => {
      let addressArray: optionObj[] = [];
      let locationType: any[] = [];
      result.forEach((item) => {
        if (
          !locationType.find(function (i) {
            return i === item.get("LocationType");
          })
        ) {
          locationType.push(item.get("LocationType"));
        }
        addressArray.push({
          id: item.id,
          label: item.get("Name"),
        });
      });

      setAllLocationType(
        allLocationType.length ? allLocationType : locationType
      );
    });
  };

  const [series, setSeries] = useState([
    {
      name: "9 am - 11 am",
      data: [31, 40, 28, 51, 42, 109, 100],
    },
    {
      name: "11 am -1pm",
      data: [11, 32, 45, 32, 34, 52, 41],
    },
    {
      name: "1 pm - 3pm",
      data: [31, 40, 28, 51, 42, 109, 100],
    },
    {
      name: "3 pm - 5pm",
      data: [11, 32, 45, 32, 34, 52, 41],
    },
    {
      name: "5 pm - 7pm",
      data: [31, 40, 28, 51, 42, 109, 100],
    },
    {
      name: "7 pm - 9pm",
      data: [11, 32, 45, 32, 34, 52, 41],
    },
    {
      name: "9 pm -  11pm",
      data: [31, 40, 28, 51, 42, 109, 100],
    },
    {
      name: "11 pm -1am",
      data: [11, 32, 45, 32, 34, 52, 41],
    },
    {
      name: "1 am - 3am",
      data: [11, 32, 45, 32, 34, 52, 41],
    },
    {
      name: "3 am - 5am",
      data: [11, 32, 45, 32, 34, 52, 41],
    },
    {
      name: "5 am -7am",
      data: [11, 32, 45, 32, 34, 52, 41],
    },
    {
      name: "7 am - 9am",
      data: [11, 32, 45, 32, 34, 52, 41],
    },
  ]);

  useEffect(() => {
    const getUsers = async () => {
        const parseQuery = new Parse.Query("ChargeSession");
        parseQuery.include("User");
        parseQuery.limit(5000);
        const result = await parseQuery.find();

        let usersMap = new Map();
        const ocppPromises = result.map(async (item) => {
            const user = item.get("User");
            if (user && !usersMap.has(user.id)) {
                usersMap.set(user.id, {
                    id: user.id,
                    label: user.get("FullName")
                });
            }
        });

        await Promise.all(ocppPromises);

        setAllUsers(Array.from(usersMap.values()));
    };

    getUsers();
}, []);

  const loadSessions = (
    // userId: string,
    // locationId: string,
    locationType: string,
    currentType: string,

    date: string,
    startDate: string | Date,
    endDate: string | Date,
    operatorId: any
  ) => {
    if (dateFilter === "This Year") {
      setDateFilterChips([{ id: "This Year", label: "This Year" }]);
    }

    const parseQuery = new Parse.Query("ChargeSession");
    parseQuery.include("ChargePoint");
    console.log("userIds In Load session", userIds);

    if (dateFilter !== "This Year") {
      parseQuery.limit(5000);
    }

    if (userIds.length > 0) {
      parseQuery.containedIn("User", userIds);
    }

    if (locationIds.length > 0) {
      parseQuery.containedIn("Location", locationIds);
    }

    // if (locationId) {
    //   var Location = Parse.Object.extend("Locations");
    //   var locationObj = Location.createWithoutData(locationId);
    //   parseQuery.equalTo("Location", locationObj);
    // }

    if (currentUser) {
      var innerQuery = new Parse.Query("Chargers");
      innerQuery.equalTo("CPO", currentUser.get("CPO"));

      if (!JSON.parse(userDetail).isSuperAdmin) {
        parseQuery.matchesQuery("ChargePoint", innerQuery);
      } else {
        parseQuery.matchesQuery("ChargePoint", innerQuery);
      }
    }

    // if (currentUser && !JSON.parse(userDetail).isSuperAdmin) {
    //   var innerQuery = new Parse.Query("Chargers");

    //   innerQuery.equalTo("CPO", currentUser.get("CPO"));
    //   parseQuery.matchesQuery("ChargePoint", innerQuery);
    // }
    // if (JSON.parse(userDetail).isSuperAdmin && operatorId) {
    //   var innerQuery = new Parse.Query("Chargers");

    //   innerQuery.equalTo("CPO", {
    //     __type: "Pointer",
    //     className: "ChargePointOperators",
    //     objectId: operatorId,
    //   });
    //   parseQuery.matchesQuery("ChargePoint", innerQuery);
    // }
    if (locationType) {
      var innerQuery = new Parse.Query("Locations");
      innerQuery.equalTo("LocationType", locationType);
      parseQuery.matchesQuery("Location", innerQuery);
    }

    if (currentType) {
      var innerQuery = new Parse.Query("Chargers");

      var moreInnerQuery = new Parse.Query("ConnectorTypes");
      moreInnerQuery.equalTo("CurrentType", currentType);

      innerQuery.matchesQuery("ConnectorType", moreInnerQuery);
      parseQuery.matchesQuery("ChargePoint", innerQuery);
    }
    if (dateFilter === "This Week") {
      parseQuery.greaterThanOrEqualTo(
        "createdAt",
        new Date(moment().startOf("week").toString())
      );
      parseQuery.lessThanOrEqualTo(
        "createdAt",
        new Date(moment().endOf("week").toString())
      );
    } else if (dateFilter === "Today") {
      parseQuery.greaterThanOrEqualTo(
        "createdAt",
        new Date(moment().startOf("day").toString())
      );
      parseQuery.lessThanOrEqualTo(
        "createdAt",
        new Date(moment().add(1, "day").startOf("day").toString())
      );
    } else if (dateFilter === "This Month") {
      parseQuery.greaterThanOrEqualTo(
        "createdAt",
        new Date(moment().startOf("month").toString())
      );
      parseQuery.lessThanOrEqualTo(
        "createdAt",
        new Date(moment().endOf("month").toString())
      );
    } else if (dateFilter === "This Year") {
      parseQuery.greaterThanOrEqualTo(
        "createdAt",
        new Date(moment().startOf("year").toString())
      );
      parseQuery.lessThanOrEqualTo(
        "createdAt",
        new Date(moment().endOf("year").toString())
      );
    }

    if (startDate) {
      parseQuery.greaterThanOrEqualTo("createdAt", new Date(startDate));
    }
    if (endDate) {
      parseQuery.lessThanOrEqualTo("createdAt", new Date(endDate));
    }

    parseQuery.include("ChargePoint");
    parseQuery.include("Location");
    parseQuery.include("Vehicle");
    parseQuery.include("User");
    parseQuery.descending("createdAt");

    parseQuery.limit(5000);
    parseQuery.find().then((result: any[]) => {
      let newRow: {
        id: number;
        status: string;
        startTime: string;
        endTime: string;
        chargeBy: string;
        chargeFor: string;
        customer: string;
        location: string;
        carCharged: string;
        aid: string;
        date: string;
        cost: string;
        energy: string;
        duration: string;
        obj: any;
        serialNum: string;
        power: string;
        connector: string;
      }[] = [];

      let usersArray: any[] = [];
      let nine: any = [0, 0, 0, 0, 0, 0, 0];
      let eleven: any = [0, 0, 0, 0, 0, 0, 0];
      let thirteen: any = [0, 0, 0, 0, 0, 0, 0];
      let fifteen: any = [0, 0, 0, 0, 0, 0, 0];
      let seventeen: any = [0, 0, 0, 0, 0, 0, 0];
      let nineteen: any = [0, 0, 0, 0, 0, 0, 0];
      let twentyone: any = [0, 0, 0, 0, 0, 0, 0];
      let twentythree: any = [0, 0, 0, 0, 0, 0, 0];
      let one: any = [0, 0, 0, 0, 0, 0, 0];
      let three: any = [0, 0, 0, 0, 0, 0, 0];
      let five: any = [0, 0, 0, 0, 0, 0, 0];
      let seven: any = [0, 0, 0, 0, 0, 0, 0];
      result.forEach((item, index) => {
        let createdAt = item.get("createdAt").getHours();
        let day = item.get("createdAt").getDay();

        if (createdAt >= 9 && createdAt < 11) {
          if (day === 0) {
            nine[0] = nine[0] + 1;
          } else if (day === 1) {
            nine[1] = nine[1] + 1;
          } else if (day === 2) {
            nine[2] = nine[2] + 1;
          } else if (day === 3) {
            nine[3] = nine[3] + 1;
          } else if (day === 4) {
            nine[4] = nine[4] + 1;
          } else if (day === 5) {
            nine[5] = nine[5] + 1;
          } else if (day === 6) {
            nine[6] = nine[6] + 1;
          }
        } else if (createdAt >= 11 && createdAt < 13) {
          if (day === 0) {
            eleven[0] = eleven[0] + 1;
          } else if (day === 1) {
            eleven[1] = eleven[1] + 1;
          } else if (day === 2) {
            eleven[2] = eleven[2] + 1;
          } else if (day === 3) {
            eleven[3] = eleven[3] + 1;
          } else if (day === 4) {
            eleven[4] = eleven[4] + 1;
          } else if (day === 5) {
            eleven[5] = eleven[5] + 1;
          } else if (day === 6) {
            eleven[6] = eleven[6] + 1;
          }
        } else if (createdAt >= 13 && createdAt < 15) {
          if (day === 0) {
            thirteen[0] = thirteen[0] + 1;
          } else if (day === 1) {
            thirteen[1] = thirteen[1] + 1;
          } else if (day === 2) {
            thirteen[2] = thirteen[2] + 1;
          } else if (day === 3) {
            thirteen[3] = thirteen[3] + 1;
          } else if (day === 4) {
            thirteen[4] = thirteen[4] + 1;
          } else if (day === 5) {
            thirteen[5] = thirteen[5] + 1;
          } else if (day === 6) {
            thirteen[6] = thirteen[6] + 1;
          }
        } else if (createdAt >= 15 && createdAt < 17) {
          if (day === 0) {
            fifteen[0] = fifteen[0] + 1;
          } else if (day === 1) {
            fifteen[1] = fifteen[1] + 1;
          } else if (day === 2) {
            fifteen[2] = fifteen[2] + 1;
          } else if (day === 3) {
            fifteen[3] = fifteen[3] + 1;
          } else if (day === 4) {
            fifteen[4] = fifteen[4] + 1;
          } else if (day === 5) {
            fifteen[5] = fifteen[5] + 1;
          } else if (day === 6) {
            fifteen[6] = fifteen[6] + 1;
          }
        } else if (createdAt >= 17 && createdAt < 19) {
          if (day === 0) {
            seventeen[0] = seventeen[0] + 1;
          } else if (day === 1) {
            seventeen[1] = seventeen[1] + 1;
          } else if (day === 2) {
            seventeen[2] = seventeen[2] + 1;
          } else if (day === 3) {
            seventeen[3] = seventeen[3] + 1;
          } else if (day === 4) {
            seventeen[4] = seventeen[4] + 1;
          } else if (day === 5) {
            seventeen[5] = seventeen[5] + 1;
          } else if (day === 6) {
            seventeen[6] = seventeen[6] + 1;
          }
        } else if (createdAt >= 19 && createdAt < 21) {
          if (day === 0) {
            nineteen[0] = nineteen[0] + 1;
          } else if (day === 1) {
            nineteen[1] = nineteen[1] + 1;
          } else if (day === 2) {
            nineteen[2] = nineteen[2] + 1;
          } else if (day === 3) {
            nineteen[3] = nineteen[3] + 1;
          } else if (day === 4) {
            nineteen[4] = nineteen[4] + 1;
          } else if (day === 5) {
            nineteen[5] = nineteen[5] + 1;
          } else if (day === 6) {
            nineteen[6] = nineteen[6] + 1;
          }
        } else if (createdAt >= 21 && createdAt < 23) {
          if (day === 0) {
            twentyone[0] = twentyone[0] + 1;
          } else if (day === 1) {
            twentyone[1] = twentyone[1] + 1;
          } else if (day === 2) {
            twentyone[2] = twentyone[2] + 1;
          } else if (day === 3) {
            twentyone[3] = twentyone[3] + 1;
          } else if (day === 4) {
            twentyone[4] = twentyone[4] + 1;
          } else if (day === 5) {
            twentyone[5] = twentyone[5] + 1;
          } else if (day === 6) {
            twentyone[6] = twentyone[6] + 1;
          }
        } else if (createdAt >= 23 && createdAt < 1) {
          if (day === 0) {
            twentythree[0] = twentythree[0] + 1;
          } else if (day === 1) {
            twentythree[1] = twentythree[1] + 1;
          } else if (day === 2) {
            twentythree[2] = twentythree[2] + 1;
          } else if (day === 3) {
            twentythree[3] = twentythree[3] + 1;
          } else if (day === 4) {
            twentythree[4] = twentythree[4] + 1;
          } else if (day === 5) {
            twentythree[5] = twentythree[5] + 1;
          } else if (day === 6) {
            twentythree[6] = twentythree[6] + 1;
          }
        } else if (createdAt >= 1 && createdAt < 3) {
          if (day === 0) {
            one[0] = one[0] + 1;
          } else if (day === 1) {
            one[1] = one[1] + 1;
          } else if (day === 2) {
            one[2] = one[2] + 1;
          } else if (day === 3) {
            one[3] = one[3] + 1;
          } else if (day === 4) {
            one[4] = one[4] + 1;
          } else if (day === 5) {
            one[5] = one[5] + 1;
          } else if (day === 6) {
            one[6] = one[6] + 1;
          }
        } else if (createdAt >= 3 && createdAt < 5) {
          if (day === 0) {
            three[0] = three[0] + 1;
          } else if (day === 1) {
            three[1] = three[1] + 1;
          } else if (day === 2) {
            three[2] = three[2] + 1;
          } else if (day === 3) {
            three[3] = three[3] + 1;
          } else if (day === 4) {
            three[4] = three[4] + 1;
          } else if (day === 5) {
            three[5] = three[5] + 1;
          } else if (day === 6) {
            three[6] = three[6] + 1;
          }
        } else if (createdAt >= 5 && createdAt < 7) {
          if (day === 0) {
            five[0] = five[0] + 1;
          } else if (day === 1) {
            five[1] = five[1] + 1;
          } else if (day === 2) {
            five[2] = five[2] + 1;
          } else if (day === 3) {
            five[3] = five[3] + 1;
          } else if (day === 4) {
            five[4] = five[4] + 1;
          } else if (day === 5) {
            five[5] = five[5] + 1;
          } else if (day === 6) {
            five[6] = five[6] + 1;
          }
        } else if (createdAt >= 7 && createdAt < 9) {
          if (day === 0) {
            seven[0] = seven[0] + 1;
          } else if (day === 1) {
            seven[1] = seven[1] + 1;
          } else if (day === 2) {
            seven[2] = seven[2] + 1;
          } else if (day === 3) {
            seven[3] = seven[3] + 1;
          } else if (day === 4) {
            seven[4] = seven[4] + 1;
          } else if (day === 5) {
            seven[5] = seven[5] + 1;
          } else if (day === 6) {
            seven[6] = seven[6] + 1;
          }
        }

        let car = "";
        if (`${item.get("Vehicle")}` !== "undefined") {
          car = `${item.get("Vehicle").get("Name")}`;
        }

        const userObj = {
          id: `${item.get("User").id}`,
          label: `${item.get("User").get("FullName")}`,
        };

        if (
          !usersArray.find(function (i) {
            return i.id === userObj.id;
          })
        ) {
          usersArray.push(userObj);
        }

        newRow.push({
          id: index + 1,
          status: `${item.get("User").get("FullName")}`,
          customer: `${item.get("User").get("FullName")}`,
          location: `${item.get("Location").get("Name")}`,
          serialNum: `${item.get("ChargePoint").get("Serial")}`,
          power: `${item.get("ChargePoint").get("Power")}`,
          connector: `${item.get("ChargePoint").get("Connector")}`,
          startTime: `${
            moment(item.get("createdAt")).format("lll")
              ? moment(item.get("createdAt")).format("lll")
              : "-"
          }`,
          endTime: `${
            moment(item.get("updatedAt")).format("lll")
              ? moment(item.get("updatedAt")).format("lll")
              : "-"
          }`,
          carCharged: car,
          chargeBy: `${item.get("ChargeBy")}`,
          chargeFor: `${item.get("ChargeFor")}`,
          duration: `${
            moment
              .duration(
                moment(new Date(item.get("updatedAt"))).diff(
                  moment(new Date(item.get("createdAt")))
                )
              )
              .hours() +
            "hr" +
            " " +
            moment
              .duration(
                moment(new Date(item.get("updatedAt"))).diff(
                  moment(new Date(item.get("createdAt")))
                )
              )
              .minutes() +
            "min"
          }  `,
          aid: `${item.get("AID")}`,
          date: `${item.get("createdAt")}`,
          cost: `₹ ${parseFloat(item.get("TotalCost")).toFixed(2)}`,
          energy: `${parseFloat(item.get("TotalEnergyConsumed")).toFixed(
            2
          )} kWh`,
          obj: item,
        });
      });

      setSeries([
        {
          name: "9 am - 11am",
          data: nine,
        },
        {
          name: "11 am -1pm",
          data: eleven,
        },
        {
          name: "1 pm - 3 pm",
          data: thirteen,
        },
        {
          name: "3 pm - 5pm",
          data: fifteen,
        },
        {
          name: "5 pm - 7pm",
          data: seventeen,
        },
        {
          name: "7 pm - 9pm",
          data: nineteen,
        },
        {
          name: "9 pm - 11pm",
          data: twentyone,
        },
        {
          name: "11 pm - 1 am",
          data: twentythree,
        },
        {
          name: "1 am - 3 am",
          data: one,
        },
        {
          name: "3 am - 5 am",
          data: three,
        },
        {
          name: "5 am -7 am",
          data: five,
        },
        {
          name: "7 am - 9am",
          data: seven,
        },
      ]);
      // console.log("userArray", usersArray);
      // console.log("/", usersArray);

      // setAllUsers(!allUsers.length ? usersArray : allUsers);
    });
  };

  console.log("allUsers", allUsers);

  // Filters

  useEffect(() => {
    getAllLocations();
    getAllLocationType();
  }, []);

  const [operatorFilter, setOperatorFilter] = useState({ id: "", label: "" });
  // const [locationFilter, setLocationFilter] = useState({ id: "", label: "" });
  const [dateFilter, setDateFilter] = useState("This Year");
  const [startDateFilter, setStartDateFilter] = useState<any>(""); // start date filter
  const [endDateFilter, setEndDateFilter] = useState<any>(""); // End Date filter
  const [currentTypeFilter, setCurrentTypeFilter] = useState<any>("");
  const [locationTypeFilter, setLocationTypeFilter] = useState<any>("");
  const [isSelected, setIsSelected] = useState(false);
  const [openCustom, setOpenCustom] = useState<boolean>(false);
  useEffect(() => {
    getWidgetsData(
      // userFilter.id,
      // locationFilter.id,
      locationTypeFilter,
      currentTypeFilter,

      dateFilter,
      startDateFilter,
      endDateFilter,
      operatorFilter.id
    );
    loadSessions(
      // userFilter.id,
      // locationFilter.id,
      locationTypeFilter,
      currentTypeFilter,
      dateFilter,
      startDateFilter,
      endDateFilter,
      operatorFilter.id
    );
  }, [
    userIds,
    locationIds,
    // locationFilter.id,
    locationTypeFilter,
    currentTypeFilter,
    dateFilter,
    startDateFilter,
    endDateFilter,
    operatorFilter,
  ]);

  useEffect(() => {
    getLocationData(locationFilterId.id, locationTypeFilter);
  }, [locationFilterId.id, locationTypeFilter]);

  // Filter Code

  const [filterChips, setFilterChips] = useState<
    { id: string; label: string }[]
  >([]);

  const [locationChips, setLocationChips] = useState<
    { id: string; label: string }[]
  >([]);

  const [locationTypeChips, setLocationTypeChips] = useState<any[]>([]);
  const [currentTypeChips, setCurrentTypeFilterChips] = useState<any[]>([]);
  const [location_Type_Filter, set_location_Type_Filter] = useState("");
  const [dateRangeChipVisible, setDateRangeChipVisible] =
    useState<boolean>(false);
  const [SingleDateChips, setSingleDateFilterChips] = useState<string | null>(
    null
  );

  // Location Type Change Code for apply Location Type Filter
  const handleLocationTypeChange = (value: string) => {
    // Find if the selected date is already in the chips
    const existingChip = locationTypeChips.find((chip) => chip.label === value);

    // If the selected date is not in the chips, update the chips array
    if (!existingChip) {
      // Remove the previous date chip if it exists
      const updatedChips = locationTypeChips.filter(
        (chip) => chip.id !== location_Type_Filter
      );

      setLocationTypeChips([...updatedChips, { id: value, label: value }]);
    }
    setLocationTypeFilter(value);
    set_location_Type_Filter((prevValue) => (prevValue === value ? "" : value));
  };

  // Current Type Change Code for apply Current Type Filter (AC/DC)
  const handleCurrentChange = (value: string) => {
    // Find if the selected date is already in the chips
    const existingChip = currentTypeChips.find((chip) => chip.label === value);

    // If the selected date is not in the chips, update the chips array
    if (!existingChip) {
      // Remove the previous date chip if it exists
      const updatedChips = currentTypeChips.filter(
        (chip) => chip.id !== currentTypeFilter
      );

      setCurrentTypeFilterChips([...updatedChips, { id: value, label: value }]);
    }
    setCurrentTypeFilter(value);
  };

  const [userFilter, setUserFilter] = useState<UserFilter[]>([]);
  const [locationsFilter, setLocationsFilter] = useState<LocationFilter[]>([]);

  // User Filter Code
  const handleUserChange = useCallback((event: any, newValue: any) => {
    setFilterChips((prevChips) => {
      const newChips = newValue.map((user: any) => ({
        id: user.id,
        label: `User: ${user.label}`,
      }));

      const filteredChips = newChips.filter(
        (chip: any) => !prevChips.some((prevChip) => prevChip.id === chip.id)
      );

      return [...prevChips, ...filteredChips];
    });

    setUserFilter(newValue);
  }, []);

  // Single Date Change function for apply single Date Filter
  const handleDateChange = (value: string) => {
    if (value === "Custom") {
      setIsSelected(!isSelected);
      setOpenCustom((prevOpenCustom) => !prevOpenCustom);
      setDateFilterChips([]);
      setDateFilter("");
      // Clear custom date range when selecting "Custom"
      setStartDateFilter(null);
      setEndDateFilter(null);
      setDateRangeChipVisible(false);
    } else {
      setOpenCustom(false);
      // Update the dateFilter state
      setDateFilter(value);
      // Update the dateFilterChips array based on the selected value
      const selectedChip = {
        id: value,
        label:
          value === "Today"
            ? "Today"
            : value === "This Month"
            ? "This Month"
            : value === "This Week"
            ? "This Week"
            : "This Year",
      };
      setDateFilterChips([selectedChip]);
      // Clear custom date range when selecting predefined filters
      setStartDateFilter(null);
      setEndDateFilter(null);
      setDateRangeChipVisible(false);
      setIsSelected(false);
    }
  };
  // Range Code for apply Range Date Filter
  const dateRangeChange = (dates: any) => {
    const [start, end] = dates;
    setStartDateFilter(start);
    setEndDateFilter(end);
    setDateRangeChipVisible(!!start && !!end);
    setSingleDateFilterChips(start);
  };

  console.log("userFilter", userFilter);

  useEffect(() => {
    if (Array.isArray(userFilter)) {
      const userIds = userFilter.map((user: any) => user.id);
      // console.log("users Id", userIds);
      setUserIds(userIds);

      if (userIds.length > 0) {
        // Your logic when userFilter is an array
      }
    } else if (userFilter && typeof userFilter === "object") {
      const userId = (userFilter as { id?: string }).id;
      console.log("user Id", userId);
      setUserIds(userId ? [userId] : []);

      // Your logic when userFilter is an object
    } else {
      // Handle other cases when userFilter is not an array or object
    }

    if (Array.isArray(locationFilter)) {
      const locationIds = locationFilter.map((user: any) => user.id);

      setLocationIds(locationIds);

      if (locationIds.length > 0) {
        // Your logic when userFilter is an array
      }
    } else if (locationFilter && typeof locationFilter === "object") {
      const locationId = (locationFilter as { id?: string }).id;

      setLocationIds(locationId ? [locationId] : []);

      // Your logic when userFilter is an object
    } else {
      // Handle other cases when userFilter is not an array or object
    }
  }, [userFilter, locationFilter]);

  console.log("user ids", userIds);

  const [seriesIndex, setSeriesIndex] = useState(-1);
  const [dataPointIndex, setDataPointIndex] = useState(-1);

  const CustomTooltip: React.FC<{
    active?: boolean;
    payload?: any[];
    label?: string;
  }> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip p-5 " style={{ width: "340px" }}>
          <h3 className="font-lexend text-l font-semibold ml-3">{`${label}`}</h3>
          {payload?.map((ele, index) => (
            <span className="flex items-center" key={index}>
              <span>
                <TripOriginIcon
                  sx={{
                    width: "20px",
                    height: "20px",
                    color:
                      ele?.name === "Revenue"
                        ? "#8EB5FF"
                        : ele?.name === "Average Energy Consumed"
                        ? "#d69f27"
                        : "#6AD3A7",
                  }}
                />
              </span>
              <span className="font-lexend text-l ml-3">{ele?.name} :</span>{" "}
              <span className="font-lexend text-l ml-3">{ele?.value}</span>{" "}
            </span>
          ))}
        </div>
      );
    }

    return null;
  };

  //  Heat Map Option of Chart
  const options = {
    chart: {
      height: 350,
      toolbar: {
        show: false, // Toolbar hidden
      },
      events: {
        dataPointMouseEnter: function (
          event: any,
          chartContext: any,
          config: any
        ) {
          console.log("Event", event);
          console.log("Chart", chartContext);
          console.log("config", config);
          setSeriesIndex(config?.seriesIndex);
          setDataPointIndex(config?.dataPointIndex);
        },
      },
    },
    dataLabels: {
      enabled: false,
    },

    xaxis: {
      categories: ["Sn", "M", "T", "W", "T", "F", "S"],
      labels: {
        style: {
          fontSize: "14px",
          fontWeight: "normal",
          color: "#333",
          fontFamily: "lexend",

          // Add any other CSS properties you need
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: "12px",
          fontWeight: "normal",
          color: "#333",
          fontFamily: "lexend",
          wordSpacing: "9px",
        },
      },
    },
    title: {
      text: "Charge Sessions Heat Map",
      style: {
        fontSize: "16px",
        fontWeight: "bold",
        color: "#333", // Example color
        fontFamily: "lexend",
        width: "100%",
        // Add any other CSS properties you need
      },
    },

    plotOptions: {
      heatmap: {
        shadeIntensity: 0.5,
        radius: 0,
        useFillColorAsStroke: true,
        // colorScale: {
        //   ranges: [
        //     {
        //       from: 0,
        //       to: 10,
        //       name: "10>",
        //       color: "#e3faf1",
        //       margin: 4,

        //     },
        //     {
        //       from: 10,
        //       to: 30,
        //       name: "30 >",
        //       color: "#9cffd8",
        //     },
        //     {
        //       from: 30,
        //       to: 70,
        //       name: "70 >",
        //       color: "#65f7bb",
        //     },
        //     {
        //       from: 70,
        //       to: 120,
        //       name: "120 >",
        //       color: "#1ac47d",
        //     },
        //   ],
        //   inverse: false,
        //   min: 0,
        //   max: 200
        // },
      },
    },

    colors: ["#14e18c"],
  };

  const [isFilterVisible, setIsFilterVisible] = useState(false); // To open Filter

  // Show / Hide Filter Function
  const handleFilterButtonClick = () => {
    // Toggle filter visibility when user clicks on "Filters" button
    setIsFilterVisible((prev) => !prev);
  };

  console.log("lineChartData", lineChartData);

  const getDayName = (dataPointIndex: number): string => {
    const dayNames = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wed",
      "Thu",
      "Friday",
      "Sat",
    ];
    return dayNames[dataPointIndex];
  };

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                       Remove Filter Code start from here                       ||
  // ! ||--------------------------------------------------------------------------------||

  // Delete User Filter Chip Code
  const handleDeleteChip = (chipId: string) => {
    // Remove the corresponding chip from the state
    setFilterChips((prevChips) =>
      prevChips.filter((chip) => chip.id !== chipId)
    );

    // Remove the user filter
    setUserIds((prevUserIds) => prevUserIds.filter((id) => id !== chipId));

    setUserFilter((prevUserFilter: any) =>
      prevUserFilter.filter((filter: any) => filter.id !== chipId)
    );
    // console.log("userFilter in delete", userFilter);
  };

  // Delete Chip Location Filter Code
  const handleLocationDeleteChip = (chipId: string) => {
    console.log("Deleting chip with id:", chipId);

    // Remove the corresponding chip from the state
    setLocationChips((prevChips) =>
      prevChips.filter((chip) => chip.id !== chipId)
    );

    // Remove the location filter
    setLocationIds((prevUserIds) => prevUserIds.filter((id) => id !== chipId));

    setLocationFilter((prevUserFilter: any) =>
      prevUserFilter.filter((filter: any) => filter.id !== chipId)
    );
  };
  // Delete Chip for Today,This Week,This Month and This Year
  const handleDeleteDateChip = (chipId: string) => {
    // Filter out the chip with the given chipId
    const updatedChips = dateChips.filter((chip) => chip.id !== chipId);

    // Update the dateChips state with the filtered chips
    setDateFilterChips(updatedChips);

    // Also update the dateFilter state to an empty string
    setDateFilter("");
  };

  // 6.8mm
  // 83 yrs
  // surya hospital

  // Delete Location type filter Code
  const handleDeleteLocationTypeChip = (chipId: string) => {
    // Remove the corresponding chip from the state
    setLocationTypeChips((prevChips) =>
      prevChips.filter((chip) => chip.id !== chipId)
    );

    setLocationTypeFilter("");
    // Clear the selected status
    set_location_Type_Filter("");
  };
  // Delete Current type filter Code
  const handleDeleteCurrentTypeChip = (chipId: string) => {
    // Remove the corresponding chip from the state
    setCurrentTypeFilterChips((prevChips) =>
      prevChips.filter((chip) => chip.id !== chipId)
    );

    setLocationTypeFilter("");
    // Clear the selected status
    setCurrentTypeFilter("");
  };
  // Delete Single Date filter Code
  const handleSingleDeleteDateChip = (chipId: string) => {
    setStartDateFilter(null);
    setEndDateFilter(null);
    setSingleDateFilterChips(null);
  };
  // Delete Range Date filter Code
  const handleDateRangeDelete = () => {
    setStartDateFilter(null);
    setEndDateFilter(null);
    setDateRangeChipVisible(false);
  };

  const [colorRanges, setColorRanges] = useState<
    { from: number; color: string }[]
  >([]);

  useEffect(() => {
    // Calculate color ranges dynamically based on your data
    const calculateColorRanges = () => {
      // Replace this with your logic to calculate color ranges dynamically
      const ranges = [
        { from: 0, color: "#dcefe7" },
        { from: 30, color: "#A9E9CE" },
        { from: 50, color: "#85e0ba" },
        { from: 100, color: "#44e29e" },
      ];
      setColorRanges(ranges);
    };

    calculateColorRanges();
  }, []);

  return (
    <>
      {/* Main Container */}
      <div className="transaction-container">
        <div className="transaction_table_container flex">
          <div style={{ width: `${!isFilterVisible ? "100%" : "75%"}` }}>
            <div className="flex justify-between mx-6 mt-5">
              <h2 className="transaction_heading mt-2">Energy Consumption</h2>
              <div className="flex justify-between items-center">
                <button
                  className="filter_button rounded-full p-3 border-2 border-black-600"
                  onClick={handleFilterButtonClick}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M3 3H15V4.629C14.9999 5.02679 14.8418 5.40826 14.5605 5.6895L11.25 9V14.25L6.75 15.75V9.375L3.39 5.679C3.13909 5.40294 3.00004 5.0433 3 4.67025V3Z"
                      stroke="#111111"
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
            {/* Chips container to display chip */}
            <div className="filter-chips-container flex flex-wrap ml-4">
              {/* User Chips Code */}
              {filterChips.map((chip) => (
                <Chip
                  key={chip.id}
                  label={capitalizeFirstLetterOfEachWord(chip.label)}
                  onDelete={() => handleDeleteChip(chip.id)}
                  variant="outlined"
                  sx={{ marginLeft: "5px", marginBottom: "10px" }}
                />
              ))}
              {/* Location Chip */}
              {locationChips.map((chip) => (
                <Chip
                  key={chip.id}
                  label={chip.label}
                  onDelete={() => handleLocationDeleteChip(chip.id)}
                  variant="outlined"
                  sx={{ marginLeft: "5px", marginBottom: "10px" }}
                />
              ))}

              {/* Location Type Chips */}
              {locationTypeChips.map((chip) => (
                <Chip
                  key={chip.id}
                  label={chip.label}
                  onDelete={() => handleDeleteLocationTypeChip(chip.id)}
                  variant="outlined"
                  sx={{ marginLeft: "5px", marginBottom: "10px" }}
                />
              ))}

              {/* Current Type Chips */}
              {currentTypeChips.map((chip) => (
                <Chip
                  key={chip.id}
                  label={chip.label}
                  onDelete={() => handleDeleteCurrentTypeChip(chip.id)}
                  variant="outlined"
                  sx={{ marginLeft: "5px", marginBottom: "10px" }}
                />
              ))}

              {/* Single Date Chip - Today,This Week , This month ,This Year */}
              {/* Single Date Chip */}
              {dateChips.map((chip) => (
                <Chip
                  key={chip.id}
                  label={chip.label}
                  onDelete={() => handleDeleteDateChip(chip.id)}
                  variant="outlined"
                  sx={{ marginLeft: "5px", marginBottom: "10px" }}
                />
              ))}
              {/* Date Range Chip Code */}
              {dateRangeChipVisible && (
                <Chip
                  label={`Date Between : ${moment(startDateFilter).format(
                    "DD-MM-YYYY"
                  )} - ${moment(endDateFilter).format("DD-MM-YYYY")}`}
                  onDelete={handleDateRangeDelete}
                  variant="outlined"
                  sx={{ marginLeft: "5px" }}
                />
              )}
              {/* Single Chip code */}
              {/* Handle range Date Chip when deleted Single Date chip shows this prevent that bug */}
              {!dateRangeChipVisible && SingleDateChips && startDateFilter && (
                <Chip
                  label={`Date : ${moment(startDateFilter).format(
                    "DD-MM-YYYY"
                  )}`}
                  onDelete={handleSingleDeleteDateChip}
                  variant="outlined"
                  sx={{ marginLeft: "5px", marginBottom: "10px" }}
                />
              )}
            </div>

            {/* Widget Section */}
            <div className="flex flex-wrap md:justify-start sm:gap-2 justify-between items-center mx-7 mt-6 ">
              {/* Widget Component here */}
              <Widget type="revenue" data={energyConsumed} />

              <Widget type="avRevenue" data={avEnergy} />

              <Widget type="energy" data={energyCost} />

              <Widget type="land" data={avCostPerKw} />
            </div>

            <div
              className="flex w-100"
              style={{ marginRight: `${isFilterVisible ? "11px" : "0px"}` }}
            >
              {/* Total Revenue Graph */}
              <span style={{ width: "63%" }}>
                <p className="font-lexend mt-6 ml-12 absolute top-22 left-30 font-semibold">
                  Total Revenue
                </p>

                <ResponsiveContainer
                  className="border-2 border-gray-300 rounded-md m-4 bg-slate-50"
                  width="96%"
                  maxHeight={393}
                >
                  <BarChart
                    margin={{
                      top: 50,
                      right: 10,
                      left: 10,
                    }}
                    // width={750}
                    data={chartData}
                  >
                    <CartesianGrid strokeDasharray="3 1" />
                    <XAxis
                      dataKey="name"
                      tickLine={false}
                      style={{
                        fill: "rgba(0, 0, 0, 0.40)",
                        fontSize: "12px",
                        fontWeight: "800",
                        fontStyle: "normal",
                        fontFamily: "lexend",
                        textAlign: "right",
                        lineHeight: "18px",
                        letterSpacing: "-0.12px",
                      }}
                    />
                    <YAxis
                      dataKey="Energy"
                      tickLine={false}
                      style={{
                        fill: "rgba(0, 0, 0, 0.40)",
                        fontSize: "12px",
                        fontWeight: "800",
                        fontStyle: "normal",
                        fontFamily: "lexend",
                        textAlign: "right",
                        lineHeight: "18px",
                        letterSpacing: "-0.12px",
                      }}
                    />
                    <Tooltip />
                    <Bar
                      dataKey="Energy"
                      fill="#1ac47d"
                      // width="30px"
                    />
                  </BarChart>
                </ResponsiveContainer>
              </span>

              {/*Charge session Heat Map Graph */}

              <div
                style={{ width: `${!isFilterVisible ? "35%" : "48%"}` }}
                className=" border-2 border-gray-300 rounded-md my-4 mr-4 p-3 bg-slate-50 flex"
              >
                <ReactApexChart
                  options={options}
                  series={series}
                  type="heatmap"
                  height={350}
                  fullWidth={`${isFilterVisible ? true : false}`}
                  width={`${isFilterVisible ? 260 : 300}`}
                />
                <div className=" w-1/4 flex flex-col">
                  <div className="mt-4">
                    {seriesIndex >= 0 && dataPointIndex >= 0 && (
                      <div className="mt-14">
                        <span className="font-lexend text-md font-semibold">
                          {" "}
                          {/* This the the function to convert the Days name according to index hovered */}
                          {getDayName(dataPointIndex)},{" "}
                          {/* This is the data of heatmap count */}
                          {series[seriesIndex].name}
                        </span>
                        <br />
                        <span className="font-lexend text-xl font-semibold">
                          {/* this is the data of hovered cell in heatmap */}
                          {series[seriesIndex].data[dataPointIndex]}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="legend_heat mt-10 w-16">
                    <span className="flex items-center justify-between mt-4">
                      <span className="legend_text font-lexend text-gray-400">
                        {" "}
                        10 &gt;
                      </span>{" "}
                      <span>
                        <div
                          style={{ background: "#dcefe7" }}
                          className="w-5 h-5 rounded-lg "
                        ></div>
                      </span>
                    </span>
                    <span className="flex items-center justify-between mt-3">
                      <span className=" legend_text font-lexend text-gray-400">
                        {" "}
                        30 &gt;
                      </span>{" "}
                      <span>
                        <div
                          style={{ background: "#A9E9CE" }}
                          className="w-5 h-5 rounded-lg "
                        ></div>
                      </span>
                    </span>
                    <span className="flex items-center justify-between mt-3">
                      <span className="font-lexend text-gray-400 legend_text">
                        {" "}
                        50&gt;
                      </span>{" "}
                      <span>
                        <div
                          style={{ background: "#85e0ba" }}
                          className="w-5 h-5 rounded-lg  "
                        ></div>
                      </span>
                    </span>

                    <span className="flex items-center justify-between mt-3">
                      <span className=" legend_text font-lexend text-gray-400">
                        {" "}
                        100&gt;
                      </span>{" "}
                      <span>
                        <div
                          style={{ background: "#44e29e" }}
                          className="w-5 h-5 rounded-lg  "
                        ></div>
                      </span>
                    </span>
                  </div>
                  {/* <ColorScaleLegend colorRanges={colorRanges} /> */}
                </div>
              </div>
            </div>

            {/* Consumption Line Graph */}
            <div
              style={{
                width: `${!isFilterVisible ? "99.3%" : "99%"}`,
                marginTop: `${isFilterVisible ? "-20px" : "-12px"}`,
              }}
              className="flex chart-container "
            >
              <p className="font-lexend mt-6 ml-10 absolute top-22 left-30 font-semibold ">
                Consumption Line Graph
              </p>
              <ResponsiveContainer
                className="border-2 border-gray-300 m-4 rounded-md mt-3"
                width="99%"
                height={400}
              >
                <LineChart
                  data={lineChartData}
                  margin={{
                    top: 55,
                    right: 30,
                    left: 10,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 1" />
                  <XAxis
                    tickLine={false}
                    dataKey="name"
                    style={{
                      fill: "rgba(0, 0, 0, 0.40)",
                      fontSize: "12px",
                      fontWeight: "800",
                      fontStyle: "normal",
                      fontFamily: "lexend",
                      textAlign: "right",
                      lineHeight: "18px",
                      letterSpacing: "-0.12px",
                    }}
                  />
                  <YAxis
                    tickLine={false}
                    style={{
                      fill: "rgba(0, 0, 0, 0.40)",
                      fontSize: "12px",
                      fontWeight: "800",
                      fontStyle: "normal",
                      fontFamily: "lexend",
                      textAlign: "right",
                      lineHeight: "18px",
                      letterSpacing: "-0.12px",
                    }}
                  />
                  <Tooltip
                    wrapperStyle={{
                      backgroundColor: "#fff",
                      border: "none",
                      boxShadow: "none",
                      outline: "none",
                    }}
                    content={<CustomTooltip />}
                  />
                  <Legend
                    width={400}
                    wrapperStyle={{
                      top: 10,
                      right: 30,
                      backgroundColor: "#f5f5f5",
                      border: "1px solid #d5d5d5",
                      borderRadius: 3,
                      lineHeight: "40px",
                    }}
                    iconSize={20} // Adjust the size of the legend icons as needed
                    content={(props) => {
                      const { payload } = props;
                      if (!payload) return null; // Handle the case when payload is undefined
                      return (
                        <ul className="flex">
                          {payload.map((entry, index) => (
                            <li
                              className="ml-5"
                              key={`item-${index}`}
                              style={{ color: entry.color }}
                            >
                              <span style={{ marginRight: "5px" }}>
                                {" "}
                                <span className="h-5 w-5">
                                  <TripOriginIcon />
                                </span>{" "}
                              </span>
                              {entry.value}
                            </li>
                          ))}
                        </ul>
                      );
                    }}
                  />
                  <Line
                    type="monotone"
                    dataKey="Energy"
                    stroke="#1ac47d"
                    strokeWidth={3}
                    activeDot={{ r: 8 }}
                  />

                  <Line
                    type="monotone"
                    dataKey="AverageEnergyConsumed"
                    name="Average Energy Consumed"
                    activeDot={{ r: 8 }}
                    strokeWidth={3}
                    stroke="#d69f27"
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
          {/* Filter Container By Default Hidden */}
          {isFilterVisible && (
            <div className="filters lg:w-1/4 flex flex-col  border-2 border-black-400">
              <div className="filter_header z-50 sticky top-0 right-0 flex justify-between px-4 py-5 mb-2">
                <h1 className="font-semibold font-lexend text-lg">Filters</h1>
                <button
                  style={{
                    background: "#1AC47D",
                    width: "35px",
                    height: "35px",
                    padding: "5px",
                    borderRadius: "50%",
                    border: "3px solid #1AAD70", // border for button
                  }}
                  onClick={handleFilterButtonClick}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M3.33301 8.00033L6.66634 11.3337L13.333 4.66699"
                      stroke="white"
                      stroke-width="1.33333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>

              <div className="filter_main_container px-4">
                <h1
                  style={{ width: "200px" }}
                  className="font-lexend text-xl font-bold ml-4"
                >
                  Search by
                </h1>
                {/* Location Accordion  */}
                <Accordion
                  defaultExpanded
                  expanded={expanded}
                  onChange={handleExpansion}
                  slots={{ transition: Fade as AccordionSlots["transition"] }}
                  slotProps={{ transition: { timeout: 400 } }}
                  sx={{
                    "& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
                    "& .MuiAccordionDetails-root": {
                      display: expanded ? "block" : "none",
                    },
                    width: "100%",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography>
                      <h1 className="font-semibold font-lexend text-lg mb-2">
                        Location
                      </h1>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Autocomplete
                      className="mb-4"
                      freeSolo
                      id="checkboxes-tags-demo"
                      disableClearable
                      multiple
                      limitTags={-1}
                      options={allLocations}
                      autoHighlight
                      size="small"
                      value={locationFilter} // Set the value to control the selected Location
                      onChange={(event: any, newValue: any) => {
                        console.log("New Value", newValue);
                        setLocationFilterId(newValue);
                        setLocationChips(
                          (prevChips: { id: string; label: string }[]) => {
                            // Ensure that newValue is an array of objects with 'id' property
                            const newChips: { id: string; label: string }[] =
                              newValue.map((user: any) => ({
                                id: user.id,
                                label: `Location: ${user.label}`,
                              }));

                            const filteredChips = newChips.filter(
                              (chip) =>
                                !prevChips.some(
                                  (prevChip) => prevChip.id === chip.id
                                )
                            );

                            return [...prevChips, ...filteredChips];
                          }
                        );
                        setLocationFilter(newValue);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option: any) => option.label}
                      getOptionDisabled={(option) =>
                        filterChips.some((chip) => chip.id === option.id)
                      }
                      renderOption={(props, option) => {
                        let counter = 0;
                        const uniqueKey = `${option.id}_${
                          option.label
                        }_${counter++}`;
                        const selected = filterChips.some(
                          (chip) => chip.id === option.id
                        );
                        // console.log("selected", selected);

                        return (
                          <MenuItem key={uniqueKey} {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              checked={
                                selected ||
                                filterChips.some(
                                  (chip) => chip.id === option.id
                                )
                              }
                            />
                            <ListItemText primary={option.label} />
                          </MenuItem>
                        );
                      }}
                      renderInput={(params: any) => (
                        <TextField {...params} label="Search" />
                      )}
                    />
                  </AccordionDetails>
                </Accordion>
                {/* Location Type Accordion */}
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <h1 className="font-semibold font-lexend text-lg">
                      Location Type
                    </h1>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="flex">
                      <input
                        type="radio"
                        className="mr-3"
                        checked={locationTypeFilter === "Public Parking"}
                        onChange={() =>
                          handleLocationTypeChange("Public Parking")
                        }
                      />
                      <label htmlFor="" className="font-lexend">
                        Public Parking
                      </label>
                    </div>
                    <div className="flex">
                      <input
                        type="radio"
                        className="mr-3"
                        checked={locationTypeFilter === "Mall"}
                        onChange={() => handleLocationTypeChange("Mall")}
                      />
                      <label htmlFor="" className="font-lexend">
                        Mall
                      </label>
                    </div>

                    <div className="flex">
                      <input
                        type="radio"
                        className="mr-3"
                        checked={locationTypeFilter === "Office Building"}
                        onChange={() =>
                          handleLocationTypeChange("Office Building")
                        }
                      />
                      <label htmlFor="" className="font-lexend">
                        Office Building
                      </label>
                    </div>
                    <div className="flex">
                      <input
                        type="radio"
                        className="mr-3"
                        checked={locationTypeFilter === "Residential"}
                        onChange={() => handleLocationTypeChange("Residential")}
                      />
                      <label htmlFor="" className="font-lexend">
                        Residential
                      </label>
                    </div>
                  </AccordionDetails>
                </Accordion>
                {/* Current Type Accordion */}
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <h1 className="font-semibold font-lexend text-lg">
                      Current Type
                    </h1>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="flex">
                      <input
                        type="radio"
                        className="mr-3"
                        checked={currentTypeFilter === "AC"}
                        onChange={() => handleCurrentChange("AC")}
                      />
                      <label htmlFor="" className="font-lexend">
                        AC
                      </label>
                    </div>
                    <div className="flex">
                      <input
                        type="radio"
                        className="mr-3"
                        checked={currentTypeFilter === "DC"}
                        onChange={() => handleCurrentChange("DC")}
                      />
                      <label htmlFor="" className="font-lexend">
                        DC
                      </label>
                    </div>
                  </AccordionDetails>
                </Accordion>
                {/* User Filter Accordion */}
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <h1 className="font-semibold font-lexend text-lg">Users</h1>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* <Autocomplete
                      className="mb-4"
                      freeSolo
                      id="checkboxes-tags-demo"
                      disableClearable
                      multiple
                      limitTags={-1}
                      options={allUsers}
                      autoHighlight
                      size="small"
                      value={userFilter} // Set the value to control the selected users
                      onChange={handleUserChange}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option: any) => option.label}
                      getOptionDisabled={(option) =>
                        filterChips.some((chip) => chip.id === option.id)
                      }
                      renderOption={(props, option) => {
                        let counter = 0;
                        const uniqueKey = `${option.id}_${
                          option.label
                        }_${counter++}`;
                        const selected = filterChips.some(
                          (chip) => chip.id === option.id
                        );

                        return (
                          <MenuItem key={uniqueKey} {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              checked={
                                selected ||
                                filterChips.some(
                                  (chip) => chip.id === option.id
                                )
                              }
                            />
                            <ListItemText
                              className="font-lexend"
                              primary={option.label}
                            />
                          </MenuItem>
                        );
                      }}
                      renderInput={(params: any) => (
                        <TextField {...params} placeholder=" Search.." />
                      )}
                    /> */}
                     <Autocomplete
                className="mb-4"
                ListboxProps={{ style: { maxHeight: 210 } }}
                id="checkboxes-tags-demo"
                disableClearable
                multiple
                limitTags={-1}
                options={allUsers}
                autoHighlight
                size="small"
                value={userFilter}
                filterOptions={(options, { inputValue }) => {
                  const inputPattern = new RegExp(`^${inputValue.split(' ').map(word => `(?=.*\\b${word})`).join('')}`, 'i'); 
          
                  // Filter options based on the inputValue and ensure selected options are handled properly
                  return options.filter(option => {
                 
                    const label = option.label.toLowerCase();
                    const matchesInput = inputPattern.test(label);
                    const isDisabled = filterChips.some(chip => chip.id === option.id);
          
                    return matchesInput && !isDisabled;
                  });
                }}
                onChange={(event: any, newValue: any) => {
                  setFilterChips(
                    (prevChips: { id: string; label: string }[]) => {
                      const newChips: { id: string; label: string }[] =
                        newValue.map((user: any) => ({
                          id: user.id,
                          label: `User: ${user.label}`,
                        }));

                      const filteredChips = newChips.filter(
                        (chip) =>
                          !prevChips.some((prevChip) => prevChip.id === chip.id)
                      );

                      return [...prevChips, ...filteredChips];
                    }
                  );
                  setUserFilter(newValue);
                }}
                
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option: any) => option.label}
                getOptionDisabled={(option) =>
                  filterChips.some((chip) => chip.id === option.id)
                }

                renderOption={(props, option:any,index) => {
                 const key = `listItem-${index}-${option.id}`;
                  const selected = filterChips.some(
                    (chip) => chip.id === option.id
                  );
                  return (
                    <MenuItem {...props} key={key}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={
                          selected ||
                          filterChips.some((chip) => chip.id === option.id)
                        }
                      />
                      <Typography sx={{ fontFamily: "lexend" ,fontSize:'16px'}}>
                        {/* Formatted name function call */}
                      {capitalizeFirstLetterOfEachWord(option.label)} 
                       
                      </Typography>
                    </MenuItem>
                  );
                }}
                
                renderInput={(params: any) => (
                  <TextField {...params} placeholder="Type to search...." />
                )}
              />
                  </AccordionDetails>
                </Accordion>

                {/* Date Filter - (Today,This Week,This Month,This Year) */}
                <div className="date_filter_container mt-5 ml-4">
                  <h1 className="font-semibold font-lexend text-lg">Date</h1>
                  <div className="flex">
                    <input
                      type="radio"
                      className="mr-3"
                      checked={dateFilter === "Today"}
                      onChange={() => handleDateChange("Today")}
                    />
                    <label htmlFor="" className="font-lexend">
                      Today
                    </label>
                  </div>
                  <div className="flex">
                    <input
                      type="radio"
                      className="mr-3"
                      checked={dateFilter === "This Week"}
                      onChange={() => handleDateChange("This Week")}
                    />
                    <label htmlFor="" className="font-lexend">
                      This Week
                    </label>
                  </div>
                  <div className="flex">
                    <input
                      type="radio"
                      className="mr-3"
                      checked={dateFilter === "This Month"}
                      onChange={() => handleDateChange("This Month")}
                    />
                    <label htmlFor="" className="font-lexend">
                      This Month
                    </label>
                  </div>
                  <div className="flex">
                    <input
                      type="radio"
                      className="accent-black-500 mr-3"
                      checked={dateFilter === "This Year"}
                      onChange={() => handleDateChange("This Year")}
                    />
                    <label htmlFor="" className="font-lexend">
                      This Year
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      className="rounded-checkbox accent-black-500 mr-3"
                      checked={isSelected}
                      onChange={() => handleDateChange("Custom")}
                    />
                    <label htmlFor="" className=" font-lexend">
                      Custom Date
                    </label>
                  </div>
                </div>
                {/* When we select Custom than it will open  the date picker */}
                {isSelected && (
                  <div className="dateSec mb-4">
                    <h1 className="mt-4 mb-3 font-lexend">Custom</h1>
                    <div className="start_dateContainer mt-4">
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <MuiDatePicker
                          label="Start Date"
                          value={startDateFilter}
                          onChange={dateRangeChange}
                          disabled
                          className="mt-4"
                          renderInput={(params) => (
                            <TextField {...params} error={false} />
                          )}
                          inputFormat="DD-MM-YYYY"
                        />
                      </LocalizationProvider>
                    </div>
                    <div className="endDate_container mt-4">
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <MuiDatePicker
                          label="End Date"
                          disabled
                          value={endDateFilter}
                          onChange={(item) => {
                            setEndDateFilter(item || "");
                          }}
                          renderInput={(params) => (
                            <TextField {...params} error={false} />
                          )}
                          inputFormat="DD-MM-YYYY"
                        />
                      </LocalizationProvider>
                    </div>
                    {/* Date Range Picker Code for filtering Range date  */}
                    <div className="rangePicker_container mt-4">
                      {/* Range Date Picker */}
                      <DatePicker
                        selected={startDateFilter}
                        onChange={dateRangeChange}
                        startDate={startDateFilter}
                        endDate={endDateFilter}
                        maxDate={new Date()} // Disabled Future Date
                        selectsRange
                        inline
                        peekNextMonth
                        showMonthDropdown // month dropdown
                        showYearDropdown // year dropdown
                        dropdownMode="select"
                        minDate={new Date(2022, 0, 1)}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
});

export default Energy;
