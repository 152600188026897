import {Tooltip } from "@mui/material";
import { memo, useEffect, useState } from "react";
import "./vendor.scss";
import AddEditModal from "./AddEditModal";
interface TableDataItem extends Parse.Object {
  objectId: string;
  id: string;
  name: string;
  imageURL: string;
  image: string;
  enabled: string;
  parseObject: Parse.Object;
}
interface Props {
  tableData: TableDataItem[];
}
const Vendors = memo(() => {
  // Widget Data
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [addEditData, setAddEditData] = useState<any>({
    id: "",
    name: "",
    image: "",
    enabled: "",
  });
  const getAllVehiclesData = () => {
    setTableLoading(true);
    const parseQuery = new Parse.Query("EV_Manufacturer");
    parseQuery.descending("Name");
   
    parseQuery.limit(100);
    parseQuery.find().then((result: any[]) => {
      let newRow: {
        sno: number;
        id: number;
        name: string;
        imageURL: any;
        manufacturer: string;
        enabled: string;
        obj: any;
      }[] = [];

      result.forEach((item, index) => {
        let url = "/images/placeholder.png";
        let file = item.get("Logo");
        if (file != null) {
          url = file.url();
        }
        let logoUrl = "/images/placeholder.png";
        let logoFile = item.get("ManufacturerLogo");
        if (logoFile != null) {
          logoUrl = logoFile.url();
        }

        newRow.push({
          sno: index + 1,
          id: item.id,
          name: `${item.get("Name")}`,
          imageURL: url,
          manufacturer: `${item.get("Manufacturer")}`,
          enabled: `${item.get("isEnabled")}`,
          obj: item,
        });
      });
      setTableLoading(false);
      setTableData(newRow);
    });
  };

  useEffect(() => {
    getAllVehiclesData();
  }, []);
  console.log("tableData", tableData);
  console.log("addEditDatavv", addEditData);
  const [typeModal,setTypeModal] = useState("")
  const handleOpenModal = (type: string, data: any) => {
    console.log("Type",type);
    console.log("Data",data);
    setTypeModal(type);
    if(type == 'edit'){
      setAddEditData({
            id: data?.id,
            name: data?.name,
            image: data?.image,
            enabled: data?.enabled,
          });
   
    }else{
      setAddEditData( {
        id: "",
        name: "",
        image: "",
        enabled: "",
      })
    }
    setShowAddEditModal(true);
  };

  return (
    <div className="vendor-container">
      <div className="mt-5 mx-5 flex justify-between">
        <h1 className="font-lexend mt-4 text-xl">Manufacturers</h1>
        <button
          className="flex justify-around items-center w-max px-3 py-2 rounded-full"
          style={{ background: "#1AC47D", border: "2px solid #1AAD70" }}
          // onClick={() => setShowAddEditModal(true)}
          onClick={() => {
            handleOpenModal('add', {});
            setShowAddEditModal(true);
          }}
        >
          <span className="mr-2 ml-5 ">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.75 9H11.25M9 6.75V11.25M2.25 9C2.25 9.88642 2.42459 10.7642 2.76381 11.5831C3.10303 12.4021 3.60023 13.1462 4.22703 13.773C4.85382 14.3998 5.59794 14.897 6.41689 15.2362C7.23583 15.5754 8.11358 15.75 9 15.75C9.88642 15.75 10.7642 15.5754 11.5831 15.2362C12.4021 14.897 13.1462 14.3998 13.773 13.773C14.3998 13.1462 14.897 12.4021 15.2362 11.5831C15.5754 10.7642 15.75 9.88642 15.75 9C15.75 8.11358 15.5754 7.23583 15.2362 6.41689C14.897 5.59794 14.3998 4.85382 13.773 4.22703C13.1462 3.60023 12.4021 3.10303 11.5831 2.76381C10.7642 2.42459 9.88642 2.25 9 2.25C8.11358 2.25 7.23583 2.42459 6.41689 2.76381C5.59794 3.10303 4.85382 3.60023 4.22703 4.22703C3.60023 4.85382 3.10303 5.59794 2.76381 6.41689C2.42459 7.23583 2.25 8.11358 2.25 9Z"
                stroke="white"
                stroke-width="1.6"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <span style={{ color: "white" }} className="mr-5">
            <span className="font-lexend">Add Manufacturer</span>
          </span>
        </button>
      </div>

      <div className="container mx-auto ">
        <div className=" mx-5 grid mt-6 items-center grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-7 gap-4">
          {/* Dynamically render API data */}
          {tableData?.map((item: TableDataItem) => (
            <div
              style={{ background: "#F6F8FA" }}
              key={item?.objectId}
              className="w-46 h-42 flex flex-col justify-center p-4"
            >
              <div className="flex justify-between">
                <div className="w-16 h-16">
                <img
                  style={{ mixBlendMode: "normal" }}
                  src={item?.imageURL}
                  alt={item?.name}
                  className="w-full h-full"
                />
                </div>
                {/* <Tooltip  title={<h2 className="font-lexend" style={{ color: "white" }}>Change Logo</h2>}
                  placement="right-end">
                <button
                  className="h-12 w-12 px-3 py-2 rounded-full"
                  style={{
                    cursor: "default",
                    background: "#1AC47D",
                    border: "2px solid #1AAD70",
                  }}
                >
                  
                    <label className="flex h-full" style={{ cursor: "pointer" }} htmlFor={item.id}>
                      <svg
                       className="mx-auto mt-1"
                       width="17"
                       height="17"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.35742 8.57171V3.33242L3.68599 5.00385L2.78599 4.07171L6.00028 0.857422L9.21456 4.07171L8.31456 5.00385L6.64314 3.33242V8.57171H5.35742ZM0.857422 11.1431V7.92885H2.14314V9.85742H9.85742V7.92885H11.1431V11.1431H0.857422Z"
                          fill="white"
                        />
                      </svg>
                    </label>
                    <input
                      accept="image/jpg, image/png, image/jpeg"
                      type="file"
                      id={item?.id}
                      style={{ display: "none" }}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const file = event.target.files?.[0]; // Use optional chaining to safely access the file
                        if (file) {
                          const parseFile = new Parse.File("photo", file);
                          parseFile.save().then(
                            () => {
                              // Assuming item holds the Parse object ID (objectId)
                              const objectId = item.id; // Adjust this according to your actual data structure
                              if (objectId) {
                                // Retrieve the Parse object using its ID
                                const parseObject = new Parse.Query(
                                  Parse.Object.extend("EV_Manufacturer")
                                ).get(objectId);
                                parseObject.then(
                                  (result) => {
                                    result.set("Logo", parseFile);
                                    result.save().then(
                                      () => {
                                        alert("Image Uploaded");
                                        getAllVehiclesData();
                                      },
                                      (error: any) => {
                                        alert(
                                          "Failed to save new object, with error code: " +
                                            error.message
                                        );
                                      }
                                    );
                                  },
                                  (error) => {
                                    alert(
                                      "Error fetching Parse object: " +
                                        JSON.stringify(error)
                                    );
                                  }
                                );
                              } else {
                                alert("Parse object ID not found in item.");
                              }
                            },
                            (error) => {
                              alert("Error Faced" + error);
                            }
                          );
                        }
                      }}
                    />
                 
                </button>
                </Tooltip> */}
                 {/* Edit Pic */}
                 <Tooltip title="Uplaod Image">
                <button
                  className="h-12 w-12  rounded-full"
                  style={{
                    cursor: "default",
                    background: "#1AC47D",
                    border: "2px solid #1AAD70",
                  }}
                >
                 
                    <label className="flex h-full" style={{ cursor: "pointer" }} htmlFor={item.id}>
                      <svg
                        className="mx-auto mt-3"
                        width="17"
                        height="17"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.35742 8.57171V3.33242L3.68599 5.00385L2.78599 4.07171L6.00028 0.857422L9.21456 4.07171L8.31456 5.00385L6.64314 3.33242V8.57171H5.35742ZM0.857422 11.1431V7.92885H2.14314V9.85742H9.85742V7.92885H11.1431V11.1431H0.857422Z"
                          fill="white"
                        />
                      </svg>
                    </label>
                    <input
                      accept="image/jpg, image/png, image/jpeg"
                      type="file"
                      id={item?.id}
                      style={{ display: "none"}}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const file = event.target.files?.[0]; // Use optional chaining to safely access the file
                        if (file) {
                          const parseFile = new Parse.File("photo", file);
                          parseFile.save().then(
                            () => {
                              // Assuming item holds the Parse object ID (objectId)
                              const objectId = item.id; // Adjust this according to your actual data structure
                              if (objectId) {
                                // Retrieve the Parse object using its ID
                                const parseObject = new Parse.Query(
                                  Parse.Object.extend("EV_Manufacturer")
                                ).get(objectId);
                                parseObject.then(
                                  (result) => {
                                    result.set("Logo", parseFile);
                                    result.save().then(
                                      () => {
                                        alert("Image Uploaded");
                                        getAllVehiclesData();
                                      },
                                      (error: any) => {
                                        alert(
                                          "Failed to save new object, with error code: " +
                                            error.message
                                        );
                                      }
                                    );
                                  },
                                  (error) => {
                                    alert(
                                      "Error fetching Parse object: " +
                                        JSON.stringify(error)
                                    );
                                  }
                                );
                              } else {
                                alert("Parse object ID not found in item.");
                              }
                            },
                            (error) => {
                              alert("Error Faced" + error);
                            }
                          );
                        }
                      }}
                    />
                 
                </button>
                </Tooltip>
              </div>

              <p className="font-lexend mt-3 mb-2">{item?.name}</p>
              <button
                className="flex items-center"
                onClick={() => {
                  handleOpenModal('edit', item);
                  setShowAddEditModal(true);
                }}
              >
                <span>
                  <svg
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.66667 2.99057L9.66667 6.99057M1 8.99057L8.8 1.19057C8.92462 1.06842 9.09216 1 9.26667 1C9.44117 1 9.60871 1.06842 9.73333 1.19057L11.4667 2.9239C11.5888 3.04852 11.6572 3.21607 11.6572 3.39057C11.6572 3.56507 11.5888 3.73262 11.4667 3.85724L3.66667 11.6572H1V8.99057Z"
                      stroke="#1877D3"
                      stroke-width="1.33333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
    
                <span className="font-lexend ml-1 lg:text-m md:text-sm"  style={{ color: "#1877D3",textAlign:'left' }}>
                  Edit Manufacturer
                </span>
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* <AddEditModal
        show={showAddEditModal}
        handleClose={() => setShowAddEditModal(false)}
        type="add"
        data={addEditData}
        refresh={() => getAllVehiclesData()}
      />
      <AddEditModal
        show={showAddEditModal}
        type="edit"
        handleClose={() => setShowAddEditModal(false)}
        // refresh={() => props.refresh()}
        refresh={() => getAllVehiclesData()}
        data={addEditData}
      /> */}
      <AddEditModal
      show={showAddEditModal}
      handleClose={() => setShowAddEditModal(false)}
      type={typeModal} // Pass the type to the modal component
      data={addEditData}
      refresh={() => getAllVehiclesData()}
    />
    </div>
  );
});

export default Vendors;
