import { memo, useEffect, useState } from "react";
import Sidebar from "../../components/sidebar";
import DatabaseTable from "./databasetable";
import AddEditModal from "./addEditModal";
import "./assign.scss";
import { Tooltip } from "@mui/material";
import {AddIcon} from "../../icons/svg_icons/icons_svg"
import moment from "moment";
import AddEditFleetRoleModal from "./addEditFleetRoleModal/index";
const AssignRoles = memo(() => {

  const [tableData, setTableData] = useState<any>([]);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showAddEditFleetModal, setShowAddEditFleetModal] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);

  
  const currentUser: any = Parse.User.current(); // Current User details
  let userDetail: any = sessionStorage.getItem("user-details"); // Logged User details
  
  // All Users fetch 
  const getAllUsers = () => {
    setTableLoading(true);
    const Users = Parse.Object.extend("_User");

    const parseQuery = new Parse.Query(Users);
    parseQuery.include("ChargePointOperators");
    parseQuery.include("FleetOperator");
    parseQuery.descending("createdAt");
    parseQuery.equalTo("UserType", "Cloud");
    if (currentUser && !JSON.parse(userDetail).isSuperAdmin) {
      parseQuery.equalTo("CPO", currentUser.get("CPO"));
    }
    parseQuery.find().then((result) => {
      let usersArray: any[] = [];

      result.forEach((item, index) => {
        console.log("item users",item);
        
        usersArray.push({
          sNo: index + 1,
          id: item.id,
          name: item.get("FullName"),
          email: item.get("username"),
          // password: item.get("password"),
          phone: item.get("Phone"),
          access: item.get("RoleAssigned"),
          createdAt: `${moment(item.get("createdAt")).format("lll")}`,
          operator: item.get("CPO").get("Name"),
          operatorId: item.get("CPO").id,
          fleet: item?.get("Fleet")?.get("Name"),
          fleetId: item?.get("Fleet")?.id,
        });
      });
      console.log("User Data Edit",usersArray);
      
      setTableData(usersArray);
      setTableLoading(false);
    });
  };
  useEffect(() => {
    getAllUsers();
  }, []);
  const [allCPOs, setAllCPOs] = useState<any>([]);
  const [allFleets, setAllFleets] = useState<any>([]);
  // Fetch All Cpo data
  const getAllCPOs = () => {
    const parseQuery = new Parse.Query("ChargePointOperators");
    parseQuery.include("User");
    parseQuery.include("ChargePoint");
    parseQuery.limit(100);
    parseQuery.find().then((result: any[]) => {
      let newRow: any[] = [];
      result.forEach((item, index) => {
        newRow.push({
          sNo: index + 1,
          id: item.id,
          companyName: item.get("CompanyName"),
          tradeName: item.get("Name"),
          companyAddress: item.get("Address"),
          contactName: item.get("ContactName"),
          contactNumber: item.get("Phone"),
          contactEmail: item.get("Email"),
          companyDescription: item.get("Description"),
          isWhiteLabel: item.get("isWhiteLabel"),
          obj: item,
        });
      });
      setAllCPOs(newRow);
    });
  };
  const getAllFleets = () => {
    const parseQuery = new Parse.Query("FleetOperator");
    parseQuery.limit(100);
    parseQuery.find().then((result: any[]) => {
      let newRow: any[] = [];
      result.forEach((item, index) => {
        newRow.push({
          sNo: index + 1,
          id: item.id,
          name: item.get("Name"),
          obj: item,
        });
      });
      setAllFleets(newRow);
    });
  };
  useEffect(() => {
    getAllCPOs();
    getAllFleets();
  }, []);
  console.log("allFleets",allFleets);
  
  return (
    <div>
      {/* Heading Row and Button*/}
      <div className="flex justify-between items-center mt-5 mx-4">
        <h1 className="transaction_heading mt-2 font-lexend text-2xl">
          Assign Roles
        </h1>
        <div className="flex items-center">
          <Tooltip title="Add Fleet Roles">
            <button
              className="flex justify-around items-center px-3 py-2 rounded-full mr-3"
              style={{ background: "#1AC47D", border: "2px solid #1AAD70" }}
              onClick={() => setShowAddEditFleetModal(true)}
            >
              <span className="mr-2">
               <AddIcon />
              </span>
              <span className="font-lexend" style={{ color: "white" }}>
                Add Fleet Roles
              </span>
            </button>
          </Tooltip>
          <Tooltip title="Add Roles">
            <button
              className="flex justify-around items-center px-3 py-2 rounded-full mr-3"
              style={{ background: "#1AC47D", border: "2px solid #1AAD70" }}
              onClick={() => setShowAddEditModal(true)}
            >
              <span className="mr-2">
              <AddIcon />
              </span>
              <span className="font-lexend" style={{ color: "white" }}>
                Add Roles
              </span>
            </button>
          </Tooltip>
        </div>
      </div>
      {/* Table Of CPO */}
      <DatabaseTable
        dataRow={tableData}
        allCPO={allCPOs}
        allFleet={allFleets}
        refreshTable={() => getAllUsers()}
        loading={tableLoading}
      />

      {/* Add Modal of assign roles */}
      <AddEditModal
        show={showAddEditModal}
        handleClose={() => setShowAddEditModal(false)}
        type="add"
        refresh={() => getAllUsers()}
        data={{
          id: "",
          companyName: "",
          tradeName: "",
          companyAddress: "",
          contactName: "",
          contactNumber: "",
          contactEmail: "",
          companyDescription: "",
        }}
        allCPO={allCPOs}
      />

      {/* Add Edit Modal For Fleet Operator */}
      <AddEditFleetRoleModal 
       show={showAddEditFleetModal}
       handleClose={() => setShowAddEditFleetModal(false)}
       type="add"
       refresh={() => getAllUsers()}
       data={{
         id: "",
         companyName: "",
         tradeName: "",
         companyAddress: "",
         contactName: "",
         contactNumber: "",
         contactEmail: "",
         companyDescription: "",
       }}
       allCPO={allCPOs}
       allFleet={allFleets}
      />
    </div>
  );
});

export default AssignRoles;
