import { useState, memo } from "react";
import "./BookingsTable.scss";
import { DataGrid } from "@mui/x-data-grid";

interface tableProps {
  dataRow: any;
  refresh: any;
  loading: boolean;
}

const DatabaseTable = memo((props: tableProps) => {
  const { dataRow } = props;
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [addEditData, setAddEditData] = useState({});
  const columns = [
    {
      field: "id",

      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          S.NO
        </span>
      ),
      width: 60,
    },
    {
      field: "name",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          User Name
        </span>
      ),
      renderCell: (params: any) => {
        return <span className="font-lexend">{params?.row?.name}</span>;
      },
      width: 180,
      editable: true,
    },
    {
      field: "mobileNumber",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Mobile
        </span>
      ),
      renderCell: (params: any) => {
        return <span className="font-lexend">{params?.row?.mobileNumber}</span>;
      },

      width: 180,
      editable: true,
    },

    {
      field: "startTime",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Start time
        </span>
      ),
      renderCell: (params: any) => {
        return <span className="font-lexend">{params?.row?.startTime}</span>;
      },
      width: 180,
      editable: true,
    },

    {
      field: "endTime",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          End time
        </span>
      ),
      renderCell: (params: any) => {
        return <span className="font-lexend">{params?.row?.endTime}</span>;
      },
      width: 180,
      editable: true,
    },
    {
      field: "serial",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Charger Serial N.O
        </span>
      ),
      renderCell: (params: any) => {
        return <span className="font-lexend">{params?.row?.serial}</span>;
      },
      width: 180,
      editable: true,
    },
    {
      field: "duration",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Duration
        </span>
      ),
      renderCell: (params: any) => {
        return <span className="font-lexend">{params?.row?.duration}</span>;
      },
      width: 180,
      editable: true,
    },
    {
      field: "status",
      width: 200,
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Status
        </span>
      ),
      headerName: "Status",
      renderCell: (params: {
        row: {
          id: any;
          cancelled: any;
          expired: any;
          upcoming: any;
          obj: { get: (arg0: string) => any };
        };
      }) => {
        if (params.row.upcoming === "true") {
          return (
            <span className="font-lexend flex">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.75 8L7.25 9.5L10.25 6.5M1.25 8C1.25 8.88642 1.42459 9.76417 1.76381 10.5831C2.10303 11.4021 2.60023 12.1462 3.22703 12.773C3.85382 13.3998 4.59794 13.897 5.41689 14.2362C6.23583 14.5754 7.11358 14.75 8 14.75C8.88642 14.75 9.76417 14.5754 10.5831 14.2362C11.4021 13.897 12.1462 13.3998 12.773 12.773C13.3998 12.1462 13.897 11.4021 14.2362 10.5831C14.5754 9.76417 14.75 8.88642 14.75 8C14.75 7.11358 14.5754 6.23583 14.2362 5.41689C13.897 4.59794 13.3998 3.85382 12.773 3.22703C12.1462 2.60023 11.4021 2.10303 10.5831 1.76381C9.76417 1.42459 8.88642 1.25 8 1.25C7.11358 1.25 6.23583 1.42459 5.41689 1.76381C4.59794 2.10303 3.85382 2.60023 3.22703 3.22703C2.60023 3.85382 2.10303 4.59794 1.76381 5.41689C1.42459 6.23583 1.25 7.11358 1.25 8Z"
                  stroke="#19885A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span className="ml-2" style={{ color: "#19885A" }}>
                Confirmed
              </span>{" "}
            </span>
          );
        } else if (params.row.expired === "true") {
          return (
            <span style={{ color: "#C99456" }} className="font-lexend flex">
              <svg
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.85742 9H11.3574M2.35742 9C2.35742 9.88642 2.53202 10.7642 2.87123 11.5831C3.21045 12.4021 3.70766 13.1462 4.33445 13.773C4.96125 14.3998 5.70536 14.897 6.52431 15.2362C7.34326 15.5754 8.221 15.75 9.10742 15.75C9.99384 15.75 10.8716 15.5754 11.6905 15.2362C12.5095 14.897 13.2536 14.3998 13.8804 13.773C14.5072 13.1462 15.0044 12.4021 15.3436 11.5831C15.6828 10.7642 15.8574 9.88642 15.8574 9C15.8574 8.11358 15.6828 7.23583 15.3436 6.41689C15.0044 5.59794 14.5072 4.85382 13.8804 4.22703C13.2536 3.60023 12.5095 3.10303 11.6905 2.76381C10.8716 2.42459 9.99384 2.25 9.10742 2.25C8.221 2.25 7.34326 2.42459 6.52431 2.76381C5.70536 3.10303 4.96125 3.60023 4.33445 4.22703C3.70766 4.85382 3.21045 5.59794 2.87123 6.41689C2.53202 7.23583 2.35742 8.11358 2.35742 9Z"
                  stroke="#C99456"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span className="ml-2">Expired</span>
            </span>
          );
        } else if (params.row.cancelled === "true") {
          return (
            <span style={{ color: "#C41A1A" }} className="font-lexend flex">
              <svg
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.60742 7.5L10.6074 10.5M10.6074 7.5L7.60742 10.5M2.35742 9C2.35742 9.88642 2.53202 10.7642 2.87123 11.5831C3.21045 12.4021 3.70766 13.1462 4.33445 13.773C4.96125 14.3998 5.70536 14.897 6.52431 15.2362C7.34326 15.5754 8.221 15.75 9.10742 15.75C9.99384 15.75 10.8716 15.5754 11.6905 15.2362C12.5095 14.897 13.2536 14.3998 13.8804 13.773C14.5072 13.1462 15.0044 12.4021 15.3436 11.5831C15.6828 10.7642 15.8574 9.88642 15.8574 9C15.8574 8.11358 15.6828 7.23583 15.3436 6.41689C15.0044 5.59794 14.5072 4.85382 13.8804 4.22703C13.2536 3.60023 12.5095 3.10303 11.6905 2.76381C10.8716 2.42459 9.99384 2.25 9.10742 2.25C8.221 2.25 7.34326 2.42459 6.52431 2.76381C5.70536 3.10303 4.96125 3.60023 4.33445 4.22703C3.70766 4.85382 3.21045 5.59794 2.87123 6.41689C2.53202 7.23583 2.35742 8.11358 2.35742 9Z"
                  stroke="#C41A1A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span className="ml-2">Cancelled</span>
            </span>
          );
        }
      },
    },
  ];

  return (
    <div className="booking-table">
      <DataGrid
        rows={dataRow}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[5]}
        checkboxSelection={false}
        disableSelectionOnClick
        autoHeight
        loading={props.loading}
      />
    </div>
  );
});

export default DatabaseTable;
