import { Button, Tooltip } from "@mui/material";
import { memo, useEffect, useState } from "react";
import Sidebar from "../../components/sidebar";
import DatabaseTable from "./databasetable";
import AddEditModal from "./addEditModal";
import AddIcon from "@mui/icons-material/Add";
import "./promocode.scss";
import moment from "moment";

const Promocode = memo(() => {
  //Tabel
  const [tableData, setTableData] = useState<any>([]);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);

  const getAllPromoCodeData = () => {
    setTableLoading(true);
    const parseQuery = new Parse.Query("PromoCodes");
    parseQuery.include("User");
    parseQuery.include("ChargePoint");
    parseQuery.limit(100);
    parseQuery.find().then((result: any[]) => {
      let newRow: any[] = [];
      result.forEach((item, index) => {
        newRow.push({
          sNo: index + 1,
          id: item.id,
          code: `${item.get("Code")}`,
          value: `${item.get("Value")}`,
          details: `${item.get("Description")}`,
          title: `${item.get("Name")}`,
          start: `${moment(item.get("createdAt")).format("lll")}`,
          status: `${item.get("isValid") ? "true" : "false"}`,
          obj: item,
        });
      });
      setTableData(newRow);
      setTableLoading(false);
    });
  };

  useEffect(() => {
    getAllPromoCodeData();
  }, []);
  return (
    <div>
    {/* Heading Row and Button*/}
    
    <div className="flex justify-between items-center mt-5 mx-4">
      <h1 className="transaction_heading mt-2 font-lexend text-2xl">
        Promo Code
      </h1>
      <div>
        <Tooltip title="Add new Promocode">
          <button
            className="flex justify-around items-center px-3 py-2 rounded-full mr-3"
            style={{ background: "#1AC47D", border: "2px solid #1AAD70" }}
            // onClick={() => setShowAddEditModal(true)}
            onClick={() => setShowAddEditModal(true)}
          >
            <span className="mr-2">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.75 9H11.25M9 6.75V11.25M2.25 9C2.25 9.88642 2.42459 10.7642 2.76381 11.5831C3.10303 12.4021 3.60023 13.1462 4.22703 13.773C4.85382 14.3998 5.59794 14.897 6.41689 15.2362C7.23583 15.5754 8.11358 15.75 9 15.75C9.88642 15.75 10.7642 15.5754 11.5831 15.2362C12.4021 14.897 13.1462 14.3998 13.773 13.773C14.3998 13.1462 14.897 12.4021 15.2362 11.5831C15.5754 10.7642 15.75 9.88642 15.75 9C15.75 8.11358 15.5754 7.23583 15.2362 6.41689C14.897 5.59794 14.3998 4.85382 13.773 4.22703C13.1462 3.60023 12.4021 3.10303 11.5831 2.76381C10.7642 2.42459 9.88642 2.25 9 2.25C8.11358 2.25 7.23583 2.42459 6.41689 2.76381C5.59794 3.10303 4.85382 3.60023 4.22703 4.22703C3.60023 4.85382 3.10303 5.59794 2.76381 6.41689C2.42459 7.23583 2.25 8.11358 2.25 9Z"
                  stroke="white"
                  stroke-width="1.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span className="font-lexend" style={{ color: "white" }}>
            Add new Promocode
            </span>
          </button>
        </Tooltip>
      </div>
    </div>
    {/* Table Of Promo Code */}
    <DatabaseTable
        dataRow={tableData}
        refresh={() => getAllPromoCodeData()}
        loading={tableLoading}
      />
    
    {/* Add PromoCode Modal */}
    <AddEditModal
        show={showAddEditModal}
        handleClose={() => setShowAddEditModal(false)}
        type="add"
        data={{
          id: "",
          code: "",
          value: "",
          start: "",
          status: "false",
          range: "",
        }}
        refresh={() => getAllPromoCodeData()}
      />



  </div>
  );
});

export default Promocode;
