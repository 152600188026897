import { useState, memo } from "react";
import "./PromocodeTable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Publish } from "@mui/icons-material";
import { Button, Tooltip } from "@mui/material";
import AddEditModal from "../addEditModal";
interface tableProps {
  dataRow: any;
  refresh: any;
  loading: any;
}

const DatabaseTable = memo((props: tableProps) => {
  const { dataRow } = props;
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [addEditData, setAddEditData] = useState({});
  const columns = [
    {
      field: "sNo",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          S.NO
        </span>
      ),
      renderCell: (params: any) => {
        console.log("data param S.NO", params);

        return <span className="font-lexend">{params?.row?.sNo}</span>;
      },
      width: 150,
    },
    {
      field: "code",
      headerName: "Code",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Code
        </span>
      ),
      renderCell: (params: any) => {
        return <span className="font-lexend">{params?.row?.code}</span>;
      },
      width: 180,
      editable: true,
    },
    {
      field: "value",
      headerName: "Value",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Value
        </span>
      ),
      renderCell: (params: any) => {
        return <span className="font-lexend">{params?.row?.value}</span>;
      },
      width: 180,
      editable: true,
    },
    {
      field: "start",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Created At
        </span>
      ),
      renderCell: (params: any) => {
        return <span className="font-lexend">{params?.row?.start}</span>;
      },
      width: 180,
      editable: true,
    },
    {
      field: "status",
      width: 200,
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Status
        </span>
      ),
      renderCell: (params: {
        row: { obj: { get: (arg0: string) => any } };
      }) => {
        let currentStatus = params.row.obj.get("isValid");
        return currentStatus === false ? (
          <div>
            <span
              style={{ color: "#C41A1A" }}
              className="font-lexend flex items-center"
            >
              <span>
                <svg
                className="mr-2"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.60938 6.5L9.60938 9.5M9.60938 6.5L6.60938 9.5M1.35938 8C1.35937 8.88642 1.53397 9.76417 1.87319 10.5831C2.21241 11.4021 2.70961 12.1462 3.3364 12.773C3.9632 13.3998 4.70731 13.897 5.52626 14.2362C6.34521 14.5754 7.22295 14.75 8.10938 14.75C8.9958 14.75 9.87354 14.5754 10.6925 14.2362C11.5114 13.897 12.2555 13.3998 12.8823 12.773C13.5091 12.1462 14.0063 11.4021 14.3456 10.5831C14.6848 9.76417 14.8594 8.88642 14.8594 8C14.8594 7.11358 14.6848 6.23583 14.3456 5.41689C14.0063 4.59794 13.5091 3.85382 12.8823 3.22703C12.2555 2.60023 11.5114 2.10303 10.6925 1.76381C9.87354 1.42459 8.9958 1.25 8.10938 1.25C7.22295 1.25 6.34521 1.42459 5.52626 1.76381C4.70731 2.10303 3.9632 2.60023 3.3364 3.22703C2.70961 3.85382 2.21241 4.59794 1.87319 5.41689C1.53397 6.23583 1.35937 7.11358 1.35938 8Z"
                    stroke="#C41A1A"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              Inactive
            </span>
          </div>
        ) : (
          <div>
            <span
              style={{ color: "#1A885A" }}
              className="font-lexend flex items-center"
            >
              <span>
                <svg
                 className="mr-2"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.85938 8L7.35938 9.5L10.3594 6.5M1.35938 8C1.35937 8.88642 1.53397 9.76417 1.87319 10.5831C2.21241 11.4021 2.70961 12.1462 3.3364 12.773C3.9632 13.3998 4.70731 13.897 5.52626 14.2362C6.34521 14.5754 7.22295 14.75 8.10938 14.75C8.9958 14.75 9.87354 14.5754 10.6925 14.2362C11.5114 13.897 12.2555 13.3998 12.8823 12.773C13.5091 12.1462 14.0063 11.4021 14.3456 10.5831C14.6848 9.76417 14.8594 8.88642 14.8594 8C14.8594 7.11358 14.6848 6.23583 14.3456 5.41689C14.0063 4.59794 13.5091 3.85382 12.8823 3.22703C12.2555 2.60023 11.5114 2.10303 10.6925 1.76381C9.87354 1.42459 8.9958 1.25 8.10938 1.25C7.22295 1.25 6.34521 1.42459 5.52626 1.76381C4.70731 2.10303 3.9632 2.60023 3.3364 3.22703C2.70961 3.85382 2.21241 4.59794 1.87319 5.41689C1.53397 6.23583 1.35937 7.11358 1.35938 8Z"
                    stroke="#1A885A"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              Active
            </span>
          </div>
        );
      },
    },
    {
      field: "Action",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Actions
        </span>
      ),
      width: 220,
      renderCell: (params: any) => {
        return (
          <Tooltip title="Edit PromoCode">
          <button
            style={{
              background: "#1AC47D",
              width: "35px",
              height: "35px",
              padding: "5px",
              borderRadius: "50%",
              border: "3px solid #1AAD70",
            
            }}
            onClick={() => {
                  setShowAddEditModal(true);
                  setAddEditData({
                    id: params.row.id,
                    code: params.row.code,
                    value: params.row.value,
                    status: params.row.status,
                    title: params.row.title,
                    details: params.row.details,
                  });
                }}
          >
            <svg
              className="ml-1"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.33268 2.66635L9.33268 6.66635M0.666016 8.66635L8.46602 0.866353C8.59063 0.744201 8.75818 0.675781 8.93268 0.675781C9.10718 0.675781 9.27473 0.744201 9.39935 0.866353L11.1327 2.59969C11.2548 2.72431 11.3233 2.89185 11.3233 3.06635C11.3233 3.24085 11.2548 3.4084 11.1327 3.53302L3.33268 11.333H0.666016V8.66635Z"
                stroke="white"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </Tooltip>
         
        );
      },
    },
  ];


  console.log("addEditData",addEditData);
  

  return (
    <div className="ev-table mt-5">
      <DataGrid
        rows={dataRow}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[5]}
        checkboxSelection={false}
        disableSelectionOnClick
        autoHeight
        loading={props.loading}
      />
      <AddEditModal
        show={showAddEditModal}
        type="edit"
        handleClose={() => setShowAddEditModal(false)}
        refresh={() => props.refresh()}
        data={addEditData}
      />
    </div>
  );
});

export default DatabaseTable;
