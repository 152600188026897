import { memo } from "react";
import "./widget.scss";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";

import {
  ChargingDurationIcon,
  EnergyConsumedIcon,
  PriceChangeSvgIcon,
  TotalChargeSessionIcon,
} from "../../../icons/svg_icons/icons_svg";
type widgetProps = {
  type: String;

  data: any;
};

const Widget = memo((props: widgetProps) => {
  const { type, data } = props;

  let content;
  switch (props.type) {
    case "ChargingDuration":
      content = {
        title: "Charging Duration",
        thisMonth: data.thisMonth,
        lastMonth: data.lastMonth,
        total: data.total,
        isMoney: true,
        link: "",
        icon: <ChargingDurationIcon />,
      };
      break;
    case "usage":
      content = {
        title: "Energy Consumed",
        thisMonth: data.thisMonth,
        lastMonth: data.lastMonth,
        total: data.total.toFixed(2) + " " + "kW",
        isMoney: false,
        link: "",
        icon: <EnergyConsumedIcon />,
      };
      break;
    case "customers":
      content = {
        title: "Customer Enrollments",
        thisMonth: (50.0345).toFixed(2),
        lastMonth: (20.0345).toFixed(2),
        icon: <EmojiPeopleIcon className="icon" />,
      };
      break;
    case "billedRevenue":
      content = {
        title: "Revenue",
        thisMonth: data.thisMonth.toFixed(2),
        lastMonth: data.lastMonth.toFixed(2),
        total: "₹" + " " + data.total.toFixed(2),
        isMoney: false,
        link: "",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
          >
            <path
              d="M15 8.26953H9H10C10.7956 8.26953 11.5587 8.5856 12.1213 9.14821C12.6839 9.71082 13 10.4739 13 11.2695C13 12.0652 12.6839 12.8282 12.1213 13.3909C11.5587 13.9535 10.7956 14.2695 10 14.2695H9L12 17.2695M9 11.2695H15M3 12.2695C3 13.4514 3.23279 14.6218 3.68508 15.7137C4.13738 16.8056 4.80031 17.7978 5.63604 18.6335C6.47177 19.4692 7.46392 20.1322 8.55585 20.5844C9.64778 21.0367 10.8181 21.2695 12 21.2695C13.1819 21.2695 14.3522 21.0367 15.4442 20.5844C16.5361 20.1322 17.5282 19.4692 18.364 18.6335C19.1997 17.7978 19.8626 16.8056 20.3149 15.7137C20.7672 14.6218 21 13.4514 21 12.2695C21 11.0876 20.7672 9.91731 20.3149 8.82538C19.8626 7.73345 19.1997 6.7413 18.364 5.90557C17.5282 5.06984 16.5361 4.40691 15.4442 3.95462C14.3522 3.50232 13.1819 3.26953 12 3.26953C10.8181 3.26953 9.64778 3.50232 8.55585 3.95462C7.46392 4.40691 6.47177 5.06984 5.63604 5.90557C4.80031 6.7413 4.13738 7.73345 3.68508 8.82538C3.23279 9.91731 3 11.0876 3 12.2695Z"
              stroke="#354052"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        ),
      };
      break;
    case "avRevenue":
      content = {
        title: "Average Revenue Per Session",
        thisMonth: data.thisMonth.toFixed(2),
        lastMonth: data.lastMonth.toFixed(2),
        total: data.total,
        isMoney: true,
        link: "",
        icon: <PriceChangeSvgIcon />,
      };
      break;
    case "chargeSession":
      content = {
        title: "Charge Sessions",
        thisMonth: data.thisMonth,
        lastMonth: data.lastMonth,
        total: data.total,
        isMoney: true,
        link: "",
        icon: <TotalChargeSessionIcon />,
      };

      break;
    default:
      break;
  }
  return (
    <div className="Widget_container fleet_container">
      <div className="title flex items-center mx-1">
        <div className="icon_widget_transaction">{content?.icon}</div>
        <h1 className="font-lexend ml-2">{content?.title}</h1>
      </div>

      <h1 className=" content ml-2"> {content?.total}</h1>
    </div>
  );
});

export default Widget;
