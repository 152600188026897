import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
};

interface PropTypes {
    show: boolean;
    handleClose: any;
    data: any;
    type: string;
  }


export default function CloseChargerModal(props: PropTypes) {
  const data = props?.data
  console.log("Reboot",data);

  const [resetType,setResetType]= React.useState("")

  const handleResetOcppCharging = (name: any) => {
    if (!window.confirm(`Are you sure you want to reboot the charger ${name} ?`)) return;
  
    const cpName = props?.data[0]; // array of [0] which is ChargerId
    const reset_Type = resetType; // Replace this with the actual reset type you need
  
    try {
        const url = `${process.env.REACT_APP_OCPP_BASE_URL}/reset?cp_name=${encodeURIComponent(cpName)}&reset_type=${encodeURIComponent(reset_Type)}`;
  
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        });
    } catch (error) {
        alert("Error Unable to Reboot ")
    }

  
  };
  


  return (
    <div>
      <Modal
        open={props?.show}
        onClose={props?.handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        
        <Box sx={{ ...style, width: "45%" }}>
            <h2 style={{background:'#1A72C4',border:'3px solid #155EA1'}} className='font-lexend text-xl font-semibold py-3 mb-4 pl-4 text-white'>Reboot Charger</h2>
          <span className='font-lexend text-xl m-4 pt-4'>Are you sure you want to Reboot the charger <span className='font-bold font-lexend'>{props?.data[0]}</span> ?</span>
          <div id="parent-modal-description">
            <div className='ml-4 mt-4 '>
                <p className='font-lexend'>Please Select the type to reboot the Charger. </p>
                <div className="flex">
                <div className="flex items-center mt-4 mr-5">
                  <input
                    type="radio"
                    style={{
                      background: "black",
                      width: "20px",
                      height: "20px",
                    }}
                     checked={resetType === "soft"}
                    onChange={() => setResetType("soft")}
                  />
                  <label htmlFor="" className="font-lexend ml-2">
                    SOFT
                  </label>
                </div>
                <div className="flex mt-4 items-center">
                  <input
                    type="radio"
                    style={{
                      background: "black",
                      width: "20px",
                      height: "20px",
                    }}
                    checked={resetType === "hard"}
                    onChange={() => setResetType("hard")}
                  />
                  <label htmlFor="" className="font-lexend ml-2">
                    HARD
                  </label>
                </div>
              </div>
            </div>
            <button
                   className="flex ml-4 mb-4 justify-around text-white items-center px-3 py-2 rounded-full mr-3 mt-4"
                   style={{background:'#1AC47D',border:'2px solid #1AAD70'}}
                 onClick={() => handleResetOcppCharging(props?.data)}
          >
              <span className='mr-2'>
              <svg width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.6375 5.4166C10.49 4.2912 9.93738 3.25814 9.08312 2.5108C8.22885 1.76346 7.1315 1.35307 5.99647 1.35647C4.86145 1.35986 3.76657 1.7768 2.91679 2.52924C2.06701 3.28167 1.52058 4.31802 1.3798 5.44428C1.23901 6.57055 1.51353 7.70951 2.15195 8.64798C2.79037 9.58644 3.74893 10.2601 4.84819 10.5427C5.94745 10.8254 7.11206 10.6978 8.124 10.1837C9.13595 9.66965 9.92585 8.8044 10.3458 7.74994M10.6375 10.6666V7.74994H7.72085" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                </span>
                 <span>
                 Reboot
                    </span>  
            </button>
          </div>
          {/* <ChildModal /> */}
        </Box>
      </Modal>
    </div>
  );
}
