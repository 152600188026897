import React, { useEffect, useState, memo } from "react";
import "./databaseTable.scss";
import AddEditModal from "../addEditModal/AddEditModal";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AddEditChargerModal from "../addEditCharger/AddEditChargerModal";
import {
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
  Paper,
  TableContainer,
  Button,
  Stack,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import { spawn } from "child_process";
import CloseChargerModal from "../ActionChargerModal";
import StopChargerModal from "../ActionChargerModal/StopModal";

interface tableProps {
  dataRow: any;
  refreshLocation: any;
  liveOcppData: any;
  loading: boolean;
  setIsFilterVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const DatabaseTable = memo((props: tableProps) => {
  const [allChargePointData, setAllChargePointData] = useState<any>([]);
  const {
    dataRow,
    refreshLocation,
    liveOcppData,
    loading,
    setIsFilterVisible,
  } = props;
  console.log("setIsFilterVisible", setIsFilterVisible);

  // TO get all live session of OCPP Charges and its Data
  const loadSessions = () => {
    const parseQuery = new Parse.Query("ChargeSession");

    parseQuery.include("ChargePoint");
    parseQuery.include("Location");
    parseQuery.include("Vehicle");
    parseQuery.include("User");
    parseQuery.descending("createdAt");

    parseQuery.find().then((result) => {
      let newRow: any[] = [];
      result.forEach((item, index) => {
        console.log("Charging Data", item);

        if (item.get("Live") && item.get("ChargePoint").get("isOCPP")) {
          console.log("live session", item);
          newRow.push({
            chargeSessionId: item.id,
            userId: `${item.get("User").id}`,
            serial: `${item.get("ChargePoint").get("Serial")}`,
            transactionId: `${item.get("TransactionId")}`,
            userName: `${item.get("User").get("FullName")}`,
            createdAt: `${item.get("createdAt")}`,
            MeterStart: `${item.get("MeterStart")}`,
          });
        } else {
          return;
        }
      });

      getAllChargePoint(newRow);
    });
  };

  useEffect(() => {
    if (props.liveOcppData.length) loadSessions();
  }, [props.liveOcppData]);

  let userDetail: any = sessionStorage.getItem("user-details");
  const currentUser: any = Parse.User.current();
  const getAllChargePoint = (liveOcppsChargesession: any) => {
    console.log("live Charger data", liveOcppsChargesession);
    const ChargePoints = Parse.Object.extend("Chargers");

    const parseQuery = new Parse.Query(ChargePoints);
    parseQuery.include("Location");
    parseQuery.include("CP_Vendors");
    parseQuery.include("ConnectorType");
    parseQuery.include("ChargePointOperators");
    parseQuery.limit(1000);
    if (currentUser && !JSON.parse(userDetail).isSuperAdmin) {
      parseQuery.equalTo("CPO", currentUser.get("CPO"));
    }
    parseQuery.find().then((result) => {
      let chargerArray: any[] = [];

      result.forEach((item, index) => {
        let connectorType = item.get("ConnectorType");
        let vendor = item.get("Vendor");
        let cpo = item.get("CPO");
        let ab = {
          id: item.id,
          serial: item.get("Serial"),
          power: `${item.get("Power")}`,
          chargingRate: `${item.get("ChargingRate")}`,
          location: `${item.get("Location")?.id}`,
          status: item.get("isOCPP")
            ? props.liveOcppData?.filter(
                (el: any) => el.ocppName === item.get("ChargeId")
              )[0]?.status || "Offline"
            : "",
          ocppName: props.liveOcppData?.filter(
            (el: any) => el.ocppName === item.get("ChargeId")
          )[0]?.ocppName,
          isActive: props.liveOcppData?.filter(
            (el: any) => el.ocppName === item.get("ChargeId")
          )[0]?.active,
          connector: {
            id: connectorType?.id ? connectorType.id : "",
            name: connectorType?.get("Name") ? connectorType.get("Name") : "",
          },
          tariff: `${item.get("Cost")}`,
          taxRate: `${item.get("TaxRate")}`,
          user: "-",
          duration: "-",
          connected: "-",
          energyConsumed: "-",
          vendor: {
            id: vendor?.id,
            name: vendor?.get("Name"),
          },
          chargerId: item.get("ChargeId"),
          inclusiveTax: item.get("inclusiveTax") ? "true" : "false",
          cost: item.get("Cost"),
          isEnabled: item.get("isEnabled") ? "true" : "false",
          disabledReason: item.get("DisableReason"),
          cpo: {
            id: cpo?.id,
            name: cpo?.get("Name"),
          },
          isOcpp: item.get("isOCPP") ? "true" : "false",
          // meterStart: liveOcppsChargesession?.map((ele:any)=> ele?.MeterStart)
        };

        // Trasaction id to get meter values and userId to Stop charging
        if (
          liveOcppsChargesession
            .map((item: any) => item.serial)
            .includes(ab.serial)
        ) {
          getOcppData(
            liveOcppsChargesession.filter(
              (item: any) => item.serial === ab.serial
            )[0].transactionId,
            ab,
            liveOcppsChargesession.filter(
              (item: any) => item.serial === ab.serial
            )[0].userId,
            liveOcppsChargesession.filter(
              (item: any) => item.serial === ab.serial
            )[0].userName,
            liveOcppsChargesession.filter(
              (item: any) => item.serial === ab.serial
            )[0].createdAt,
            liveOcppsChargesession.filter(
              (item: any) => item.serial === ab.serial
            )[0].chargeSessionId,
            liveOcppsChargesession.filter(
              (item: any) => item.serial === ab.serial
            )[0].MeterStart
          );
        } else {
          chargerArray.push(ab);
        }
      });

      setAllChargePointData(chargerArray);
    });
  };

  console.log("allChargePointData", allChargePointData);

  const getOcppData = async (
    id: any,
    item: any,
    userId: any,
    userName: any,
    start: any,
    chargeSessionId: any,
    meterStart: any
  ) => {
    const transaction_ID = id;
    await fetch(`${process.env.REACT_APP_OCPP_BASE_URL}/meter_value/${id}`)
      .then((response: any) => response.json())
      .then((res: any) => {
        console.log("Live meter Value", res);
        setAllChargePointData((oldArray: any) => [
          ...oldArray,
          {
            ...item,
            energyConsumed: (res.energy_active_import_register / 1000).toFixed(
              2
            ),

            duration:
              moment.duration(moment(res.timestamp).diff(start)).hours() +
              "hr" +
              " " +
              moment.duration(moment(res.timestamp).diff(start)).minutes() +
              "min",

            ocppCost: (
              item.tariff *
              (res.energy_active_import_register / 1000)
            ).toFixed(2),

            // ocppTransactionId: res.transaction_id,
            ocppTransactionId: transaction_ID,
            ocppUserId: userId,
            userName: userName,
            chargeSessionId: chargeSessionId,
            meterStart: meterStart,
          },
        ]);
      });
  };

  const handleCharging = async () => {
    try {
      // Create a Parse.Query for the ChargeSessions class
      const ChargeSessions = new Parse.Query("ChargeSessions");
      // Filter the query to fetch only the data where live is true
      ChargeSessions.equalTo("live", true);
      // Fetch the data
      const results = await ChargeSessions.find();

      // Process the fetched data
      results.forEach((result) => {
        // Do whatever you want with each result
        console.log("Live Chargers Data", result.toJSON());
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function createData(
    id: string,
    stationName: number,
    type: number,
    city: number,
    access: string,
    operator: number
  ) {
    return {
      id,
      stationName,
      type,
      city,
      access,
      operator,
    };
  }

  const handleResetOcppCharging = (name: any) => {
    if (
      !window.confirm(`Are you sure you want to reboot the charger ${name} ?`)
    )
      return;

    (async () => {
      await fetch(
        `${process.env.REACT_APP_OCPP_BASE_URL}/reset?name=${name}&reset_type=hard`,
        {
          method: "POST",
        }
      ).then(() => {
        alert(`${name} Rebooted`);
      });
    })();
  };

  const handleStopChargingBack4app = (
    id: any,
    energy: any,
    time: any,
    cost: any,
    range: any
  ) => {
    let myNewObject: Parse.Object = new Parse.Object("ChargeSession");
    console.log("hui", typeof id);
    myNewObject.set("objectId", id);
    myNewObject.set("TotalEnergyConsumed", Number(energy));
    // myNewObject.set("TotalTimeConsumed", time);
    myNewObject.set("TotalCost", cost);
    // myNewObject.set("RangeAdded", range);
    myNewObject.set("Live", false);

    myNewObject.save().then(() => {
      alert("Charger Stopped");
    });
  };
  const [actionType, setActionType] = useState("");

  const [rebootChargerData, setRebootChargerData] = useState({});
  const [StopChargerData, setStopChargerData] = useState({});

  

  const handleStopChargerModal = (item: any) => {
    setRebootChargerData(item);
  };

  console.log("Close Charger data", rebootChargerData);

  // const handleStopOcppCharging = (
  //   csId: any,
  //   name: any,
  //   transactionId: any,
  //   userId: any,
  //   cost: any,
  //   energy: any,
  //   time: any,
  //   range: any
  // ) => {
  //   console.log("Received parameters:", { csId, name, transactionId, userId, cost, energy, time, range });

  //   if (!window.confirm(`Are you sure you want to stop the charging session ${name}?`)) return;

  //   (async () => {
  //     try {
  //       const url = `${process.env.REACT_APP_OCPP_BASE_URL}/emergency_stop`;
  //       const body = {
  //         cp_name: name,
  //         transaction: transactionId,
  //       };

  //       console.log("Sending request to:", url);
  //       console.log("Request body:", body);

  //       const response = await fetch(url, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(body),
  //       });

  //       if (!response.ok) {
  //         throw new Error(`Request failed with status ${response.status}`);
  //       }

  //       console.log("Fetch response successful");

  //       // Ensure csId and other parameters are logged before calling the next function
  //       console.log("Calling handleStopChargingBack4app with parameters:", { csId, energy, time, cost, range });

  //       await handleStopChargingBack4app(csId, energy, time, cost, range);
  //     } catch (error) {
  //       console.error("Failed to stop the charging session:", error);
  //     }
  //   })();
  // };

  console.log("action Type", actionType);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleOpenCloseChargerModal = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openModalCloseCharger = Boolean(anchorEl);
  const id = openModalCloseCharger ? "simple-popover" : undefined;
  // const [open, setOpen] = React.useState(false);

  const [openRows, setOpenRows] = React.useState<{ [key: string]: boolean }>(
    {}
  );

  const handleCharger = (id: string) => {
    setOpenRows((prevOpenRows: { [key: string]: boolean }) => ({
      ...prevOpenRows,
      [id]: !prevOpenRows[id],
    }));
  };

  function Row(props: {
    [x: string]: any;
    row: ReturnType<typeof createData>;
  }) {
    const { row } = props;
    const isOpen = openRows[row.id];
    console.log("RowID", props?.row?.id);

    const [actionOpen, setActionOpen] = React.useState(false);
    const [showAddEditLocationModal, setShowAddEditLocationModal] =
      useState(false);
    const [showAddEditChargerModal, setShowAddEditChargerModal] =
      useState(false);
    const [showRebootChargerModal, setShowRebootChargerModal] = useState(false);
    const [showStopChargerModal, setShowStopChargerModal] = useState(false);
    const [editLocationData, setEditLocationData] = useState({});
    const [editChargerData, setEditChargerData] = useState({});
    const [rebootChargerData, setRebootChargerData] = useState({});
    const [stopChargerData, setStopChargerData] = useState({});

    const handleEditCharger = (item: any) => {
      setEditChargerData(item);
      setShowAddEditChargerModal(true);
    };

    const handleRebootCloseChargerModal = (item: any, id: any, serial: any) => {
      // setOpen(true);
      setActionType("Reboot");
      setRebootChargerData([item, id, serial]);
      setShowRebootChargerModal(true);
    };
    const handleStopCloseChargerModal = (
      csId: any,
    name: any,
    transactionId: any,
    userId: any,
    cost: any,
    energy: any,
    time: any,
    range: any
    ) => {
      // setOpen(true);
      setActionType("Reboot");
      setStopChargerData([csId,name,transactionId,userId,cost,energy,time,range]);
      setShowStopChargerModal(true);
    };

    console.log("Selected Modal Type", actionType);

  //  Code For checking location has charger or not if not then show message in UI No charger existing
    const checkLocationHasAnyCharger = allChargePointData.filter(
      (item: { isEnabled: string; location: any }) =>
        item.location === row.id && item?.isEnabled == "true"
    );

    return (
      <React.Fragment>
        <TableRow
          sx={{ "& > *": { borderBottom: "unset", background: "#F6F8FA" } }}
        >
          <TableCell
            style={{ fontFamily: "lexend" }}
            component="th"
            scope="row"
          >
            {row.id}
          </TableCell>
          <TableCell style={{ fontFamily: "lexend" }}>
            {row.stationName}
          </TableCell>
          <TableCell style={{ fontFamily: "lexend" }}>{row.type}</TableCell>
          <TableCell style={{ fontFamily: "lexend" }}>{row.city}</TableCell>
          {/* <TableCell  style={{fontFamily:'lexend'}}>{row.access}</TableCell> */}
          <TableCell style={{ fontFamily: "lexend" }}>
            <div className="flex">
              <span>
                {row.access == "Restricted" && (
                  <div className="flex">
                    <span>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.00011 8C7.80119 8 7.61043 7.92098 7.46978 7.78033C7.32912 7.63968 7.25011 7.44891 7.25011 7.25C7.25011 7.05109 7.32912 6.86032 7.46978 6.71967C7.61043 6.57902 7.80119 6.5 8.00011 6.5C8.19902 6.5 8.38978 6.57902 8.53044 6.71967C8.67109 6.86032 8.75011 7.05109 8.75011 7.25C8.75011 7.44891 8.67109 7.63968 8.53044 7.78033C8.38978 7.92098 8.19902 8 8.00011 8ZM8.00011 8V9.875M8.00012 1.25C9.75199 2.79993 12.0385 3.60692 14.3751 3.5C14.7153 4.65726 14.8194 5.8711 14.6812 7.06939C14.543 8.26767 14.1654 9.42595 13.5707 10.4754C12.976 11.5248 12.1764 12.444 11.2195 13.1784C10.2625 13.9127 9.16771 14.4472 8.00012 14.75C6.83253 14.4472 5.73773 13.9127 4.78078 13.1784C3.82383 12.444 3.02425 11.5248 2.42957 10.4754C1.83488 9.42595 1.45722 8.26767 1.31903 7.06939C1.18083 5.8711 1.28493 4.65726 1.62512 3.5C3.96177 3.60692 6.24825 2.79993 8.00012 1.25Z"
                          stroke="#C41A1A"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    <span className="ml-2" style={{ color: "#C41A1A" }}>
                      Restricted
                    </span>
                  </div>
                )}
              </span>
              {row.access == "Public" && (
                <div className="flex">
                  <span>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.00012 15.75C7.83253 15.4472 6.73773 14.9127 5.78078 14.1784C4.82383 13.444 4.02425 12.5248 3.42957 11.4754C2.83488 10.426 2.45722 9.26767 2.31903 8.06939C2.18083 6.8711 2.28493 5.65726 2.62512 4.5C4.96177 4.60692 7.24825 3.79993 9.00012 2.25C10.752 3.79993 13.0385 4.60692 15.3751 4.5C15.7324 5.71613 15.829 6.99398 15.6586 8.25M13.5001 16.5L16.0126 14.037C16.1667 13.8875 16.2892 13.7086 16.373 13.511C16.4569 13.3133 16.5002 13.1008 16.5006 12.8862C16.5009 12.6715 16.4582 12.4589 16.3751 12.2609C16.2919 12.063 16.1699 11.8838 16.0164 11.7337C15.703 11.4272 15.2823 11.2553 14.8439 11.2544C14.4055 11.2536 13.9842 11.4239 13.6696 11.7292L13.5016 11.8942L13.3344 11.7292C13.021 11.423 12.6004 11.2511 12.1623 11.2503C11.7241 11.2494 11.3029 11.4197 10.9884 11.7247C10.8342 11.8742 10.7116 12.053 10.6277 12.2507C10.5439 12.4483 10.5004 12.6607 10.5 12.8754C10.4996 13.0901 10.5422 13.3027 10.6253 13.5007C10.7084 13.6986 10.8303 13.8779 10.9839 14.028L13.5001 16.5Z"
                        stroke="#19885A"
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span className="ml-2" style={{ color: "#19885A" }}>
                    Public
                  </span>
                </div>
              )}
            </div>
          </TableCell>

          <TableCell>
            <Tooltip title="Edit Location">
              <button
                style={{
                  background: "#1AC47D",
                  width: "35px",
                  height: "35px",
                  padding: "5px",
                  borderRadius: "50%",
                  border: "3px solid #1AAD70",
                }}
                onClick={() => {
                  setShowAddEditLocationModal(true);
                  setEditLocationData(row);
                }}
              >
                <svg
                  className="ml-1"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.33268 2.66635L9.33268 6.66635M0.666016 8.66635L8.46602 0.866353C8.59063 0.744201 8.75818 0.675781 8.93268 0.675781C9.10718 0.675781 9.27473 0.744201 9.39935 0.866353L11.1327 2.59969C11.2548 2.72431 11.3233 2.89185 11.3233 3.06635C11.3233 3.24085 11.2548 3.4084 11.1327 3.53302L3.33268 11.333H0.666016V8.66635Z"
                    stroke="white"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </Tooltip>
          </TableCell>
          <TableCell>
            <Tooltip title="Show Charger">
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => handleCharger(row.id)}
              >
                {openRows[row.id] ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>

        {checkLocationHasAnyCharger.length === 0 ? (
          <TableCell
            size="small"
            style={{
              // display: checkLocationHasAnyCharger == false ? "none" : undefined,
              paddingBottom: 0,
              paddingTop: 0,
            }}
            colSpan={12}
          >
            {/* <Collapse   in={open} timeout="auto" unmountOnExit> */}
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <h1 className="text-center font-lexend text-2xl bg-gray-200 font-bold py-3">
                No Active Charger Available At This Location
              </h1>
            </Collapse>
          </TableCell>
        ) : (
          <TableCell
            size="small"
            style={{
              paddingBottom: 0,
              paddingTop: 0,
            }}
            colSpan={12}
          >
            <Collapse in={openRows[row.id]} timeout="auto" unmountOnExit>
              <Table aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontFamily: "lexend",
                        fontWeight: "600",
                        fontSize: "15px",
                      }}
                    >
                      Serial Number
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: "lexend",
                        fontWeight: "600",
                        fontSize: "15px",
                      }}
                    >
                      Max Power
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: "lexend",
                        fontWeight: "600",
                        fontSize: "15px",
                      }}
                    >
                      Status
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: "lexend",
                        fontWeight: "600",
                        fontSize: "15px",
                      }}
                    >
                      Duration
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: "lexend",
                        fontWeight: "600",
                        fontSize: "15px",
                      }}
                    >
                      Connected
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: "lexend",
                        fontWeight: "600",
                        fontSize: "15px",
                      }}
                    >
                      Cost
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: "lexend",
                        fontWeight: "600",
                        fontSize: "15px",
                        width: "25px",
                      }}
                    >
                      Energy Consumed
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: "lexend",
                        fontWeight: "600",
                        fontSize: "15px",
                      }}
                    >
                      Operator
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: "lexend",
                        fontWeight: "600",
                        fontSize: "15px",
                      }}
                    >
                      User
                    </TableCell>

                    {actionOpen ? (
                      <TableCell
                        style={{
                          fontFamily: "lexend",
                          fontWeight: "600",
                          fontSize: "15px",
                        }}
                        align="left"
                      >
                        Action
                      </TableCell>
                    ) : (
                      <TableCell
                        style={{
                          fontFamily: "lexend",
                          fontWeight: "600",
                          fontSize: "15px",
                        }}
                        align="center"
                      >
                        Action
                      </TableCell>
                    )}

                    <TableCell
                      style={{
                        fontFamily: "lexend",
                        fontWeight: "600",
                        fontSize: "15px",
                      }}
                      align="center"
                    ></TableCell>
                  </TableRow>
                </TableHead>

                {/* .filter(
                      (item: {
                        isEnabled: string; location: any 
}) => item.location === row.id && item?.isEnabled == "true"
                    ) */}

                {allChargePointData
                  .filter(
                    (item: { isEnabled: string; location: any }) =>
                      item.location === row.id && item?.isEnabled == "true"
                  )
                  .map((item: any) => {
                    return (
                      <TableBody key={item.id}>
                        <TableCell
                          style={{ fontFamily: "lexend", fontSize: "15px" }}
                        >
                          {/* Serial Number */}
                          {item.serial}
                        </TableCell>
                        <TableCell
                          style={{ fontFamily: "lexend", fontSize: "15px" }}
                        >
                          {item.power}
                        </TableCell>
                        <TableCell
                          style={{ fontFamily: "lexend", fontSize: "15px" }}
                        >
                          {item.status === "Available" && (
                            <span className="flex items-center">
                              {" "}
                              <span>
                                <svg
                                  className="mr-2"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M1 13L3.625 10.375M9.25 1L6.625 3.625M13 4.75L10.375 7.375M5.33877 2.5L11.5 8.66125L9.95952 10.2017C9.55791 10.6194 9.07704 10.9528 8.54504 11.1824C8.01304 11.412 7.44059 11.5332 6.86119 11.5389C6.28179 11.5445 5.70708 11.4346 5.17067 11.2155C4.63427 10.9964 4.14695 10.6725 3.73724 10.2628C3.32752 9.85306 3.00363 9.36575 2.78452 8.82934C2.56541 8.29294 2.45548 7.71822 2.46116 7.13882C2.46685 6.55942 2.58803 5.98698 2.81762 5.45497C3.0472 4.92297 3.38059 4.4421 3.79827 4.0405L5.33877 2.5Z"
                                    stroke="#529fec"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </span>{" "}
                              <span style={{ color: "#529fec" }}>Online</span>{" "}
                            </span>
                          )}

                          {item.status === "Charging" && (
                            <>
                              <span className="flex items-center">
                                {" "}
                                <span>
                                  <svg
                                    className="mr-2"
                                    width="15"
                                    height="14"
                                    viewBox="0 0 15 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1.78906 2.75C1.78906 2.35218 1.9471 1.97064 2.2284 1.68934C2.50971 1.40804 2.89124 1.25 3.28906 1.25H11.5391C11.9369 1.25 12.3184 1.40804 12.5997 1.68934C12.881 1.97064 13.0391 2.35218 13.0391 2.75V3.125C13.0391 3.22446 13.0786 3.31984 13.1489 3.39017C13.2192 3.46049 13.3146 3.5 13.4141 3.5C13.5135 3.5 13.6089 3.53951 13.6792 3.60983C13.7496 3.68016 13.7891 3.77554 13.7891 3.875V6.125C13.7891 6.22446 13.7496 6.31984 13.6792 6.39016C13.6089 6.46049 13.5135 6.5 13.4141 6.5C13.3146 6.5 13.2192 6.53951 13.1489 6.60983C13.0786 6.68016 13.0391 6.77554 13.0391 6.875V7.25C13.0391 7.64782 12.881 8.02935 12.5997 8.31066C12.3184 8.59196 11.9369 8.75 11.5391 8.75H8.16406M3.28906 12.5V10.25M1.78906 7.25V5.375M4.78906 7.25V5.375M1.03906 7.25H5.53906V8.75C5.53906 9.14782 5.38103 9.52935 5.09972 9.81066C4.81842 10.092 4.43689 10.25 4.03906 10.25H2.53906C2.14124 10.25 1.75971 10.092 1.4784 9.81066C1.1971 9.52935 1.03906 9.14782 1.03906 8.75V7.25Z"
                                      stroke="#1AC47D"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </span>{" "}
                                <span style={{ color: "#1AC47D" }}>
                                  Charging
                                </span>{" "}
                              </span>
                            </>
                          )}

                          {item.status === "Preparing" && (
                            // <span className="label-connected">Connected</span>
                            <span className="flex items-center">
                              {" "}
                              <span>
                                <svg
                                  className="mr-2"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M1 13L3.625 10.375M9.25 1L6.625 3.625M13 4.75L10.375 7.375M5.33877 2.5L11.5 8.66125L9.95952 10.2017C9.55791 10.6194 9.07704 10.9528 8.54504 11.1824C8.01304 11.412 7.44059 11.5332 6.86119 11.5389C6.28179 11.5445 5.70708 11.4346 5.17067 11.2155C4.63427 10.9964 4.14695 10.6725 3.73724 10.2628C3.32752 9.85306 3.00363 9.36575 2.78452 8.82934C2.56541 8.29294 2.45548 7.71822 2.46116 7.13882C2.46685 6.55942 2.58803 5.98698 2.81762 5.45497C3.0472 4.92297 3.38059 4.4421 3.79827 4.0405L5.33877 2.5Z"
                                    stroke="#52ec6c"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </span>{" "}
                              <span style={{ color: "#52ec6c" }}>
                                Connected
                              </span>{" "}
                            </span>
                          )}
                          {item.status === "Finishing" && (
                            <span style={{ color: "#fd9610" }}>Finishing</span>
                          )}
                          {item.status === "Offline" && (
                            <span className="flex items-center">
                              {" "}
                              <span>
                                <svg
                                  className="mr-2"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M10.1625 13.2997C9.6009 13.4765 9.0093 13.5376 8.42344 13.4793C7.83759 13.4209 7.26964 13.2444 6.75395 12.9603C6.23826 12.6763 5.78553 12.2906 5.42313 11.8266C5.06072 11.3626 4.79618 10.8299 4.64549 10.2607C4.44852 9.51812 4.45141 8.73662 4.65385 7.99547C4.8563 7.25432 5.2511 6.57988 5.79824 6.0405L7.33874 4.5L12.7125 9.87375M3 15L5.625 12.375M11.25 3L8.625 5.625M15 6.75L12.375 9.375M12 12L15 15M15 12L12 15"
                                    stroke="#C99456"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </span>{" "}
                              <span style={{ color: "#C99456" }}>Offline</span>{" "}
                            </span>
                          )}
                          {item.status === "Faulted" && (
                            <span className="flex items-center">
                              <span>
                                <svg
                                  className="mr-2"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M10.55 14.733C9.8012 14.9687 9.0124 15.0501 8.23126 14.9724C7.45011 14.8946 6.69286 14.6592 6.00527 14.2804C5.31768 13.9017 4.71404 13.3874 4.23083 12.7688C3.74763 12.1501 3.39491 11.4399 3.19398 10.681C2.93136 9.69083 2.93521 8.64883 3.20514 7.66063C3.47506 6.67243 4.00146 5.77317 4.73098 5.054L6.78498 3L13.95 10.165M1 17L4.5 13.5M12 1L8.5 4.5M17 6L13.5 9.5M13 13L17 17M17 13L13 17"
                                    stroke="#981818"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </span>
                              <span className="text-red-900">Faulted</span>
                            </span>
                          )}
                          {item.status === "SuspendedEV" && (
                            <span className="text-red-900">Suspended EV</span>
                          )}
                        </TableCell>
                        <TableCell
                          style={{ fontFamily: "lexend", fontSize: "15px" }}
                        >
                          {item.duration}
                        </TableCell>

                        <TableCell
                          style={{ fontFamily: "lexend", fontSize: "15px" }}
                        >
                          {item.status
                            ? item.status === "Charging" ||
                              item.status === "Preparing"
                              ? "Yes"
                              : "No"
                            : "-"}
                        </TableCell>

                        <TableCell
                          style={{ fontFamily: "lexend", fontSize: "15px" }}
                        >
                          {/* {item?.meterStart === "0" && (item?.cost * item?.energyConsumed).toFixed(2)}

                            {
                              item?.meterStart != "0" && ((item?.energyConsumed - item?.meterStart / 1000) * item?.cost).toFixed(2)
                            } */}

                          {item?.status === "Charging"
                            ? item?.meterStart === "0"
                              ? (item?.cost * item?.energyConsumed).toFixed(2)
                              : (
                                  (item?.energyConsumed -
                                    item?.meterStart / 1000) *
                                  item?.cost
                                ).toFixed(2)
                            : "-"}

                          {/* // {item.cost * item.energyConsumed */}
                          {/* //   ? item.cost * item.energyConsumed */}
                          {/* //   : "-"} */}
                        </TableCell>
                        <TableCell
                          style={{ fontFamily: "lexend", fontSize: "15px" }}
                        >
                          {/* {item?.meterStart == "0" && item.energyConsumed}
                            {
                              item?.meterStart != "0" && (item?.energyConsumed - item?.meterStart / 1000).toFixed(2)
                            } */}
                          {item?.status === "Charging"
                            ? item?.meterStart === "0"
                              ? item?.energyConsumed
                              : (
                                  item?.energyConsumed -
                                  item?.meterStart / 1000
                                ).toFixed(2)
                            : "-"}
                        </TableCell>
                        <TableCell
                          style={{ fontFamily: "lexend", fontSize: "15px" }}
                        >
                          {item.cpo.name}
                        </TableCell>
                        <TableCell
                          style={{ fontFamily: "lexend", fontSize: "15px" }}
                        >
                          {item.userName}
                        </TableCell>
                        {item.vendor !== "EO" ? (
                          <TableCell align="center">
                            <span className="flex">
                              <Tooltip title="Close Charger">
                                <button
                                  // disabled={item.status !== "Charging"}
                                  style={{
                                    // display: item.status !== "Charging" ? "none" : "block",
                                    background:
                                      item.status !== "Charging"
                                        ? "#c8c2c2"
                                        : "#C41A1A",
                                    width: "35px",
                                    height: "35px",
                                    marginRight: "5px",
                                    borderRadius: "50%",
                                    border:
                                      item.status !== "Charging"
                                        ? "3px solid #858585 "
                                        : "3px solid #9E1212 ",
                                  }}
                                  onClick={() =>
                                    // handleStopOcppCharging(
                                    //   item.chargeSessionId,
                                    //   item.ocppName,
                                    //   item.ocppTransactionId,
                                    //   item.ocppUserId,
                                    //   Math.round(item.ocppCost),
                                    //   item.energyConsumed,
                                    //   20,
                                    //   10
                                    // )
                                    handleStopCloseChargerModal(
                                      item.chargeSessionId,
                                      item.ocppName,
                                      item.ocppTransactionId,
                                      item.ocppUserId,
                                      Math.round(item.ocppCost),
                                      item.energyConsumed,
                                      20,
                                      10
                                    )
                                  }

                                  // onClick={() => handleStopChargerModal(item)}
                                >
                                  <svg
                                    className="ml-2"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M10.5 3.5L3.5 10.5M3.5 3.5L10.5 10.5"
                                      stroke="white"
                                      stroke-width="1.25"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </button>
                              </Tooltip>
                              <Tooltip title="Reboot Charger">
                                <button
                                  style={{
                                    background: "#1A72C4",
                                    width: "35px",
                                    height: "35px",
                                    borderRadius: "50%",
                                    border: "3px solid  #155EA1",
                                  }}
                                  onClick={() =>
                                    handleRebootCloseChargerModal(
                                      item.ocppName,
                                      item?.id,
                                      item?.serial
                                    )
                                  }
                                >
                                  <svg
                                    className="ml-2"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M11.6375 6.4166C11.49 5.2912 10.9374 4.25814 10.0831 3.5108C9.22885 2.76346 8.1315 2.35307 6.99647 2.35647C5.86145 2.35986 4.76657 2.7768 3.91679 3.52924C3.06701 4.28167 2.52058 5.31802 2.3798 6.44428C2.23901 7.57055 2.51353 8.70951 3.15195 9.64798C3.79037 10.5864 4.74893 11.2601 5.84819 11.5427C6.94745 11.8254 8.11206 11.6978 9.124 11.1837C10.1359 10.6696 10.9258 9.8044 11.3458 8.74994M11.6375 11.6666V8.74994H8.72085"
                                      stroke="white"
                                      stroke-width="1.25"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </button>
                              </Tooltip>
                            </span>
                          </TableCell>
                        ) : (
                          <TableCell align="center">-</TableCell>
                        )}

                        <TableCell>
                          <Tooltip title="Edit Charger">
                            <button
                              style={{
                                background: "#1AC47D",
                                width: "35px",
                                height: "35px",
                                padding: "5px",
                                borderRadius: "50%",
                                border: "3px solid #1AAD70",
                              }}
                              onClick={() => handleEditCharger(item)}
                            >
                              <svg
                                className="ml-1"
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.33268 2.66635L9.33268 6.66635M0.666016 8.66635L8.46602 0.866353C8.59063 0.744201 8.75818 0.675781 8.93268 0.675781C9.10718 0.675781 9.27473 0.744201 9.39935 0.866353L11.1327 2.59969C11.2548 2.72431 11.3233 2.89185 11.3233 3.06635C11.3233 3.24085 11.2548 3.4084 11.1327 3.53302L3.33268 11.333H0.666016V8.66635Z"
                                  stroke="white"
                                  stroke-width="1.33333"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </button>
                          </Tooltip>
                        </TableCell>

                        <AddEditChargerModal
                          show={showAddEditChargerModal}
                          handleClose={() => setShowAddEditChargerModal(false)}
                          
                          data={editChargerData}
                          type="Edit"
                        />

                        {/* Close Charger Modal */}
                        <CloseChargerModal
                          show={showRebootChargerModal}
                          handleClose={() => setShowRebootChargerModal(false)}
                          data={rebootChargerData}
                          type={actionType}
                        />
                        
                        <StopChargerModal
                          show={showStopChargerModal}
                          handleClose={() => setShowStopChargerModal(false)}
                          data={stopChargerData}
                          type={actionType}
                        />
                      </TableBody>
                    );
                  })}
              </Table>
            </Collapse>
          </TableCell>
        )}
        <AddEditModal
          show={showAddEditLocationModal}
          handleClose={() => setShowAddEditLocationModal(false)}
          type="Edit"
          data={editLocationData}
          refreshLocation={() => console.log("Hey")}
          allChargePointData={allChargePointData}
        />
      </React.Fragment>
    );
  }

  const [collapseID, setCollapseID] = useState("");
  // const handleCharger = (id:any) => {
  //   console.log("clicked ",id);
  //   setCollapseID(id)
  //   // alert("trigger open drop")
  //   setOpen((prev)=> !prev);

  //   // setIsFilterVisible((prev: boolean) => !prev);
  // };

  console.log("allChargePointData", allChargePointData);
  console.log("row props", props);
  return (
    <div className="databaseTable">
      {/* <div className="title">All Locations ({dataRow.length})</div> */}
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead className="tHead">
            <TableRow>
              <TableCell className="tCell">Id</TableCell>
              <TableCell className="tCell">Name</TableCell>
              <TableCell className="tCell">Type</TableCell>
              <TableCell className="tCell">City</TableCell>

              <TableCell className="tCell">Access</TableCell>

              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {props.loading ? (
              <Box
                width="100%"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress color="success" />
              </Box>
            ) : (
              dataRow.length &&
              dataRow?.map((row: any) => {
                return <Row key={row.name} row={row} />;
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
});

export default DatabaseTable;
