import React from 'react'

const index = () => {
  return (
    <div className='m-5'>
        <h1 className='font-lexend text-2xl font-semibold'>
        Load management
        </h1>
        </div>
  )
}

export default index