import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  useMediaQuery,
  useTheme,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Stack,
  } from "@mui/material";
import "./addEdit.scss";
import CloseIcon from "@mui/icons-material/Close";
interface PropTypes {
  show: boolean;
  handleClose: any;
  type: string;
  data: any;
  refresh: any;
}

function AddEditModal(props: PropTypes) {
  const initialValues = {
    id: "",
    name: "",
    image: "",
    enabled: "",
  };

  const [values, setValues] = useState(initialValues);
  useEffect(() => {
    if (!props.show) return;
    setValues(props.data);
  }, [props.show]);
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const submitAddManufacturer = () => {
    try {
      if(values.name){
        let myNewObject: Parse.Object = new Parse.Object("EV_Manufacturer");

    myNewObject.set("Name", values.name);

    myNewObject.set("isEnabled", values.enabled === "true" ? true : false);

    myNewObject.save().then((res) => {
      alert("Manufacturer added successfully");
      props.refresh();
      props.handleClose();
    });
      }else{
        alert("Please fill required")
      }
    } catch (error) {
      console.log("error",error);
      
    }
    
  };

  const submitEditManufacturer = () => {
    let myNewObject: Parse.Object = new Parse.Object("EV_Manufacturer");
    myNewObject.set("objectId", values.id);
    myNewObject.set("Name", values.name);

    myNewObject.set("isEnabled", values.enabled === "true" ? true : false);
    myNewObject.save().then((res) => {
      alert("Manufaturer Edited successfully");
      props.refresh();
      props.handleClose();
    });
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          width: 600,
          height: 230,
        },
      }}
      maxWidth={"md"}
      fullScreen={fullScreen}
      open={props.show}
      onClose={props.handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <div className="headItem">
          <span className="font-lexend">
            {" "}
            {props.type == "add" ? "Add Manufacturer" : "Edit Manufacturer"}
          </span>{" "}
          <span className="head2" onClick={props.handleClose}>
            <CloseIcon />
          </span>
        </div>
      </DialogTitle>

      <DialogContent>
        <Stack direction="column" spacing={2}>
          <label className="flex items-center mb-2" htmlFor="station name">
            <span>
              <svg
                className="mr-2"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.75 13.5C3.75 13.8978 3.90804 14.2794 4.18934 14.5607C4.47064 14.842 4.85218 15 5.25 15H14.25V3H5.25C4.85218 3 4.47064 3.15804 4.18934 3.43934C3.90804 3.72064 3.75 4.10218 3.75 4.5V13.5ZM3.75 13.5C3.75 13.1022 3.90804 12.7206 4.18934 12.4393C4.47064 12.158 4.85218 12 5.25 12H14.25M6.75 6H11.25"
                  stroke="#354052"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>{" "}
            <span className="font-lexend">Name<span className="text-red-500 text-l ml-1">*</span></span>
          </label>

          <TextField
            id="input-with-sx"
            variant="standard"
            placeholder="Enter name of the manufacturer"
            value={values.name}
            onChange={(e) =>
              setValues({
                ...values,
                name: e.target.value,
              })
            }
          />
          <div className="flex justify-between items-center">
            <div className="flex items-center ">
              <input
                type="checkbox"
                style={{ width: "20px", height: "20px" }}
                // checked={values.enabled === "true"}
                checked={values.enabled === "true" || true}
                onChange={() =>
                  setValues({
                    ...values,
                    enabled: values.enabled === "true" ? "false" : "true",
                  })
                }
              />
              <label className="font-lexend ml-2" htmlFor="">
                Enable
              </label>
            </div>

            <div className="flex">
              <button
                className="flex justify-around items-center px-3 py-2 rounded-full mr-3"
                style={{ background: "#1AC47D", border: "2px solid #1AAD70" }}
                onClick={() => {
                  props.type === "add"
                    ? submitAddManufacturer()
                    : submitEditManufacturer();
                }}
              >
                <span className="mr-2">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.75 9L7.5 12.75L15 5.25"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <span className="font-lexend" style={{ color: "white" }}>
                  Submit
                </span>
              </button>
            </div>
          </div>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default AddEditModal;
