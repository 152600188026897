import { Autocomplete, TextField, Typography } from "@mui/material";
import "./chargeSession.scss";
import DatabaseTable from "./databasetable";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Widget from "./widget";
import DatePicker from "react-datepicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Checkbox, Chip } from "@mui/material";
import moment from "moment";
import Popover from "@mui/material/Popover";
import { memo, useEffect, useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import "react-datepicker/dist/react-datepicker.css";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { CSVLink } from "react-csv";
import { FilterIcon, CloseFilterIcon } from "../../icons/svg_icons/icons_svg";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface optionObj {
  id: string;
  label: string;
}

interface UserFilter {
  id: string;
  label: string;
}
interface LocationFilter {
  id: string;
  label: string;
}
const ChargeSession = memo(() => {
  const [tableData, setTableData] = useState<any>([]);
  // Widget Data
  const [usage, setUsage] = useState({ thisMonth: 0, lastMonth: 0, total: 0 });
  const [billedRevenue, setBilledRevenue] = useState({
    thisMonth: 0,
    lastMonth: 0,
    total: 0,
  });
  const [chargeSession, setChargeSession] = useState({
    thisMonth: 0,
    lastMonth: 0,
    total: 0,
  });
  const [chargeDuration, setChargeDuration] = useState({
    thisMonth: 0,
    lastMonth: 0,
    total: null as string | null, // Ensure type accommodates your expected values
  });
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [dateChips, setDateFilterChips] = useState<any[]>(["This Week"]);
  const [statusChips, setStatusFilterChips] = useState<any[]>([]);
  const [dateRangeChipVisible, setDateRangeChipVisible] =
    useState<boolean>(false);
  const [SingleDateChips, setSingleDateFilterChips] = useState<string | null>(
    null
  );

  const [startDateFilter, setStartDateFilter] = useState<any>("");
  const [endDateFilter, setEndDateFilter] = useState<any>("");
  const [openCustom, setOpenCustom] = useState<boolean>(false);
  const [userFilter, setUserFilter] = useState<UserFilter[]>([]);
  const [locationFilter, setLocationFilter] = useState<LocationFilter[]>([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [dateFilter, setDateFilter] = useState("This Week");
  const [userFind, setUserFind] = useState("");
  const [chargerSerialFilter, setChargerSerialFilter] = useState<any>("");
  const [usersFilter, setUserFiltersIds] = useState<any[]>([]);
  const [locationIds, setLocationIds] = useState<any[]>([]);
  const [filterChips, setFilterChips] = useState<
    { id: string; label: string }[]
  >([]);
  const [locationChips, setLocationChips] = useState<
    { id: string; label: string }[]
  >([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [csvState, setCsvState] = useState<any>([]);
  // Code to Filter show and hide.
  const handleFilterButtonClick = () => {
    // Toggle filter visibility
    setIsFilterVisible((prev) => !prev);
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Date Filter code
  const dateRangeChange = (dates: any) => {
    const [start, end] = dates;
    setStartDateFilter(start);
    setEndDateFilter(end);
    setDateRangeChipVisible(!!start && !!end);
    setSingleDateFilterChips(start);
  };

  const handleCustomChange = () => {
    setOpenCustom(!openCustom);
    setDateFilterChips([{ id: "", label: "" }]);
    setDateFilter("");
  };
  const [isSelected, setIsSelected] = useState(false);
  const handleDateChange = (value: string) => {
    if (value === "Custom") {
      setIsSelected(!isSelected);
      setOpenCustom((prevOpenCustom) => !prevOpenCustom);
      setDateFilterChips([]);
      setDateFilter("");
      // Clear custom date range when selecting "Custom"
      setStartDateFilter(null);
      setEndDateFilter(null);
      setDateRangeChipVisible(false);
    } else {
      setOpenCustom(false);
      // Update the dateFilter state
      setDateFilter(value);
      // Update the dateFilterChips array based on the selected value
      const selectedChip = {
        id: value,
        label:
          value === "Today"
            ? "Today"
            : value === "This Month"
            ? "This Month"
            : "This Year",
      };
      setDateFilterChips([selectedChip]);
      // Clear custom date range when selecting predefined filters
      setStartDateFilter(null);
      setEndDateFilter(null);
      setDateRangeChipVisible(false);
      setIsSelected(false);
    }
  };

  const handleStatusChange = (value: string) => {
    const existingChip = statusChips.find((chip) => chip.label === value);
    if (!existingChip) {
      const updatedChips = statusChips.filter(
        (chip) => chip.id !== statusFilter
      );

      setStatusFilterChips([...updatedChips, { id: value, label: value }]);
    }
    setStatusFilter((prevValue) => (prevValue === value ? "" : value));
  };

  // Delete Chip User Filter
  const handleDeleteChip = (chipId: string) => {
    setFilterChips((prevChips) =>
      prevChips.filter((chip) => chip.id !== chipId)
    );

    // Remove the user filter
    setUserFiltersIds((prevUserIds) =>
      prevUserIds.filter((id) => id !== chipId)
    );

    setUserFilter((prevUserFilter: any) =>
      prevUserFilter.filter((filter: any) => filter.id !== chipId)
    );
  };

  // Delete Chip Location Filter
  const handleLocationDeleteChip = (chipId: string) => {
    // Remove the corresponding chip from the state
    setLocationChips((prevChips) =>
      prevChips.filter((chip) => chip.id !== chipId)
    );

    // Remove the location filter
    setLocationIds((prevUserIds) => prevUserIds.filter((id) => id !== chipId));

    setLocationFilter((prevUserFilter: any) =>
      prevUserFilter.filter((filter: any) => filter.id !== chipId)
    );
  };

  // Chip delete Status
  const handleDeleteStatusChip = (chipId: string) => {
    // Remove the corresponding chip from the state
    setStatusFilterChips((prevChips) =>
      prevChips.filter((chip) => chip.id !== chipId)
    );
    setStatusFilter("");
  };
  const handleDeleteDateChip = (chipId: string) => {
    const updatedChips = dateChips.filter((chip) => chip.id !== chipId);
    setDateFilterChips(updatedChips);
    setDateFilter("");
  };

  const handleSingleDeleteDateChip = (chipId: string) => {
    setStartDateFilter(null);
    setEndDateFilter(null);
    setSingleDateFilterChips(null);
  };

  const handleDateRangeDelete = () => {
    setStartDateFilter(null);
    setEndDateFilter(null);
    setDateRangeChipVisible(false);
  };

  const currentUser: any = Parse.User.current();

  useEffect(() => {
    if (Array.isArray(userFilter)) {
      const usersFilter = userFilter.map((user: any) => user.id);

      setUserFiltersIds(usersFilter);

      if (usersFilter.length > 0) {
        // Your logic when userFilter is an array
      }
    } else if (userFilter && typeof userFilter === "object") {
      const userId = (userFilter as { id?: string }).id;

      setUserFiltersIds(userId ? [userId] : []);

      // Your logic when userFilter is an object
    } else {
      // Handle other cases when userFilter is not an array or object
    }

    // code for location
    if (Array.isArray(locationFilter)) {
      const locationIds = locationFilter.map((user: any) => user.id);

      setLocationIds(locationIds);

      if (locationIds.length > 0) {
        // Your logic when userFilter is an array
      }
    } else if (locationFilter && typeof locationFilter === "object") {
      const locationId = (locationFilter as { id?: string }).id;

      setLocationIds(locationId ? [locationId] : []);

      // Your logic when userFilter is an object
    } else {
      // Handle other cases when userFilter is not an array or object
    }
  }, [userFilter, locationFilter, dateFilter, startDateFilter, endDateFilter]);

  // Filters Data
  const [allLocations, setAllLocations] = useState<any | null>([]);
  const [allUsers, setAllUsers] = useState<any | null>([]);
  let userDetail: any = sessionStorage.getItem("user-details");

  const getAllLocations = () => {
    const chargersQuery = new Parse.Query("Chargers");
    if (currentUser && !currentUser.get("isSuperAdmin")) {
      chargersQuery.equalTo("CPO", currentUser.get("CPO"));
    }

    chargersQuery.find().then((chargeResult) => {
      let locArray: any = [];
      chargeResult.map((chargePoint) => {
        if (!locArray.includes(chargePoint.get("Location").id)) {
          locArray.push(chargePoint?.get("Location").id);
        }
      });

      const locationQuery = new Parse.Query("Locations");
      !JSON.parse(userDetail).isSuperAdmin &&
        locationQuery.containedIn("objectId", locArray);
      locationQuery.find().then((result) => {
        let addressArray: any[] = [];

        result.forEach((item) => {
          let locPoint = item.get("GeoLocation");
          addressArray.push({
            id: item.id,
            label: item.get("Name"),
          });
          setAllLocations(addressArray);
        });
      });
    });
  };

  const getOcppData = async (id: any, item: any, start: any) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_OCPP_BASE_URL}/meter_value/${id}`
      );
      const res = await response.json();

      // Update ocppData object
      let ocppData = {
        energy: (res.energy_active_import_register / 1000).toFixed(2),
        time: res.timestamp,
      };

      let cost = item;
      // Check if the item already exists in the table based on the 'id'
      setTableData((oldArray: any) => {
        // Check if the item already exists in the table based on the 'id'
        const isItemExist = oldArray.some(
          (tableItem: any) => tableItem.id === item.id
        );

        if (!isItemExist) {
          return [
            ...oldArray,
            {
              ...item,
              ocppEnergy: (res.energy_active_import_register / 1000).toFixed(2),
              soc: res?.soc,
              ocppDuration:
                moment.duration(moment(res.timestamp).diff(start)).hours() +
                "hr" +
                " " +
                moment.duration(moment(res.timestamp).diff(start)).minutes() +
                "min",
              ocppCost: (
                item.tariff *
                (res.energy_active_import_register / 1000)
              ).toFixed(2),
            },
          ];
        } else {
          // Handle the case where the item already exists (optional)

          return oldArray; // Return the unchanged array
        }
      });
    } catch (error) {
      console.error("Error fetching OCPP data:", error);
    }
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage] = useState(100); // You can set the items per page here or pass it as a prop

  const handlePreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleNextPage = () => {
    setPageNumber(pageNumber + 1);
  };

  const [totalChargeSessions, setTotalChargeSessions] = useState(0);

  const secToMinute = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours} hrs, ${minutes} minutes`;
  };

  const getWidgetsData = async () => {
    const ChargeSession = Parse.Object.extend("ChargeSession");
    const parseQuery = new Parse.Query(ChargeSession);

    parseQuery.include("ChargePoint");
    parseQuery.include("Location");
    parseQuery.include("Vehicle");
    parseQuery.include("User");
    parseQuery.descending("createdAt");
    if (usersFilter.length > 0) {
      parseQuery.containedIn("User", usersFilter);
    }
    if (locationIds.length > 0) {
      parseQuery.containedIn("Location", locationIds);
    }

    if (usersFilter.length > 0 && statusFilter) {
      parseQuery.containedIn("User", usersFilter);
      if (statusFilter === "Charging") {
        parseQuery.equalTo("Live", true);
      } else if (statusFilter === "Completed") {
        parseQuery.equalTo("Live", false);
      } else if (statusFilter === "Cancelled") {
        parseQuery.doesNotExist("TotalCost"); // Ensure TotalCost exists
        parseQuery.doesNotExist("MeterEnd"); // Ensure TotalCost exists
        parseQuery.exists("MeterStart"); // Ensure TotalCost exists
        parseQuery.equalTo("cost", NaN);
        parseQuery.equalTo("RangeAdded", 0); // Check if TotalCost is NaN
        parseQuery.equalTo("Live", false);
      }
    }
    if (usersFilter.length > 0 && locationIds.length > 0 && statusFilter) {
      parseQuery.containedIn("Location", locationIds);
      parseQuery.containedIn("User", usersFilter);
      if (statusFilter === "Charging") {
        parseQuery.equalTo("Live", true);
      } else if (statusFilter === "Completed") {
        parseQuery.equalTo("Live", false);
      } else if (statusFilter === "Cancelled") {
        parseQuery.doesNotExist("TotalCost"); // Ensure TotalCost exists
        parseQuery.doesNotExist("MeterEnd"); // Ensure TotalCost exists
        parseQuery.exists("MeterStart"); // Ensure TotalCost exists
        parseQuery.equalTo("cost", NaN);
        parseQuery.equalTo("RangeAdded", 0); // Check if TotalCost is NaN
        parseQuery.equalTo("Live", false);
      }
    }
    if (statusFilter) {
      if (statusFilter === "Charging") {
        parseQuery.equalTo("Live", true);
      } else if (statusFilter === "Completed") {
        parseQuery.exists("TotalCost");
        parseQuery.equalTo("cost", NaN);
        parseQuery.greaterThan("RangeAdded", 0);
        parseQuery.equalTo("Live", false);
      } else if (statusFilter === "Cancelled") {
        parseQuery.doesNotExist("TotalCost");

        parseQuery.equalTo("Live", false);
        parseQuery.equalTo("TransactionId", undefined);
      }
    }
    if (locationIds.length > 0 && statusFilter) {
      parseQuery.containedIn("Location", locationIds);

      if (statusFilter === "Charging") {
        parseQuery.equalTo("Live", true);
      } else if (statusFilter === "Completed") {
        parseQuery.equalTo("Live", false);
      } else if (statusFilter === "Cancelled") {
        parseQuery.doesNotExist("TotalCost"); // Ensure TotalCost exists
        parseQuery.doesNotExist("MeterEnd"); // Ensure TotalCost exists
        parseQuery.exists("MeterStart"); // Ensure TotalCost exists
        parseQuery.equalTo("cost", NaN);
        parseQuery.equalTo("RangeAdded", 0); // Check if TotalCost is NaN
        parseQuery.equalTo("Live", false);
      }
    }

    if (statusFilter && chargerSerialFilter) {
      if (statusFilter === "Charging") {
        parseQuery.equalTo("Live", true);
      } else if (statusFilter === "Completed") {
        parseQuery.equalTo("Live", false);
      } else if (statusFilter === "Cancelled") {
        parseQuery.doesNotExist("TotalCost"); // Ensure TotalCost exists
        parseQuery.doesNotExist("MeterEnd"); // Ensure TotalCost exists
        parseQuery.exists("MeterStart"); // Ensure TotalCost exists
        parseQuery.equalTo("cost", NaN);
        parseQuery.equalTo("RangeAdded", 0); // Check if TotalCost is NaN
        parseQuery.equalTo("Live", false);
      }
      var innerQuery = new Parse.Query("Chargers");

      innerQuery.equalTo("Serial", chargerSerialFilter);
      parseQuery.matchesQuery("ChargePoint", innerQuery);

      const searchTerm = chargerSerialFilter.toLowerCase();
      const filter = tableData.filter((item: any) =>
        Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchTerm)
        )
      );
      setTableData(filter);
    }
    // dateFilter
    if (dateFilter) {
      if (dateFilter === "Today") {
        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment().startOf("day").toString())
        );
        parseQuery.lessThanOrEqualTo(
          "createdAt",
          new Date(moment().add(1, "day").startOf("day").toString())
        );
      } else if (dateFilter === "This Week") {
        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment().startOf("week").toString())
        );
        parseQuery.lessThanOrEqualTo(
          "createdAt",
          new Date(moment().endOf("week").toString())
        );
      } else if (dateFilter === "This Month") {
        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment().startOf("month").toString())
        );
        parseQuery.lessThanOrEqualTo(
          "createdAt",
          new Date(moment().endOf("month").toString())
        );
      } else if (dateFilter === "This Year") {
        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment().startOf("year").toString())
        );
        parseQuery.lessThanOrEqualTo(
          "createdAt",
          new Date(moment().endOf("year").toString())
        );
      }
    }

    if (startDateFilter) {
      // Apply single date filter
      parseQuery.greaterThanOrEqualTo(
        "createdAt",
        new Date(moment(startDateFilter).startOf("day").toString())
      );
      parseQuery.lessThanOrEqualTo(
        "createdAt",
        new Date(moment(startDateFilter).endOf("day").toString())
      );
    }
    if (startDateFilter && endDateFilter) {
      parseQuery.greaterThanOrEqualTo("createdAt", new Date(startDateFilter));
      parseQuery.greaterThanOrEqualTo("createdAt", new Date(startDateFilter));
      const adjustedEndDate = moment(endDateFilter).endOf("day").toDate();
      parseQuery.lessThanOrEqualTo("createdAt", adjustedEndDate);
    }

    // ! ||--------------------------------------------------------------------------------||
    // ! ||                   CPO data visibility according to login type                   ||
    // ! ||--------------------------------------------------------------------------------||
    if (currentUser && !currentUser.get("isSuperAdmin")) {
      var innerQuery = new Parse.Query("Chargers");

      innerQuery.equalTo("CPO", currentUser.get("CPO"));
      parseQuery.matchesQuery("ChargePoint", innerQuery);
    }
    if (chargerSerialFilter) {
      var innerQuery = new Parse.Query("Chargers");
      innerQuery.matches("Serial", new RegExp(chargerSerialFilter, "i"));
      parseQuery.matchesQuery("ChargePoint", innerQuery);
    }

    // Check Cpo

    const count = await parseQuery.count();
    setTotalChargeSessions(count);

    parseQuery.limit(5000);
    await parseQuery.find().then((result) => {
      let totalEnergy = 0;
      let totalTimeConsumed = 0;
      let thisMonthEnergy = 0;
      let lastMonthEnergy = 0;
      let percentageEnergy = 0;
      let totalMoney = 0;
      let totalChargingDuration: "";
      let thisMonthMoney = 0;
      let lastMonthMoney = 0;
      let totalSession = 0;
      let thisMonthSession = 0;
      let lastMonthSession = 0;
      let csvRow: any[] = [
        [
          "Id",
          "Name",
          "Location",
          "Vehicle",
          "Start Time",
          "End Time",
          "Duration",
          "Cost",
          "Energy(kWh)",
          "Serial",
          "Power",
          "Connector",
        ],
      ];
      let csvFirstRow: any[] = [
        ["Revenue", "Energy Consumed", "Total Session", "Charging Duration"],
      ];

      result.forEach((item, index) => {
        totalEnergy = item.get("TotalEnergyConsumed")
          ? item.get("TotalEnergyConsumed") + totalEnergy
          : 0 + totalEnergy;
        totalTimeConsumed = item.get("TotalTimeConsumed")
          ? item.get("TotalTimeConsumed") + totalTimeConsumed
          : 0 + totalTimeConsumed;
        totalMoney = item.get("TotalCost")
          ? item.get("TotalCost") + totalMoney
          : 0 + totalMoney;
        totalSession++;

        let bc = [
          index + 1,
          `${item?.get("User")?.get("FullName")}`,
          item.get("Location")?.get("Name"),
          `${item?.get("Vehicle")?.get("Name")}`,
          `${
            moment(item?.get("createdAt")).format("lll")
              ? moment(item.get("createdAt")).format("lll")
              : "-"
          }`,
          `${
            moment(item.get("updatedAt")).format("lll")
              ? moment(item.get("updatedAt")).format("lll")
              : "-"
          }`,
          `${
            moment
              .duration(
                moment(new Date(item.get("updatedAt"))).diff(
                  moment(new Date(item.get("createdAt")))
                )
              )
              .hours() +
            "hr" +
            " " +
            moment
              .duration(
                moment(new Date(item.get("updatedAt"))).diff(
                  moment(new Date(item.get("createdAt")))
                )
              )
              .minutes() +
            "min"
          }  `,
          `₹ ${parseFloat(item.get("TotalCost")).toFixed(2)}`,
          `${parseFloat(item.get("TotalEnergyConsumed")).toFixed(2)}`,

          `${item.get("ChargePoint")?.get("Serial")}`,
          `${item.get("ChargePoint")?.get("Power")}`,
          `${item.get("ChargePoint")?.get("Connector")}`,
        ];
       
        csvRow.push(bc);
      });
      csvFirstRow.push(
        [totalMoney, totalEnergy, totalSession, secToMinute(totalTimeConsumed)],
        ["", "", "", ""]
      );
      setCsvState(csvFirstRow.concat(csvRow));
      setUsage({
        thisMonth: thisMonthEnergy,
        lastMonth: lastMonthEnergy,
        total: totalEnergy,
      });
      setBilledRevenue({
        thisMonth: thisMonthMoney,
        lastMonth: lastMonthMoney,
        total: totalMoney,
      });

      setChargeDuration({
        thisMonth: thisMonthMoney,
        lastMonth: lastMonthMoney,
        total: secToMinute(totalTimeConsumed),
      });
    });
  };

  useEffect(() => {
    const getUsers = async () => {
      try {
        const User = Parse.Object.extend("User");
        const parseQuery = new Parse.Query(User);
        parseQuery.notEqualTo("UserType", "Cloud");
        parseQuery.limit(4500);
        const result = await parseQuery.find();
        
        let usersArray: optionObj[] = [];
        result.forEach((item) => {
          usersArray.push({
            id: item.id,
            label: item.get("FullName"),
          });
        });
        console.log("usersArray",usersArray);
        
        setAllUsers(usersArray);
      } catch (error) {
        console.error("Error fetching users:", error);
      } 
    };

    getUsers();
  }, []);
  // useEffect(() => {
  //   const getUsers = async () => {
  //     const parseQuery = new Parse.Query("ChargeSession");
  //     parseQuery.include("User");
  //     parseQuery.limit(5000);
  //     const result = await parseQuery.find();
  //     console.log("result users",result);
      
     
  //     let usersArray: optionObj[] = [];
  //     const ocppPromises = result.map(async (item) => {
  //       usersArray.push({
  //         id: item.id,
  //         label: item.get("FullName"),
  //       });
  //     });

  //     await Promise.all(ocppPromises);
    
  //     setAllUsers(usersArray);
  //   };

  //   getUsers();
  // }, []);

  // User Name Formatted code
  const capitalizeFirstLetterOfEachWord = (str: any) => {
    // return str.replace(/\b\w/g, (char:any) => char.toUpperCase());
    return str
      ?.toLowerCase()
      ?.replace(/\b\w/g, (char: any) => char.toUpperCase());
  };

  const loadSessions = async (
    pageNumber: number,
    itemsPerPage: number,
    dateFilter: string
  ) => {
    setTableLoading(true);
    // var dateFilter = "This Week";
    if (dateFilter === "This Week") {
      setDateFilterChips([{ id: "This Week", label: "This Week" }]);
    }

    const parseQuery = new Parse.Query("ChargeSession");
    parseQuery.include("ChargePoint");
    let filteredData: any[] = [];

    if (dateFilter !== "This Week") {
      parseQuery.limit(5000);
      // setDateFilterChips([]);
    }
    
    if (usersFilter.length > 0) {
      parseQuery.containedIn("User", usersFilter);
    }

    if (locationIds.length > 0) {
      parseQuery.containedIn("Location", locationIds);
    }

    if (statusFilter) {
      if (statusFilter === "Charging") {
        parseQuery.equalTo("Live", true);
      } else if (statusFilter === "Completed") {
        parseQuery.equalTo("Live", false);
        // parseQuery.equalTo("cost", NaN)
        // parseQuery.greaterThan("RangeAdded",0);
        // parseQuery.exists("TotalCost")
        parseQuery.exists("TransactionId");
      } else if (statusFilter === "Cancelled") {
        parseQuery.equalTo("TransactionId", undefined);
        parseQuery.equalTo("Live", false);
      }
    }

    if (dateFilter === "This Week") {
      parseQuery.greaterThanOrEqualTo(
        "createdAt",
        new Date(moment().startOf("week").toString())
      );
      parseQuery.lessThanOrEqualTo(
        "createdAt",
        new Date(moment().endOf("week").toString())
      );
    } else if (dateFilter === "Today") {
      parseQuery.greaterThanOrEqualTo(
        "createdAt",
        new Date(moment().startOf("day").toString())
      );
      parseQuery.lessThanOrEqualTo(
        "createdAt",
        new Date(moment().add(1, "day").startOf("day").toString())
      );
    } else if (dateFilter === "This Month") {
      parseQuery.greaterThanOrEqualTo(
        "createdAt",
        new Date(moment().startOf("month").toString())
      );
      parseQuery.lessThanOrEqualTo(
        "createdAt",
        new Date(moment().endOf("month").toString())
      );
    } else if (dateFilter === "This Year") {
      parseQuery.greaterThanOrEqualTo(
        "createdAt",
        new Date(moment().startOf("year").toString())
      );
      parseQuery.lessThanOrEqualTo(
        "createdAt",
        new Date(moment().endOf("year").toString())
      );
    }
    if (startDateFilter) {
      parseQuery.limit(1000);
      parseQuery.greaterThanOrEqualTo(
        "createdAt",
        new Date(moment(startDateFilter).startOf("day").toString())
      );
      parseQuery.lessThanOrEqualTo(
        "createdAt",
        new Date(moment(startDateFilter).endOf("day").toString())
      );
    }
    if (startDateFilter && endDateFilter) {
      parseQuery.limit(5000);
      parseQuery.greaterThanOrEqualTo("createdAt", new Date(startDateFilter));
      parseQuery.greaterThanOrEqualTo("createdAt", new Date(startDateFilter));
      const adjustedEndDate = moment(endDateFilter).endOf("day").toDate();
      parseQuery.lessThanOrEqualTo("createdAt", adjustedEndDate);
    }
    if (currentUser && !currentUser.get("isSuperAdmin")) {
      var innerQuery = new Parse.Query("Chargers");

      innerQuery.equalTo("CPO", currentUser.get("CPO"));
      parseQuery.matchesQuery("ChargePoint", innerQuery);
    }

    if (chargerSerialFilter) {
      var innerQuery = new Parse.Query("Chargers");
      innerQuery.matches("Serial", new RegExp(chargerSerialFilter, "i"));
      parseQuery.matchesQuery("ChargePoint", innerQuery);
    }

    if (usersFilter.length > 0 && chargerSerialFilter) {
      var innerQuery = new Parse.Query("Chargers");
      innerQuery.matches("Serial", new RegExp(chargerSerialFilter, "i"));
      parseQuery.containedIn("User", usersFilter);
      parseQuery.matchesQuery("ChargePoint", innerQuery);
    }
    parseQuery.include("Location");
    parseQuery.include("Vehicle");
    parseQuery.include("User");
    parseQuery.descending("createdAt");
    const skip = (pageNumber - 1) * itemsPerPage;
    parseQuery.skip(skip);
    parseQuery.limit(itemsPerPage);
    const ChargeSessionsCount = await parseQuery.count();
    setTotalChargeSessions(ChargeSessionsCount);
    parseQuery.find().then(async (result: any[]) => {
      let newRow: any[] = [];
      const startIndex = (pageNumber - 1) * itemsPerPage + 1;
      let totalEnergy = 0;
      let thisMonthEnergy = 0;
      let lastMonthEnergy = 0;
      let totalMoney = 0;
      let thisMonthMoney = 0;
      let lastMonthMoney = 0;
      let totalSession = 0;
      let thisMonthSession = 0;
      let lastMonthSession = 0;
      let usersArray: any[] = [];
      let liveCount = 0;
      const ocppPromises = result.map(async (item, index) => {
        let ocppData: any = { energy: "", time: "" };
        const userObj = {
          id: `${item?.get("User")?.id}`,
          label: `${item?.get("User")?.get("FullName")}`,
        };

        if (
          !usersArray.find(function (i) {
            return i.id === userObj.id;
          })
        ) {
          usersArray.push(userObj);
        }

        if (moment(item.get("createdAt")).isAfter(moment().startOf("month"))) {
          thisMonthEnergy = item.get("TotalEnergyConsumed")
            ? item.get("TotalEnergyConsumed") + thisMonthEnergy
            : 0 + thisMonthEnergy;
          thisMonthMoney = item.get("TotalCost")
            ? item.get("TotalCost") + thisMonthMoney
            : 0 + thisMonthMoney;
          thisMonthSession = thisMonthSession + 1;
        } else if (
          item.get("createdAt") >
            moment().subtract(1, "months").startOf("month") &&
          item.get("createdAt") < moment().startOf("month")
        ) {
          lastMonthEnergy = item.get("TotalEnergyConsumed")
            ? item.get("TotalEnergyConsumed") + lastMonthEnergy
            : 0 + lastMonthEnergy;
          lastMonthMoney = item.get("TotalCost")
            ? item.get("TotalCost") + lastMonthMoney
            : 0 + lastMonthMoney;
          lastMonthSession = lastMonthSession + 1;
        } else {
          totalEnergy = item.get("TotalEnergyConsumed")
            ? item.get("TotalEnergyConsumed") + totalEnergy
            : 0 + totalEnergy;
          totalMoney = item.get("TotalCost")
            ? item.get("TotalCost") + totalMoney
            : 0 + totalMoney;
          totalSession++;
        }
        let car = "";
        if (`${item.get("Vehicle")}` !== "undefined") {
          car = `${item.get("Vehicle").get("Name")}`;
        }

        let name = "";
        // const capitalize = (str:any) => {
        //   return str.replace(/\b\w/g, (char:any) => char.toUpperCase());
        //   // return str
        //   // .toLowerCase()
        //   // .replace(/\b\w/g, (char:any) => char.toUpperCase());
        // };

        if (`${item.get("User")?.get("FullName")}` !== "undefined") {
          name = capitalizeFirstLetterOfEachWord(
            `${item.get("User")?.get("FullName")}`
          );
        }
        // if (`${item.get("User")?.get("FullName")}` !== "undefined") {
        //   name = capitalize(`${item.get("User")?.get("FullName")}`);
        // }

        var newTime = "";
        if (item.get("TotalTimeConsumed") != null) {
          var timeInSeconds = item.get("TotalTimeConsumed");
          var hours = Math.floor(timeInSeconds / 3600);
          timeInSeconds = timeInSeconds - hours * 3600;
          var minutes = Math.floor(timeInSeconds / 60);
          timeInSeconds = timeInSeconds - minutes * 60;
          if (hours === 0) {
            newTime = `${minutes}` + "m " + `${timeInSeconds}` + "s";
          } else {
            newTime =
              `${hours}` +
              "h " +
              `${minutes}` +
              "m " +
              `${timeInSeconds}` +
              "s";
          }
        }

        var rangeAdded = 0.0;
        rangeAdded = item.get("RangeAdded");

        var transactionId = "";
        transactionId = item.get("TransactionId");

        var unit = "";
        if (item.get("ChargeBy") === "Time") {
          unit = "Hours";
        } else if (item.get("ChargeBy") === "Units") {
          unit = "kWh";
        } else {
          unit = "₹";
        }

        let ab = {
          id: startIndex + index,
          orderId: `${item.id}`,
          isLive: `${item.get("Live")}`,
          chargeFor: `${item.get("ChargeFor")}` + " " + unit,
          customer: name,
          location: `${item.get("Location")?.get("Name")}`,
          carCharged: car,
          aid: `${item.get("AID")}`,
          startTime: `${moment(item.get("createdAt")).format("lll")}`,
          endTime: `${moment(item.get("updatedAt")).format("lll")}`,
          date: `${moment(item.get("createdAt")).format("DD MMM YYYY")}`,
          cost: "₹" + `${item.get("TotalCost")?.toFixed(2)}`,
          energy: `${item.get("TotalEnergyConsumed")}` + " kWh",
          rangeAdded: rangeAdded?.toFixed(2) + " km",
          time: newTime,
          serialNum: `${item.get("ChargePoint")?.get("Serial")}`,
          power: `${item.get("ChargePoint")?.get("Power")}`,
          connector: `${item.get("ChargePoint")?.get("Connector")}`,
          tariff: `${item.get("ChargePoint")?.get("Cost")}`,

          duration: `${
            moment
              .duration(
                moment(new Date(item.get("updatedAt"))).diff(
                  moment(new Date(item.get("createdAt")))
                )
              )
              .hours() +
            "hr" +
            " " +
            moment
              .duration(
                moment(new Date(item.get("updatedAt"))).diff(
                  moment(new Date(item.get("createdAt")))
                )
              )
              .minutes() +
            "min"
          }  `,
          transactionId: "transactionId",
          obj: item,
        };

        if (item.get("Live") && item.get("ChargePoint").get("isOCPP")) {
          getOcppData(item.get("TransactionId"), ab, item.get("createdAt"));

          liveCount++;
        } else {
          newRow.push(ab);
        }
      });
      await Promise.all(ocppPromises);

      if (chargerSerialFilter) {
        setTableData(newRow);
      } else {
        setTableData(newRow);
      }

      setChargeSession({
        thisMonth: thisMonthSession,
        lastMonth: lastMonthSession,
        total: ChargeSessionsCount,
      });

      setTableLoading(false);
    });
  };

  useEffect(() => {
    getAllLocations();
  }, []);

  useEffect(() => {
    getWidgetsData();
    loadSessions(pageNumber, itemsPerPage, dateFilter);
  }, [
    usersFilter,
    locationIds,
    statusFilter,
    chargerSerialFilter,
    dateFilter,
    startDateFilter,
    endDateFilter,
    pageNumber,
  ]);

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                    Handle Custom File Name With Dates Values                   ||
  // ! ||--------------------------------------------------------------------------------||
  const customFilename = () => {
    let filename = "Charge Session";
    const today = moment().format("YYYY-MM-DD");

    if (startDateFilter) {
      const startDate = moment(startDateFilter).format("YYYY-MM-DD");
      if (endDateFilter) {
        const endDate = moment(endDateFilter).format("YYYY-MM-DD");
        filename += `_${startDate}_to_${endDate}`;
      } else {
        filename += `_${startDate}`;
      }
    } else {
      switch (dateFilter) {
        case "Today":
          filename += `_${today}`;
          break;
        case "This Week":
          const startOfWeek = moment().startOf("week").format("YYYY-MM-DD");
          filename += `_${startOfWeek}_to_${today}`;
          break;
        case "This Month":
          const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
          filename += `_${startOfMonth}_to_${today}`;
          break;
        case "This Year":
          const startOfYear = moment().startOf("year").format("YYYY-MM-DD");
          filename += `_${startOfYear}_to_${today}`;
          break;
        default:
          filename += `_All`;
      }
    }

    return `${filename}.csv`;
  };

  return (
    <div className="transaction-container">
      <div className="transaction_table_container flex">
        <div style={{ width: "100%" }}>
          <div className="flex justify-between mx-6 mt-5 ">
            <h2 className="transaction_heading mt-2">Charge Sessions</h2>
            <div className="flex justify-between items-center">
              <CSVLink data={csvState} filename={customFilename()}>
                {" "}
                <span
                  className="flex items-center py-3 px-4 mr-3 rounded-full font-lexend"
                  style={{
                    background: "#1AC47D",
                    color: "white",
                    border: "2px solid #1AAD70",
                  }}
                >
                  <span>
                    <svg
                      className="mr-2"
                      width="14"
                      height="15"
                      viewBox="0 0 14 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 10.75V12.25C1 12.6478 1.15804 13.0294 1.43934 13.3107C1.72064 13.592 2.10218 13.75 2.5 13.75H11.5C11.8978 13.75 12.2794 13.592 12.5607 13.3107C12.842 13.0294 13 12.6478 13 12.25V10.75M3.25 6.25L7 10M7 10L10.75 6.25M7 10V1"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span className="font-lexend text-sm tracking-wide">Download CSV </span>
                </span>
              </CSVLink>
              <button
                className="filter_button rounded-full p-3 border-2 border-black-600"
                onClick={handleFilterButtonClick}
              >
                <FilterIcon />
              </button>
            </div>

            {/* Modal Button */}
            <button
              aria-describedby={id}
              onClick={handleClick}
              className="modal_button_filter rounded-full p-3 border-2 border-black-600"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M3 3H15V4.629C14.9999 5.02679 14.8418 5.40826 14.5605 5.6895L11.25 9V14.25L6.75 15.75V9.375L3.39 5.679C3.13909 5.40294 3.00004 5.0433 3 4.67025V3Z"
                  stroke="#111111"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              {/* All filters in Mobile Version */}
              <div className="filters flex flex-col  border-2 border-black-400">
                <div className="filter_header z-50 sticky top-0 right-0 flex justify-between px-4 py-5 mb-8">
                  <h1 className="font-semibold font-lexend text-lg">Filters</h1>
                  <button
                    style={{
                      background: "#1AC47D",
                      width: "35px",
                      height: "35px",
                      padding: "5px",
                      borderRadius: "50%",
                      border: "2px solid #1AAD70",
                    }}
                    // onClick={handleFilterButtonClick}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M3.33301 8.00033L6.66634 11.3337L13.333 4.66699"
                        stroke="white"
                        stroke-width="1.33333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>

                <div className="filter_main_container px-4">
                  <h1 className="font-semibold font-lexend text-lg mb-2">
                    Users
                  </h1>
                  <Autocomplete
                    className="mb-4"
                    freeSolo
                    id="checkboxes-tags-demo"
                    disableClearable
                    multiple
                    limitTags={-1}
                    options={allUsers}
                    autoHighlight
                    size="small"
                    value={userFilter}
                    onChange={(event: any, newValue: any) => {
                      setFilterChips(
                        (prevChips: { id: string; label: string }[]) => {
                          // Ensure that newValue is an array of objects with 'id' property
                          const newChips: { id: string; label: string }[] =
                            newValue.map((user: any) => ({
                              id: user.id,
                              label: `User: ${user.label}`,
                            }));

                          const filteredChips = newChips.filter(
                            (chip) =>
                              !prevChips.some(
                                (prevChip) => prevChip.id === chip.id
                              )
                          );

                          return [...prevChips, ...filteredChips];
                        }
                      );
                      setUserFilter(newValue);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    getOptionLabel={(option: any) => option.label}
                    getOptionDisabled={(option) =>
                      filterChips.some((chip) => chip.id === option.id)
                    }
                    renderOption={(props, option) => {
                      let counter = 0;
                      const uniqueKey = `${option.id}_${
                        option.label
                      }_${counter++}`;
                      const selected = filterChips.some(
                        (chip) => chip.id === option.id
                      );
                      return (
                        <MenuItem key={uniqueKey} {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={
                              selected ||
                              filterChips.some((chip) => chip.id === option.id)
                            }
                          />
                          <ListItemText primary={option.label} />
                        </MenuItem>
                      );
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        label="Search"
                        onChange={(e) => setUserFind(e.target.value)}
                      />
                    )}
                  />
                  <h1 className="font-semibold font-lexend text-lg mb-2">
                    Location
                  </h1>
                  <Autocomplete
                    className="mb-4"
                    freeSolo
                    id="checkboxes-tags-demo"
                    disableClearable
                    multiple
                    limitTags={-1}
                    options={allLocations}
                    autoHighlight
                    size="small"
                    value={locationFilter}
                    onChange={(event: any, newValue: any) => {
                      setLocationChips(
                        (prevChips: { id: string; label: string }[]) => {
                          const newChips: { id: string; label: string }[] =
                            newValue.map((user: any) => ({
                              id: user.id,
                              label: `Location: ${user.label}`,
                            }));

                          const filteredChips = newChips.filter(
                            (chip) =>
                              !prevChips.some(
                                (prevChip) => prevChip.id === chip.id
                              )
                          );

                          return [...prevChips, ...filteredChips];
                        }
                      );
                      setLocationFilter(newValue);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    getOptionLabel={(option: any) => option.label}
                    getOptionDisabled={(option) =>
                      filterChips.some((chip) => chip.id === option.id)
                    }
                    renderOption={(props, option) => {
                      let counter = 0;
                      const uniqueKey = `${option.id}_${
                        option.label
                      }_${counter++}`;
                      const selected = filterChips.some(
                        (chip) => chip.id === option.id
                      );
                      return (
                        <MenuItem key={uniqueKey} {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={
                              selected ||
                              filterChips.some((chip) => chip.id === option.id)
                            }
                          />
                          <ListItemText primary={option.label} />
                        </MenuItem>
                      );
                    }}
                    renderInput={(params: any) => (
                      <TextField {...params} label="Search" />
                    )}
                  />
                  {/* Status Filter */}
                  <div className="">
                    <h1 className="font-semibold font-lexend text-lg">
                      Status
                    </h1>
                    <div className="flex">
                      <input
                        type="radio"
                        className="mr-3"
                        checked={statusFilter === "Charging"}
                        onChange={() => handleStatusChange("Charging")}
                      />
                      <label htmlFor="" className="font-lexend">
                        Charging
                      </label>
                    </div>
                    <div className="flex">
                      <input
                        type="radio"
                        className="mr-3"
                        checked={statusFilter === "Completed"}
                        onChange={() => handleStatusChange("Completed")}
                      />
                      <label htmlFor="" className="font-lexend">
                        Completed
                      </label>
                    </div>
                    <div className="flex">
                      <input
                        type="radio"
                        className="mr-3"
                        checked={statusFilter === "Cancelled"}
                        onChange={() => handleStatusChange("Cancelled")}
                      />
                      <label htmlFor="" className="font-lexend">
                        Cancelled
                      </label>
                    </div>
                  </div>

                  <h1 className="font-semibold mt-5 mb-2 font-lexend text-lg">
                    Charger Serial
                  </h1>
                  <TextField
                    id="outlined-search"
                    label="Search Charger Serial"
                    type="text"
                    size="small"
                    sx={{ width: "100%", marginBottom: 5 }}
                    onChange={(event: any) => {
                      setChargerSerialFilter(event.target.value);
                    }}
                  />

                  <div className="-mt-4">
                    <h1 className="font-semibold font-lexend text-lg">Date</h1>
                    <div className="flex">
                      <input
                        type="radio"
                        className="mr-3"
                        checked={dateFilter === "Today"}
                        onChange={() => handleDateChange("Today")}
                      />
                      <label htmlFor="" className="font-lexend">
                        Today
                      </label>
                    </div>
                    <div className="flex">
                      <input
                        type="radio"
                        className="mr-3"
                        checked={dateFilter === "This Week"}
                        onChange={() => handleDateChange("This Week")}
                      />
                      <label htmlFor="" className="font-lexend">
                        This Week
                      </label>
                    </div>
                    <div className="flex">
                      <input
                        type="radio"
                        className="mr-3"
                        checked={dateFilter === "This Month"}
                        onChange={() => handleDateChange("This Month")}
                      />
                      <label htmlFor="" className="font-lexend">
                        This Month
                      </label>
                    </div>
                    <div className="flex">
                      <input
                        type="radio"
                        className="mr-3"
                        checked={dateFilter === "This Year"}
                        onChange={() => handleDateChange("This Year")}
                      />
                      <label htmlFor="" className="font-lexend">
                        This Year
                      </label>
                    </div>
                    <div className="flex">
                      <input
                        type="radio"
                        className="mr-3"
                        checked={dateFilter === "Custom"}
                        onChange={handleCustomChange}
                      />
                      <label htmlFor="" className="font-lexend">
                        Custom Date
                      </label>
                    </div>
                  </div>

                  {openCustom && (
                    <div className="dateSec mb-4">
                      <h1 className="mt-4 mb-3 font-lexend">Custom</h1>
                      <div className="start_dateContainer mt-4">
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <MuiDatePicker
                            label="Start Date"
                            value={startDateFilter}
                            onChange={dateRangeChange}
                            disabled
                            className="mt-4"
                            renderInput={(params) => (
                              <TextField {...params} error={false} />
                            )}
                            inputFormat="DD-MM-YYYY"
                          />
                        </LocalizationProvider>
                      </div>
                      <div className="endDate_container mt-4">
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <MuiDatePicker
                            label="End Date"
                            // value={endDateFilter}
                            disabled
                            value={endDateFilter}
                            // onChange={(item) => setEndDateFilter(item || "")}
                            onChange={(item) => {
                              setEndDateFilter(item || "");
                              setDateRangeChipVisible(
                                !!startDateFilter && !!item
                              );
                            }}
                            renderInput={(params) => (
                              <TextField {...params} error={false} />
                            )}
                            inputFormat="DD-MM-YYYY"
                          />
                        </LocalizationProvider>
                      </div>

                      <div className="rangePicker_container mt-4">
                        <DatePicker
                          selected={startDateFilter}
                          onChange={dateRangeChange}
                          startDate={startDateFilter}
                          endDate={endDateFilter}
                          selectsRange
                          inline
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Popover>
          </div>
          <div className="filter-chips-container flex flex-wrap ml-4">
            {/* User Filter */}
            {filterChips.map((chip) => (
              <Chip
                key={chip.id}
                label={capitalizeFirstLetterOfEachWord(chip.label)}
                onDelete={() => handleDeleteChip(chip.id)}
                variant="outlined"
                sx={{ marginLeft: "5px", marginBottom: "10px" }}
              />
            ))}

            {/* Location Chips */}
            {locationChips.map((chip) => (
              <Chip
                key={chip.id}
                label={chip.label}
                onDelete={() => handleLocationDeleteChip(chip.id)}
                variant="outlined"
                sx={{ marginLeft: "5px", marginBottom: "10px" }}
              />
            ))}
            {/* Charger Status Chips */}
            {statusChips.map((chip) => (
              <Chip
                key={chip.id}
                label={chip.label}
                onDelete={() => handleDeleteStatusChip(chip.id)}
                variant="outlined"
                sx={{ marginLeft: "5px", marginBottom: "10px" }}
              />
            ))}
            {/* Single Date Chip */}
            {dateChips.map((chip) => (
              <Chip
                key={chip.id}
                label={chip.label}
                onDelete={() => handleDeleteDateChip(chip.id)}
                variant="outlined"
                sx={{ marginLeft: "5px", marginBottom: "10px" }}
              />
            ))}
            {/* Range Date Chip */}
            {dateRangeChipVisible && (
              <Chip
                label={`Date Between : ${moment(startDateFilter).format(
                  "DD-MM-YYYY"
                )} - ${moment(endDateFilter).format("DD-MM-YYYY")}`}
                onDelete={handleDateRangeDelete}
                variant="outlined"
                sx={{ marginLeft: "5px" }}
              />
            )}
            {/* Handle range Date Chip when deleted Single Date chip shows this prevent that bug */}
            {!dateRangeChipVisible && SingleDateChips && startDateFilter && (
              <Chip
                label={`Date : ${moment(startDateFilter).format("DD-MM-YYYY")}`}
                onDelete={handleSingleDeleteDateChip}
                variant="outlined"
                sx={{ marginLeft: "5px", marginBottom: "10px" }}
              />
            )}
          </div>

          <div className="topRow">
            <div className="dwidgets">
              <Widget type="billedRevenue" data={billedRevenue} />
              <Widget type="usage" data={usage} />
              <Widget type="chargeSession" data={chargeSession} />
              <Widget type="ChargingDuration" data={chargeDuration} />
            </div>
          </div>

          <DatabaseTable data={tableData} loading={tableLoading} />
          <div className="flex justify-end mr-5 ml-5 mb-6 px-4 py-5 border border-gray-300">
            <span className="bold font-lexend mr-5">
              {" "}
              {pageNumber * 100 - 99}–
              {Math.min(pageNumber * 100, totalChargeSessions)} of{" "}
              {totalChargeSessions}
            </span>
            <button
              disabled={pageNumber == 1}
              style={{ color: `${pageNumber == 1 ? "lightgray" : "black"}` }}
              onClick={handlePreviousPage}
            >
              {" "}
              <KeyboardArrowLeftIcon />{" "}
            </button>
            <button className="ml-8" onClick={handleNextPage}>
              <ChevronRightIcon />
            </button>
          </div>
        </div>
        {isFilterVisible && (
          <div className="filters lg:w-1/3 flex flex-col  border-2 border-black-400">
            <div className="filter_header z-50 sticky top-0 right-0 flex justify-between px-4 py-5 mb-8">
              <h1 className="font-semibold font-lexend text-lg">Filters</h1>
              <button
                style={{
                  background: "#1AC47D",
                  border:'2px solid #1AAD70',
                  width: "35px",
                  height: "35px",
                  paddingLeft: "7px",
                  borderRadius: "50%",
                }}
                onClick={handleFilterButtonClick}
              >
                <CloseFilterIcon />
              </button>
            </div>

            <div className="filter_main_container px-4">
              <h1 className="font-semibold font-lexend text-lg mb-2">Users</h1>
              <Autocomplete
                className="mb-4"
                ListboxProps={{ style: { maxHeight: 210 } }}
                id="checkboxes-tags-demo"
                disableClearable
                multiple
                limitTags={-1}
                options={allUsers}
                autoHighlight
                size="small"
                value={userFilter}
                filterOptions={(options, { inputValue }) => {
                  const inputPattern = new RegExp(
                    `^${inputValue
                      .split(" ")
                      .map((word) => `(?=.*\\b${word})`)
                      .join("")}`,
                    "i"
                  );

                  // Filter options based on the inputValue and ensure selected options are handled properly
                  return options.filter((option) => {
                    const label = option?.label?.toLowerCase();
                    const matchesInput = inputPattern?.test(label);
                    const isDisabled = filterChips?.some(
                      (chip) => chip?.id === option?.id
                    );

                    return matchesInput && !isDisabled;
                  });
                }}
                onChange={(event: any, newValue: any) => {
                  console.log("new Value selected",newValue);
                  
                  setFilterChips(
                    (prevChips: { id: string; label: string }[]) => {
                      const newChips: { id: string; label: string }[] =
                        newValue.map((user: any) => ({
                          id: user.id,
                          label: `User: ${user.label}`,
                        }));

                      const filteredChips = newChips.filter(
                        (chip) =>
                          !prevChips.some((prevChip) => prevChip.id === chip.id)
                      );

                      return [...prevChips, ...filteredChips];
                    }
                  );
                  setUserFilter(newValue);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option: any) => option.label}
                getOptionDisabled={(option) =>
                  filterChips.some((chip) => chip.id === option.id)
                }
                renderOption={(props, option: any, index) => {
                  const key = `listItem-${index}-${option.id}`;
                  const selected = filterChips.some(
                    (chip) => chip.id === option.id
                  );
                  return (
                    <MenuItem {...props} key={key}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={
                          selected ||
                          filterChips.some((chip) => chip.id === option.id)
                        }
                      />
                      <Typography
                        sx={{ fontFamily: "lexend", fontSize: "16px" }}
                      >
                        {/* Formatted name function call */}
                        {capitalizeFirstLetterOfEachWord(option.label)}
                      </Typography>
                    </MenuItem>
                  );
                }}
                renderInput={(params: any) => (
                  <TextField {...params} placeholder="Type to search...." />
                )}
              />

              <h1 className="font-semibold font-lexend text-lg mb-2">
                Location
              </h1>
              <Autocomplete
                className="mb-4"
                freeSolo
                id="checkboxes-tags-demo"
                disableClearable
                multiple
                limitTags={-1}
                options={allLocations}
                autoHighlight
                size="small"
                value={locationFilter}
                onChange={(event: any, newValue: any) => {
                  setLocationChips(
                    (prevChips: { id: string; label: string }[]) => {
                      const newChips: { id: string; label: string }[] =
                        newValue.map((user: any) => ({
                          id: user.id,
                          label: `Location: ${user.label}`,
                        }));

                      const filteredChips = newChips.filter(
                        (chip) =>
                          !prevChips.some((prevChip) => prevChip.id === chip.id)
                      );

                      return [...prevChips, ...filteredChips];
                    }
                  );
                  setLocationFilter(newValue);
                }}
                filterOptions={(options, { inputValue }) => {
                  const inputPattern = new RegExp(
                    `^${inputValue
                      .split(" ")
                      .map((word) => `(?=.*\\b${word})`)
                      .join("")}`,
                    "i"
                  );

                  // Filter options based on the inputValue and ensure selected options are handled properly
                  return options.filter((option) => {
                    const label = option.label.toLowerCase();
                    const matchesInput = inputPattern.test(label);
                    const isDisabled = filterChips.some(
                      (chip) => chip.id === option.id
                    );

                    return matchesInput && !isDisabled;
                  });
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option: any) => option.label}
                getOptionDisabled={(option) =>
                  filterChips.some((chip) => chip.id === option.id)
                }
                renderOption={(props, option) => {
                  let counter = 0;
                  const uniqueKey = `${option.id}_${option.label}_${counter++}`;
                  const selected = filterChips.some(
                    (chip) => chip.id === option.id
                  );

                  return (
                    <MenuItem key={uniqueKey} {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={
                          selected ||
                          filterChips.some((chip) => chip.id === option.id)
                        }
                      />
                      <Typography sx={{ fontFamily: "lexend" }}>
                        {option.label}
                      </Typography>
                    </MenuItem>
                  );
                }}
                renderInput={(params: any) => (
                  <TextField {...params} label="Search" />
                )}
              />
              {/* Status Filter */}
              <div className="">
                <h1 className="font-semibold font-lexend text-lg">Status</h1>
                <div className="flex">
                  <input
                    type="radio"
                    className="mr-3"
                    checked={statusFilter === "Charging"}
                    onChange={() => handleStatusChange("Charging")}
                  />
                  <label htmlFor="" className="font-lexend">
                    Charging
                  </label>
                </div>
                <div className="flex">
                  <input
                    type="radio"
                    className="mr-3"
                    checked={statusFilter === "Completed"}
                    onChange={() => handleStatusChange("Completed")}
                  />
                  <label htmlFor="" className="font-lexend">
                    Completed
                  </label>
                </div>
                <div className="flex">
                  <input
                    type="radio"
                    className="mr-3"
                    checked={statusFilter === "Cancelled"}
                    onChange={() => handleStatusChange("Cancelled")}
                  />
                  <label htmlFor="" className="font-lexend">
                    Cancelled
                  </label>
                </div>
              </div>

              <h1 className="font-semibold mt-5 mb-2 font-lexend text-lg">
                Charger Serial
              </h1>
              <TextField
                id="outlined-search"
                label="Search Charger Serial"
                type="text"
                size="small"
                sx={{ width: "100%", marginBottom: 5 }}
                onChange={(event: any) => {
                  setChargerSerialFilter(event.target.value);
                }}
              />

              <div className="date_filter_container -mt-5">
                <h1 className="font-semibold font-lexend text-lg">Date</h1>
                <div className="flex">
                  <input
                    type="radio"
                    className="mr-3"
                    checked={dateFilter === "Today"}
                    onChange={() => handleDateChange("Today")}
                  />
                  <label htmlFor="" className="font-lexend">
                    Today
                  </label>
                </div>
                <div className="flex">
                  <input
                    type="radio"
                    className="mr-3"
                    checked={dateFilter === "This Week"}
                    onChange={() => handleDateChange("This Week")}
                  />
                  <label htmlFor="" className="font-lexend">
                    This Week
                  </label>
                </div>
                <div className="flex">
                  <input
                    type="radio"
                    className="mr-3"
                    checked={dateFilter === "This Month"}
                    onChange={() => handleDateChange("This Month")}
                  />
                  <label htmlFor="" className="font-lexend">
                    This Month
                  </label>
                </div>
                <div className="flex">
                  <input
                    type="radio"
                    className="mr-3"
                    checked={dateFilter === "This Year"}
                    onChange={() => handleDateChange("This Year")}
                  />
                  <label htmlFor="" className="font-lexend">
                    This Year
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    className="rounded-checkbox accent-black-500 mr-3"
                    checked={isSelected}
                    onChange={() => handleDateChange("Custom")}
                  />
                  <label htmlFor="" className="font-lexend">
                    Custom Date
                  </label>
                </div>
              </div>

              {isSelected && (
                <div className="dateSec mb-4">
                  <h1 className="mt-4 mb-3 font-lexend">Custom</h1>
                  <div className="start_dateContainer mt-4">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <MuiDatePicker
                        label="Start Date"
                        value={startDateFilter}
                        onChange={dateRangeChange}
                        disabled
                        className="mt-4"
                        renderInput={(params) => (
                          <TextField {...params} error={false} />
                        )}
                        inputFormat="DD-MM-YYYY"
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="endDate_container mt-4">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <MuiDatePicker
                        label="End Date"
                        // value={endDateFilter}
                        disabled
                        value={endDateFilter}
                        // onChange={(item) => setEndDateFilter(item || "")}
                        onChange={(item) => {
                          setEndDateFilter(item || "");
                          setDateRangeChipVisible(!!startDateFilter && !!item);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} error={false} />
                        )}
                        inputFormat="DD-MM-YYYY"
                      />
                    </LocalizationProvider>
                  </div>

                  <div className="rangePicker_container mt-4">
                    <DatePicker
                      selected={startDateFilter}
                      onChange={dateRangeChange}
                      startDate={startDateFilter}
                      endDate={endDateFilter}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      maxDate={new Date()} // Disabled Future Date
                      minDate={new Date(2022, 0, 1)}
                      selectsRange
                      inline
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default ChargeSession;
