import {
  TextField,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { memo, useState } from "react";
import "./pushNotification.scss";

const Pushnotification = memo(() => {
  //Table

  const [deviceType, setDeviceType] = useState<string | null>("");
  const [message, setMessage] = useState<string>("");

  const getAllDevicesData = (device: any) => {
    const parseQuery = new Parse.Query("_Installation");
    if (device) {
      parseQuery.equalTo("deviceType", device);
    }
    parseQuery.include("User");

    parseQuery.limit(100);
    parseQuery.find().then((result: any[]) => {
      let newRow: any[] = [];
      result.forEach((item, index) => {
        let ab = item.get("User");
        newRow.push({
          ab,
        });
      });
    });
  };
  const handleSendPushNotificataion = () => {
    try {
      const params = {
        message: message,
        deviceType: deviceType ? deviceType : "all",
      };

      Parse.Cloud.run("sendPushMessage", params).then(
        (status) => {
          alert("Push Sent");
        },
        (error: any) => {
          alert("Failed to send message, with error code: " + error.message);
        }
      );
    } catch (error: any) {
      alert("Failed to send message, with error code: " + error.message);
    }
  };

  return (
    
    <div className="push_container mt-4 mx-8">
      <h1 className="font-bold font-lexend text-xl mt-4">Push Notifications</h1>
      {/* Top Section */}
      <div className="flex flex-col">
        <div>
          <h1 className="font-bold font-lexend text-l mt-4 ">Devices</h1>
        </div>
        <div className="flex justify-between mt-3 mx-0">
          <div className="flex">
            {/* All */}
            <div>
              <label className="Upcoming_Label">
                <input
                  className="Upcoming_Custom_Radio"
                  type="radio"
                  name="radio"
                  defaultChecked
                  onChange={() => {
                    getAllDevicesData(null);

                    setDeviceType(null);
                  }}
                />
                <span
                  className="Upcoming_Custom_label"
                  style={{ marginLeft: "0px" }}
                >
                  All
                </span>
              </label>
            </div>
            {/* Android */}
            <div>
              <label className="Upcoming_Label">
                <input
                  className="Upcoming_Custom_Radio"
                  type="radio"
                  name="radio"
                  onChange={() => {
                    getAllDevicesData("android");
                    setDeviceType("android");
                  }}
                />
                <span className="Upcoming_Custom_label">Android</span>
              </label>
            </div>
            {/* Ios */}
            <div>
              <label className="Upcoming_Label">
                <input
                  className="Upcoming_Custom_Radio"
                  type="radio"
                  name="radio"
                  onChange={() => {
                    getAllDevicesData("ios");
                    setDeviceType("ios");
                  }}
                />
                <span className="Upcoming_Custom_label">iOS</span>
              </label>
            </div>
          </div>
          {/* Send Button */}
          <div>
            <Tooltip title="Send Notification">
              <button
                className="flex justify-around items-center px-3 py-2 rounded-full mr-3"
                style={{ background: "#1AC47D", border: "2px solid #1AAD70" }}
                onClick={() => handleSendPushNotificataion()}
              >
                <span className="mr-2">
                  <svg
                    width="15"
                    height="17"
                    viewBox="0 0 15 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.375 11.75H1C1.4087 11.5186 1.75736 11.1944 2.01789 10.8036C2.27842 10.4128 2.44356 9.96628 2.5 9.5V7.25C2.54467 6.29831 2.84748 5.3767 3.37596 4.58397C3.90445 3.79124 4.63869 3.15727 5.5 2.75C5.5 2.35218 5.65804 1.97064 5.93934 1.68934C6.22064 1.40804 6.60218 1.25 7 1.25C7.39782 1.25 7.77936 1.40804 8.06066 1.68934C8.34196 1.97064 8.5 2.35218 8.5 2.75C9.36131 3.15727 10.0956 3.79124 10.624 4.58397C11.1525 5.3767 11.4553 6.29831 11.5 7.25V8.75M4.75 11.75V12.5C4.75 13.0967 4.98705 13.669 5.40901 14.091C5.83097 14.5129 6.40326 14.75 7 14.75M10 15.5L13.75 11.75M13.75 11.75V15.125M13.75 11.75H10.375"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <span style={{ color: "white" }}>Send Notification</span>
              </button>
            </Tooltip>
          </div>
        </div>
      </div>

      {/* Message Section */}
      <div>
        <h1 className="font-bold font-lexend text-l mt-3 mb-2">Message</h1>
        <div className="w-full">

            <TextField
              sx={{ width: "100%", backgroundColor: "white" }}
              variant="outlined"
              placeholder="Start typing your message...."
              fullWidth
              multiline
              value={message}
              onChange={(e:any) => setMessage(e.target.value)}
              minRows={5}
              size="small"
            />
          
        </div>
      </div>
    </div>
  );
});

export default Pushnotification;
