import React, { memo } from "react";
import "./widget.scss";

import PriceChangeIcon from "@mui/icons-material/PriceChange";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

type widgetProps = {
  type: String;

  data: any;
};

const Widget = memo((props: widgetProps) => {
  const { type, data } = props;
  console.log("data",data)
  let content;

  // const diff = 20;
  switch (type) {
    case "wallet":
      content = {
        title: "Wallet Balance",

        total: `₹ ${String(data.total.toFixed(2))}`,
        isMoney: false,
        link: "",
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12M9 12C9 11.2044 9.31607 10.4413 9.87868 9.87868C10.4413 9.31607 11.2044 9 12 9C12.7956 9 13.5587 9.31607 14.1213 9.87868C14.6839 10.4413 15 11.2044 15 12M9 12H2M15 12H22M2 7C2 6.46957 2.21071 5.96086 2.58579 5.58579C2.96086 5.21071 3.46957 5 4 5H20C20.5304 5 21.0391 5.21071 21.4142 5.58579C21.7893 5.96086 22 6.46957 22 7V17C22 17.5304 21.7893 18.0391 21.4142 18.4142C21.0391 18.7893 20.5304 19 20 19H4C3.46957 19 2.96086 18.7893 2.58579 18.4142C2.21071 18.0391 2 17.5304 2 17V7Z" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>,
      };
      break;
    case "revenue":
      content = {
        title: "Revenue",
        total: data.total === null ? "-" : `₹ ${String(data.total.toFixed(2))}`,
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
        <path d="M15 8.26953H9H10C10.7956 8.26953 11.5587 8.5856 12.1213 9.14821C12.6839 9.71082 13 10.4739 13 11.2695C13 12.0652 12.6839 12.8282 12.1213 13.3909C11.5587 13.9535 10.7956 14.2695 10 14.2695H9L12 17.2695M9 11.2695H15M3 12.2695C3 13.4514 3.23279 14.6218 3.68508 15.7137C4.13738 16.8056 4.80031 17.7978 5.63604 18.6335C6.47177 19.4692 7.46392 20.1322 8.55585 20.5844C9.64778 21.0367 10.8181 21.2695 12 21.2695C13.1819 21.2695 14.3522 21.0367 15.4442 20.5844C16.5361 20.1322 17.5282 19.4692 18.364 18.6335C19.1997 17.7978 19.8626 16.8056 20.3149 15.7137C20.7672 14.6218 21 13.4514 21 12.2695C21 11.0876 20.7672 9.91731 20.3149 8.82538C19.8626 7.73345 19.1997 6.7413 18.364 5.90557C17.5282 5.06984 16.5361 4.40691 15.4442 3.95462C14.3522 3.50232 13.1819 3.26953 12 3.26953C10.8181 3.26953 9.64778 3.50232 8.55585 3.95462C7.46392 4.40691 6.47177 5.06984 5.63604 5.90557C4.80031 6.7413 4.13738 7.73345 3.68508 8.82538C3.23279 9.91731 3 11.0876 3 12.2695Z" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>,
      };
      break;
    case "transaction":
      content = {
        title: "Transaction",

        total: data.total,
        isMoney: false,
        link: "",
        icon:<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M3 8L7 4M7 4L11 8M7 4V13M13 16L17 20M17 20L21 16M17 20V10" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>,
      };
      break;
    case "avRevenue":
      content = {
        title: "Average Revenue Per Customer(₹)",
        thisMonth: data.thisMonth.toFixed(2),
        lastMonth: data.lastMonth.toFixed(2),
        total: data.total,
        isMoney: true,
        link: "",
        icon: <PriceChangeIcon className="icon" />,
      };
      break;
    case "uniqueUsers":
      content = {
        title: "Unique Users",
        thisMonth: data.thisMonth,
        lastMonth: data.lastMonth,
        total: data.total,
        isMoney: true,
        link: "",
        icon: <PriceChangeIcon className="icon" />,
      };
      break;
    default:
      break;
  }
  console.log("content?.total",content?.total)
  return (
    <div className="Widget_container">
      <div className="title flex items-center mx-1">
       <div className="icon_widget_transaction">
       {content?.icon}
        </div> 
        <h1 className="ml-2 font-lexend">
        {content?.title}
          </h1></div>

      <h1 className="content ml-2"> {content?.total}</h1>
    </div>
  );
});

export default Widget;
