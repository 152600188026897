import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius:"9px",
  padding:'2px 7px 10px'
};

interface LogoutModalProps {
  open: boolean;
  handleClose: () => void;
}

const LogoutModal: React.FC<LogoutModalProps> = ({ open, handleClose }) => {
    const navigate = useNavigate();
    return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
          
            className=" font-lexend text-2xl w-full pt-4 px-3"
            id="modal-modal-title"
          >
            Logout
          </div>
          <div className="ml-4 mt-2">
            <h4 className="font-lexend text-lg text-gray-500">
            Are you sure you want to Log Out?
            </h4>

            <div className="flex flex-col mt-5">
             
            <button
                style={{background:'#1AC47D', border:'2px solid #1AAD70'}}
               onClick={() => {
                    Parse.User.logOut();
                    sessionStorage.setItem("session-id", "");
                    navigate("/");
                  }}
              className="text-white font-lexend hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                Logout
              </button>

              <button
                onClick={handleClose}
                style={{border:'2px solid #868687'}}
                className=" font-lexend focus:outline-none focus:ring-4 focus:ring-gray-200 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-gray-400 dark:hover:bg-gray-500 dark:focus:ring-gray-600"
              >
                Cancel
              </button>
            
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default LogoutModal;
