import { Autocomplete, TextField, Checkbox, Chip } from "@mui/material";
import moment from "moment";
import { memo, useCallback, useEffect, useState } from "react";
import "./revenue.scss";
import TripOriginIcon from "@mui/icons-material/TripOrigin";
import Widget from "./widget";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import Accordion, { AccordionSlots } from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fade from "@mui/material/Fade";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Bar,
  BarChart,
} from "recharts";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { logDOM } from "@testing-library/react";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
interface optionObj {
  id: string;
  label: string;
}

interface UserFilter {
  id: string;
  label: string;
}

interface LocationFilter {
  id: string;
  label: string;
}

const Revenue = memo(() => {
  // Accordion Code
  const [expanded, setExpanded] = useState(false);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  // Filter Variable
  const [dateFilter, setDateFilter] = useState("This Year"); // default filter applied for This year
  const [userFilter, setUserFilter] = useState<UserFilter[]>([]);
  const [filterChips, setFilterChips] = useState<
    { id: string; label: string }[]
  >([]);

  // Widget Data

  const [landCost, setLandCost] = useState({
    thisMonth: 0,
    lastMonth: 0,
    total: 0,
  });
  const [billedRevenue, setBilledRevenue] = useState({
    thisMonth: 0,
    lastMonth: 0,
    total: 0,
  });

  const [avRevenue, setAvRevenue] = useState(0);
  const [energyCost, setEnergyCost] = useState(0);
  const [chartData, setChartData] = useState<any>(0);
  const [grossMargin, setGrossMargin] = useState(0);
  const [lineChartData, setLineChartData] = useState<any>(0);
  const [locationFilter, setLocationFilter] = useState<LocationFilter[]>([]);
  const currentUser: any = Parse.User.current(); // current user Details
  console.log("currentUser", currentUser);

  const getWidgetsData = (
    // locationId: string,
    locationType: string,
    currentType: string,
    date: string,
    startDate: string | Date,
    endDate: string | Date
  ) => {
    const ChargeSession = Parse.Object.extend("ChargeSession");
    const parseQuery = new Parse.Query(ChargeSession);
    parseQuery.include("ChargePoint");

    if (userIds.length > 0) {
      parseQuery.containedIn("User", userIds);
    }

    if (locationIds.length > 0) {
      parseQuery.containedIn("Location", locationIds);
    }

    //  Check the current is super admin or not if user is super admin than all Charge
    //  session data would be showed otherwise only user CPO Charge session data would
    //  be shown
    if (currentUser) {
      // The current user must be super admin to view all Charge session data
      var innerQuery = new Parse.Query("Chargers");
      innerQuery.equalTo("CPO", currentUser.get("CPO"));
      if (!JSON.parse(userDetail).isSuperAdmin) {
        parseQuery.matchesQuery("ChargePoint", innerQuery);
      } else {
        parseQuery.matchesQuery("ChargePoint", innerQuery);
      }
    }

    if (locationType) {
      var innerQuery = new Parse.Query("Locations");
      innerQuery.equalTo("LocationType", locationType);
      parseQuery.matchesQuery("Location", innerQuery);
    }

    if (currentType) {
      var innerQuery = new Parse.Query("Chargers");

      var moreInnerQuery = new Parse.Query("ConnectorTypes");
      moreInnerQuery.equalTo("CurrentType", currentType);

      innerQuery.matchesQuery("ConnectorType", moreInnerQuery);
      parseQuery.matchesQuery("ChargePoint", innerQuery);
    }
    if (date) {
      if (date === "Today") {
        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment().startOf("day").toString())
        );
        parseQuery.lessThanOrEqualTo(
          "createdAt",
          new Date(moment().add(1, "day").startOf("day").toString())
        );
      } else if (date === "This Week") {
        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment().startOf("week").toString())
        );
        parseQuery.lessThanOrEqualTo(
          "createdAt",
          new Date(moment().endOf("week").toString())
        );
      } else if (date === "This Month") {
        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment().startOf("month").toString())
        );
        parseQuery.lessThanOrEqualTo(
          "createdAt",
          new Date(moment().endOf("month").toString())
        );
      } else if (date === "This Year") {
        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment().startOf("year").toString())
        );
        parseQuery.lessThanOrEqualTo(
          "createdAt",
          new Date(moment().endOf("year").toString())
        );
      }
    }

    if (startDateFilter) {
      // Apply single date filter
      parseQuery.greaterThanOrEqualTo(
        "createdAt",
        new Date(moment(startDateFilter).startOf("day").toString())
      );
      parseQuery.lessThanOrEqualTo(
        "createdAt",
        new Date(moment(startDateFilter).endOf("day").toString())
      );
    }
    if (startDateFilter && endDateFilter) {
      parseQuery.greaterThanOrEqualTo("createdAt", new Date(startDateFilter));
      parseQuery.greaterThanOrEqualTo("createdAt", new Date(startDateFilter));
      const adjustedEndDate = moment(endDateFilter).endOf("day").toDate();
      parseQuery.lessThanOrEqualTo("createdAt", adjustedEndDate);
    }

    parseQuery.include("Location");
    parseQuery.ascending("createdAt");
    parseQuery.limit(5000);
    parseQuery.find().then((result) => {
      type Person = {
        name: string;
        Revenue: any;
      };
      var dateArray: any[] = [];
      var tempData: Person[] = [];
      var lineData: any = [];
      let totalEnergyCost = 0;

      let totalMoney = 0;
      let thisMonthMoney = 0;
      let lastMonthMoney = 0;
      let totalSession = 0;
      let totalEnergyConsumed = 0;
      let totalElectricityTarrif = 0;

      result.forEach((item, index) => {
        let dateM = item.createdAt;

        let dateValue = moment(dateM).format("MMM YY");
        if (date === "Today") {
          dateValue = moment(dateM).format("MMM YY hh:mm A");
        }
        if (date === "This Week") {
          dateValue = moment(dateM).format("DD MMM YY ");
        }
        if (date === "This Month") {
          dateValue = moment(dateM).format("DD MMM YY ");
        }
        let currentRevenue = item.get("TotalCost") ? item.get("TotalCost") : 0;
        let currentEnergyCost = item.get("TotalEnergyConsumed")
          ? item.get("TotalEnergyConsumed") *
            item.get("Location").get("ElectricityTariff")
          : 0;

        let currentMargin = currentRevenue - currentEnergyCost;
        if (!dateArray.includes(dateValue)) {
          dateArray.push(dateValue);
          let newItem = {
            name: dateValue,
            Revenue: parseFloat(currentRevenue.toFixed(2)),
          };
          let oldItem = {
            name: dateValue,
            Revenue: parseFloat(currentRevenue.toFixed(2)),
            Energycost: parseFloat(currentEnergyCost.toFixed(2)),
            Margin: currentMargin,
          };
          tempData.push(newItem);
          lineData.push(oldItem);
        } else {
          tempData.forEach((el: { name: string; Revenue: any }) => {
            if (el.name === dateValue) {
              var sessions = el.Revenue;
              el.Revenue = parseFloat((sessions + currentRevenue).toFixed(2));
            } else {
              return;
            }
          });
          lineData.forEach(
            (el: {
              name: string;
              Revenue: any;
              Energycost: any;
              Margin: any;
            }) => {
              if (el.name === dateValue) {
                var sessions = el.Revenue;
                var energy = el.Energycost;
                var gross = el.Margin;
                el.Revenue = parseFloat((sessions + currentRevenue).toFixed(2));
                el.Energycost = parseFloat(
                  (energy + currentEnergyCost).toFixed(2)
                );
                el.Margin = parseFloat((gross + currentMargin).toFixed(2));
              } else {
                return;
              }
            }
          );
        }

        totalEnergyCost = item.get("TotalEnergyConsumed")
          ? item.get("TotalEnergyConsumed") *
              item.get("Location").get("ElectricityTariff") +
            totalEnergyCost
          : 0 + totalEnergyCost;
        totalMoney = item.get("TotalCost")
          ? item.get("TotalCost") + totalMoney
          : 0 + totalMoney;
        totalElectricityTarrif = item.get("Location").get("ElectricityTariff")
          ? item.get("Location").get("ElectricityTariff") +
            totalElectricityTarrif
          : 0 + totalElectricityTarrif;
        totalSession = totalSession + 1;
        totalEnergyConsumed = item.get("TotalEnergyConsumed")
          ? totalEnergyConsumed + item.get("TotalEnergyConsumed")
          : totalEnergyConsumed;
      });
      setLineChartData(lineData);
      console.log("tempData", tempData);

      setChartData(tempData);
      setAvRevenue(totalMoney / totalSession);
      setEnergyCost(totalEnergyCost);

      setLandCost({
        thisMonth: 0,
        lastMonth: 0,
        total: totalMoney / totalEnergyConsumed,
      });
      setGrossMargin(totalMoney - totalEnergyCost);

      setBilledRevenue({
        thisMonth: thisMonthMoney,
        lastMonth: lastMonthMoney,
        total: totalMoney,
      });

      getLandCost(
        //  locationIds,
        locationType,
        date,
        startDate,
        endDate,
        totalMoney,
        totalEnergyCost
      );
    });
  };
  // Filters Data
  const [allLocations, setAllLocations] = useState<any | null>([]);
  const [allUsers, setAllUsers] = useState<any | null>([]);
  const [allLocationType, setAllLocationType] = useState<any | null>([]);
  const [allOperators, setAllOperators] = useState<any | null>([]);
  // const [locationFilter, setLocationFilter] = useState({ id: "", label: "" });

  const [isFilterVisible, setIsFilterVisible] = useState(false); // To open Filter
  const getAllCPO = () => {
    const Vendors = Parse.Object.extend("ChargePointOperators");
    const parseQuery = new Parse.Query(Vendors);

    parseQuery.limit(50);
    parseQuery.find().then((result) => {
      let vendorsArray: any = [];
      result.forEach((item) => {
        let ab = {
          id: item.id,
          label: item.get("Name"),
        };
        vendorsArray.push(ab);
      });
      setAllOperators(vendorsArray);
    });
  };

  useEffect(() => {
    getAllCPO();
  }, []);
  const getLandCost = (
    // locationId: string,
    locationType: string,
    date: string,
    startDate: string | Date,
    endDate: string | Date,
    totalRevenue: Number | any,
    totalEnergyCost: Number | any
  ) => {
    const Locations = Parse.Object.extend("Locations");
    const parseQuery = new Parse.Query(Locations);
    // if (locationIds) {
    //   parseQuery.containedIn("objectId", locationIds);
    // }
    if (locationIds.length > 0) {
      parseQuery.containedIn("Location", locationIds);
    }
    if (locationType) {
      parseQuery.equalTo("LocationType", locationType);
    }

    parseQuery.limit(50);
    parseQuery.find().then((result) => {
      let addressArray: optionObj[] = [];
      let locationType: any[] = [];
      let totalLandCost = 0;
      let avLandCost = 0;
      result.forEach((item) => {
        if (item.get("RevenueModel") === "Rental") {
          if (date) {
            if (date === "Today" && item.get("RentalAmount")) {
              totalLandCost = item.get("RentalAmount") + totalLandCost;
              avLandCost =
                totalLandCost /
                new Date(
                  new Date().getFullYear(),
                  new Date().getMonth() + 1,
                  0
                ).getDate();
            } else if (date === "This Week" && item.get("RentalAmount")) {
              var currDate = moment.now();

              var result = moment(currDate).diff(
                new Date(
                  new Date().setDate(new Date().getDate() - new Date().getDay())
                ),
                "days"
              );

              totalLandCost = item.get("RentalAmount") + totalLandCost;
              avLandCost =
                (totalLandCost /
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 1,
                    0
                  ).getDate()) *
                result;
            } else if (date === "This Month") {
              totalLandCost = item.get("RentalAmount") + totalLandCost;
              var currDate = moment.now();

              var result = moment(currDate).diff(
                new Date(
                  new Date(new Date().getFullYear(), new Date().getMonth(), 1)
                ),
                "days"
              );
              avLandCost =
                (totalLandCost /
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 1,
                    0
                  ).getDate()) *
                result;
            } else if (date === "This Year") {
              var currDate = moment.now();
              totalLandCost = item.get("RentalAmount") + totalLandCost;
              var result = moment(currDate).diff(
                new Date(new Date(new Date().getFullYear(), 0, 1)),
                "days"
              );
              avLandCost =
                (totalLandCost /
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 1,
                    0
                  ).getDate()) *
                result;
            } else if (date === "Custom") {
              if (startDate && endDate) {
                var currDate = moment.now();
                totalLandCost = item.get("RentalAmount") + totalLandCost;
                var result = moment(endDate).diff(new Date(startDate), "days");

                avLandCost =
                  (totalLandCost /
                    new Date(
                      new Date().getFullYear(),
                      new Date().getMonth() + 1,
                      0
                    ).getDate()) *
                  result;
              } else {
                avLandCost = 0;
              }
            }
          }
        } else if (item.get("RevenueModel") === "Shared") {
          avLandCost = 0;
        }
      });
    });
  };

  let userDetail: any = sessionStorage.getItem("user-details");
  const getAllLocations = () => {
    const chargersQuery = new Parse.Query("Chargers");
    if (currentUser) {
      chargersQuery.equalTo("CPO", currentUser.get("CPO"));
    }

    chargersQuery.find().then((chargeResult) => {
      let locArray: any = [];
      chargeResult.map((chargePoint) => {
        if (!locArray.includes(chargePoint.get("Location").id)) {
          locArray.push(chargePoint.get("Location").id);
        }
      });

      const locationQuery = new Parse.Query("Locations");
      !JSON.parse(userDetail).isSuperAdmin &&
        locationQuery.containedIn("objectId", locArray);
      locationQuery.find().then((result) => {
        let addressArray: any[] = [];

        result.forEach((item) => {
          let locPoint = item.get("GeoLocation");
          addressArray.push({
            id: item.id,
            label: item.get("Name"),
          });
          setAllLocations(addressArray);
        });
      });
    });
  };

  const getAllLocationTypes = () => {
    const Locations = Parse.Object.extend("Locations");
    const parseQuery = new Parse.Query(Locations);

    parseQuery.limit(50);
    parseQuery.find().then((result) => {
      let addressArray: optionObj[] = [];
      let locationType: any[] = [];

      result.forEach((item) => {
        if (
          !locationType.find(function (i) {
            return i === item.get("LocationType");
          })
        ) {
          locationType.push(item.get("LocationType"));
        }
        addressArray.push({
          id: item.id,
          label: item.get("Name"),
        });
      });

      setAllLocationType(locationType);
    });
  };

  const [tableData, setTableData] = useState<any>([]);

  const capitalizeFirstLetterOfEachWord = (str:any) => {
    // return str.replace(/\b\w/g, (char:any) => char.toUpperCase());
    return str
    .toLowerCase()
    .replace(/\b\w/g, (char:any) => char.toUpperCase());
  };

  useEffect(() => {
    const getUsers = async () => {
        const parseQuery = new Parse.Query("ChargeSession");
        parseQuery.include("User");
        parseQuery.limit(5000);
        const result = await parseQuery.find();

        let usersMap = new Map();
        const ocppPromises = result.map(async (item) => {
            const user = item.get("User");
            if (user && !usersMap.has(user.id)) {
                usersMap.set(user.id, {
                    id: user.id,
                    label: user.get("FullName")
                });
            }
        });

        await Promise.all(ocppPromises);

        setAllUsers(Array.from(usersMap.values()));
    };

    getUsers();
}, []);

  const loadSessions = (
    // userId: string,
    // locationId: string,
    locationType: string,
    currentType: string,
    date: string,
    startDate: string | Date,
    endDate: string | Date
  ) => {
    if (dateFilter === "This Year") {
      setDateFilterChips([{ id: "This Year", label: "This Year" }]);
    }

    const parseQuery = new Parse.Query("ChargeSession");
    parseQuery.include("ChargePoint");

    if (dateFilter !== "This Year") {
      parseQuery.limit(5000);
      // setDateFilterChips([]);
    }

    if (userIds.length > 0) {
      parseQuery.containedIn("User", userIds);
    }

    if (locationIds.length > 0) {
      parseQuery.containedIn("Location", locationIds);
    }

    if (currentUser) {
      var innerQuery = new Parse.Query("Chargers");
      innerQuery.equalTo("CPO", currentUser.get("CPO"));

      if (!JSON.parse(userDetail).isSuperAdmin) {
        parseQuery.matchesQuery("ChargePoint", innerQuery);
      } else {
        parseQuery.matchesQuery("ChargePoint", innerQuery);
      }
    }

    // This code is working Cpo login and Admin login when we apply filter
    if (currentType) {
      var innerQuery = new Parse.Query("Chargers");

      var moreInnerQuery = new Parse.Query("ConnectorTypes");
      moreInnerQuery.equalTo("CurrentType", currentType);

      innerQuery.matchesQuery("ConnectorType", moreInnerQuery);
      parseQuery.matchesQuery("ChargePoint", innerQuery);
    }
    if (locationType) {
      var innerQuery = new Parse.Query("Locations");
      innerQuery.equalTo("LocationType", locationType);
      parseQuery.matchesQuery("Location", innerQuery);
    }

    if (startDateFilter) {
      // Apply single date filter
      parseQuery.greaterThanOrEqualTo(
        "createdAt",
        new Date(moment(startDateFilter).startOf("day").toString())
      );
      parseQuery.lessThanOrEqualTo(
        "createdAt",
        new Date(moment(startDateFilter).endOf("day").toString())
      );
    }
    if (startDateFilter && endDateFilter) {
      parseQuery.greaterThanOrEqualTo("createdAt", new Date(startDateFilter));
      parseQuery.greaterThanOrEqualTo("createdAt", new Date(startDateFilter));
      const adjustedEndDate = moment(endDateFilter).endOf("day").toDate();
      parseQuery.lessThanOrEqualTo("createdAt", adjustedEndDate);
    }

    if (dateFilter === "This Week") {
      parseQuery.greaterThanOrEqualTo(
        "createdAt",
        new Date(moment().startOf("week").toString())
      );
      parseQuery.lessThanOrEqualTo(
        "createdAt",
        new Date(moment().endOf("week").toString())
      );
    } else if (dateFilter === "Today") {
      parseQuery.greaterThanOrEqualTo(
        "createdAt",
        new Date(moment().startOf("day").toString())
      );
      parseQuery.lessThanOrEqualTo(
        "createdAt",
        new Date(moment().add(1, "day").startOf("day").toString())
      );
    } else if (dateFilter === "This Month") {
      parseQuery.greaterThanOrEqualTo(
        "createdAt",
        new Date(moment().startOf("month").toString())
      );
      parseQuery.lessThanOrEqualTo(
        "createdAt",
        new Date(moment().endOf("month").toString())
      );
    } else if (dateFilter === "This Year") {
      parseQuery.greaterThanOrEqualTo(
        "createdAt",
        new Date(moment().startOf("year").toString())
      );
      parseQuery.lessThanOrEqualTo(
        "createdAt",
        new Date(moment().endOf("year").toString())
      );
    }

    parseQuery.include("ChargePoint");
    parseQuery.include("Location");
    parseQuery.include("Vehicle");
    parseQuery.include("User");
    parseQuery.descending("createdAt");

    parseQuery.limit(1000);
    parseQuery.find().then((result: any[]) => {
      let newRow: {
        id: number;
        status: string;
        startTime: string;
        endTime: string;
        chargeBy: string;
        chargeFor: string;
        customer: string;
        location: string;
        carCharged: string;
        aid: string;
        date: string;
        cost: string;
        energy: string;
        duration: string;
        obj: any;
        serialNum: string;
        power: string;
        connector: string;
      }[] = [];

      let usersArray: any[] = [];

      result.forEach((item, index) => {
        let car = "";
        if (`${item.get("Vehicle")}` !== "undefined") {
          car = `${item.get("Vehicle").get("Name")}`;
        }

        const userObj = {
          id: `${item.get("User").id}`,
          label: `${item.get("User").get("FullName")}`,
        };

        if (
          !usersArray.find(function (i) {
            return i.id === userObj.id;
          })
        ) {
          usersArray.push(userObj);
        }

        newRow.push({
          id: index + 1,
          status: `${item.get("User").get("FullName")}`,
          customer: `${item.get("User").get("FullName")}`,
          location: `${item.get("Location").get("Name")}`,
          serialNum: `${item.get("ChargePoint").get("Serial")}`,
          power: `${item.get("ChargePoint").get("Power")}`,
          connector: `${item.get("ChargePoint").get("Connector")}`,
          startTime: `${
            moment(item.get("createdAt")).format("lll")
              ? moment(item.get("createdAt")).format("lll")
              : "-"
          }`,
          endTime: `${
            moment(item.get("updatedAt")).format("lll")
              ? moment(item.get("updatedAt")).format("lll")
              : "-"
          }`,
          carCharged: car,
          chargeBy: `${item.get("ChargeBy")}`,
          chargeFor: `${item.get("ChargeFor")}`,
          duration: `${
            moment
              .duration(
                moment(new Date(item.get("updatedAt"))).diff(
                  moment(new Date(item.get("createdAt")))
                )
              )
              .hours() +
            "hr" +
            " " +
            moment
              .duration(
                moment(new Date(item.get("updatedAt"))).diff(
                  moment(new Date(item.get("createdAt")))
                )
              )
              .minutes() +
            "min"
          }  `,
          aid: `${item.get("AID")}`,
          date: `${item.get("createdAt")}`,
          cost: `₹ ${parseFloat(item.get("TotalCost")).toFixed(2)}`,
          energy: `${parseFloat(item.get("TotalEnergyConsumed")).toFixed(
            2
          )} kWh`,
          obj: item,
        });
      });

      
      // console.log("userArray", usersArray);
      // console.log("", usersArray);

      setTableData(newRow);
      // setAllUsers(!allUsers.length ? usersArray : allUsers);
    });
  };

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                       Remove Filter Code start from here                       ||
  // ! ||--------------------------------------------------------------------------------||

  // Delete User Filter Chip Code
  const handleDeleteChip = (chipId: string) => {
    // Remove the corresponding chip from the state
    setFilterChips((prevChips) =>
      prevChips.filter((chip) => chip.id !== chipId)
    );

    // Remove the user filter
    setUserIds((prevUserIds) => prevUserIds.filter((id) => id !== chipId));

    setUserFilter((prevUserFilter: any) =>
      prevUserFilter.filter((filter: any) => filter.id !== chipId)
    );
    // console.log("userFilter in delete", userFilter);
  };

  // Delete Chip Location Filter Code
  const handleLocationDeleteChip = (chipId: string) => {
    console.log("Deleting chip with id:", chipId);

    // Remove the corresponding chip from the state
    setLocationChips((prevChips) =>
      prevChips.filter((chip) => chip.id !== chipId)
    );

    // Remove the location filter
    setLocationIds((prevUserIds) => prevUserIds.filter((id) => id !== chipId));

    setLocationFilter((prevUserFilter: any) =>
      prevUserFilter.filter((filter: any) => filter.id !== chipId)
    );
  };
  // Delete Chip for Today,This Week,This Month and This Year
  const handleDeleteDateChip = (chipId: string) => {
    // Filter out the chip with the given chipId
    const updatedChips = dateChips.filter((chip) => chip.id !== chipId);

    // Update the dateChips state with the filtered chips
    setDateFilterChips(updatedChips);

    // Also update the dateFilter state to an empty string
    setDateFilter("");
  };
  // Delete Location type filter Code
  const handleDeleteLocationTypeChip = (chipId: string) => {
    // Remove the corresponding chip from the state
    setLocationTypeChips((prevChips) =>
      prevChips.filter((chip) => chip.id !== chipId)
    );

    setLocationTypeFilter("");
    // Clear the selected status
    set_location_Type_Filter("");
  };
  // Delete Current type filter Code
  const handleDeleteCurrentTypeChip = (chipId: string) => {
    // Remove the corresponding chip from the state
    setCurrentTypeFilterChips((prevChips) =>
      prevChips.filter((chip) => chip.id !== chipId)
    );

    setLocationTypeFilter("");
    // Clear the selected status
    setCurrentTypeFilter("");
  };
  // Delete Single Date filter Code
  const handleSingleDeleteDateChip = (chipId: string) => {
    setStartDateFilter(null);
    setEndDateFilter(null);
    setSingleDateFilterChips(null);
  };
  // Delete Range Date filter Code
  const handleDateRangeDelete = () => {
    setStartDateFilter(null);
    setEndDateFilter(null);
    setDateRangeChipVisible(false);
  };

  // Show / Hide Filter Function
  const handleFilterButtonClick = () => {
    // Toggle filter visibility when user clicks on "Filters" button
    setIsFilterVisible((prev) => !prev);
  };

  const [locationChips, setLocationChips] = useState<
    { id: string; label: string }[]
  >([]);
  const [dateRangeChipVisible, setDateRangeChipVisible] =
    useState<boolean>(false);
  const [SingleDateChips, setSingleDateFilterChips] = useState<string | null>(
    null
  );
  const [locationIds, setLocationIds] = useState<any[]>([]); // Array of Location Filter
  const [operatorFilter, setOperatorFilter] = useState({ id: "", label: "" });
  const [locationTypeChips, setLocationTypeChips] = useState<any[]>([]);
  const [currentTypeChips, setCurrentTypeFilterChips] = useState<any[]>([]);
  const [location_Type_Filter, set_location_Type_Filter] = useState("");

  // const [dateFilter, setDateFilter] = useState("");
  const [startDateFilter, setStartDateFilter] = useState<any>("");
  const [endDateFilter, setEndDateFilter] = useState<any>("");
  const [userIds, setUserIds] = useState<any[]>([]);
  const [currentTypeFilter, setCurrentTypeFilter] = useState<any>("");
  const [locationTypeFilter, setLocationTypeFilter] = useState<any>("");
  useEffect(() => {
    getAllLocations();
    getAllLocationTypes();
  }, []);

  const [dateChips, setDateFilterChips] = useState<any[]>([]);

  const [openCustom, setOpenCustom] = useState<boolean>(false);
  const [isSelected, setIsSelected] = useState(false);

  // Code for apply Location Filter
  const handleLocationChange = (event: any, newValue: any) => {
    console.log("New Value", newValue);

    setLocationChips((prevChips: { id: string; label: string }[]) => {
      // Ensure that newValue is an array of objects with 'id' property
      const newChips: { id: string; label: string }[] = newValue.map(
        (user: any) => ({
          id: user.id,
          label: `Location: ${user.label}`,
        })
      );

      const filteredChips = newChips.filter(
        (chip) => !prevChips.some((prevChip) => prevChip.id === chip.id)
      );

      return [...prevChips, ...filteredChips];
    });
    setLocationFilter(newValue);
  };
  // Location Type Change Code for apply Location Type Filter
  const handleLocationTypeChange = (value: string) => {
    // Find if the selected date is already in the chips
    const existingChip = locationTypeChips.find((chip) => chip.label === value);

    // If the selected date is not in the chips, update the chips array
    if (!existingChip) {
      // Remove the previous date chip if it exists
      const updatedChips = locationTypeChips.filter(
        (chip) => chip.id !== location_Type_Filter
      );

      setLocationTypeChips([...updatedChips, { id: value, label: value }]);
    }
    setLocationTypeFilter(value);
    set_location_Type_Filter((prevValue) => (prevValue === value ? "" : value));
  };
  // Current Type Change Code for apply Current Type Filter (AC/DC)
  const handleCurrentChange = (value: string) => {
    // Find if the selected date is already in the chips
    const existingChip = currentTypeChips.find((chip) => chip.label === value);

    // If the selected date is not in the chips, update the chips array
    if (!existingChip) {
      // Remove the previous date chip if it exists
      const updatedChips = currentTypeChips.filter(
        (chip) => chip.id !== currentTypeFilter
      );

      setCurrentTypeFilterChips([...updatedChips, { id: value, label: value }]);
    }
    setCurrentTypeFilter(value);
  };

  // User Filter Code
  const handleUserChange = useCallback((event: any, newValue: any) => {
    setFilterChips((prevChips) => {
      const newChips = newValue.map((user: any) => ({
        id: user.id,
        label: `User: ${user.label}`,
      }));

      const filteredChips = newChips.filter(
        (chip: any) => !prevChips.some((prevChip) => prevChip.id === chip.id)
      );

      return [...prevChips, ...filteredChips];
    });

    setUserFilter(newValue);
  }, []);

  // Single Date Change function for apply single Date Filter
  const handleDateChange = (value: string) => {
    if (value === "Custom") {
      setIsSelected(!isSelected);
      setOpenCustom((prevOpenCustom) => !prevOpenCustom);
      setDateFilterChips([]);
      setDateFilter("");
      // Clear custom date range when selecting "Custom"
      setStartDateFilter(null);
      setEndDateFilter(null);
      setDateRangeChipVisible(false);
    } else {
      setOpenCustom(false);
      // Update the dateFilter state
      setDateFilter(value);
      // Update the dateFilterChips array based on the selected value
      const selectedChip = {
        id: value,
        label:
          value === "Today"
            ? "Today"
            : value === "This Month"
            ? "This Month"
            : value === "This Week"
            ? "This Week"
            : "This Year",
      };
      setDateFilterChips([selectedChip]);
      // Clear custom date range when selecting predefined filters
      setStartDateFilter(null);
      setEndDateFilter(null);
      setDateRangeChipVisible(false);
      setIsSelected(false);
    }
  };
  // Range Code for apply Range Date Filter
  const dateRangeChange = (dates: any) => {
    const [start, end] = dates;
    setStartDateFilter(start);
    setEndDateFilter(end);

    setDateRangeChipVisible(!!start && !!end);
    setSingleDateFilterChips(start);
  };

  useEffect(() => {
    getWidgetsData(
      // locationFilter.id,
      locationTypeFilter,
      currentTypeFilter,
      dateFilter,
      startDateFilter,
      endDateFilter
    );
    loadSessions(
      // locationFilter.id,
      locationTypeFilter,
      currentTypeFilter,
      dateFilter,
      startDateFilter,
      endDateFilter
    );
  }, [
    userIds,
    locationIds,
    locationTypeFilter,
    currentTypeFilter,
    dateFilter,
    startDateFilter,
    endDateFilter,
  ]);

  useEffect(() => {
    if (Array.isArray(userFilter)) {
      const userIds = userFilter.map((user: any) => user.id);
      // console.log("users Id", userIds);
      setUserIds(userIds);

      if (userIds.length > 0) {
        // Your logic when userFilter is an array
      }
    } else if (userFilter && typeof userFilter === "object") {
      const userId = (userFilter as { id?: string }).id;
      console.log("user Id", userId);
      setUserIds(userId ? [userId] : []);

      // Your logic when userFilter is an object
    } else {
      // Handle other cases when userFilter is not an array or object
    }

    if (Array.isArray(locationFilter)) {
      const locationIds = locationFilter.map((user: any) => user.id);

      setLocationIds(locationIds);

      if (locationIds.length > 0) {
        // Your logic when userFilter is an array
      }
    } else if (locationFilter && typeof locationFilter === "object") {
      const locationId = (locationFilter as { id?: string }).id;

      setLocationIds(locationId ? [locationId] : []);

      // Your logic when userFilter is an object
    } else {
      // Handle other cases when userFilter is not an array or object
    }
  }, [userFilter, locationFilter]);

  const CustomTooltip: React.FC<{
    active?: boolean;
    payload?: any[];
    label?: string;
  }> = ({ active, payload, label }) => {
    console.log("Active: " + active);
    console.log("Active payload: ", payload);
    console.log("Active label: " + label);

    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip p-5 w-64">
          <h3 className="font-lexend text-l font-semibold">{`${label}`}</h3>
          {payload?.map((ele, index) => (
            <span className="flex items-center" key={index}>
              <span>
                <TripOriginIcon
                  sx={{
                    width: "20px",
                    height: "20px",
                    color:
                      ele?.name === "Revenue"
                        ? "#8EB5FF"
                        : ele?.name === "Energy Cost"
                        ? "#FFA38E"
                        : "#6AD3A7",
                  }}
                />
              </span>
              <span className="font-lexend text-l ml-2">{ele?.name} :</span>{" "}
              {/* Added curly braces to render value */}
              <span className="font-lexend text-l ml-3">{ele?.value}</span>{" "}
              {/* Added curly braces to render value */}
            </span>
          ))}
        </div>
      );
    }

    return null;
  };
  const CustomTooltipForTotalRevenue: React.FC<{
    active?: boolean;
    payload?: any[];
    label?: string;
  }> = ({ active, payload, label }) => {
    console.log("Active: " + active);
    console.log("Active payload: ", payload);
    console.log("Active label: " + label);

    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip p-5 w-64">
          <h3 className="font-lexend text-l font-semibold">{`${label}`}</h3>
          {payload?.map((ele, index) => (
            <span className="flex items-center mt-3" key={index}>
              <span className="font-lexend text-l ">{ele?.name} :</span>{" "}
              {/* Added curly braces to render value */}
              <span className="font-lexend text-l ml-2">{ele?.value}</span>{" "}
              {/* Added curly braces to render value */}
            </span>
          ))}
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <div className="transaction-container">
        <div className="transaction_table_container flex">
          <div style={{ width: "100%" }}>
            <div className="flex justify-between mx-6 mt-5 ">
              <h2 className="main_heading mt-2">Revenue</h2>
              <div className="flex justify-between items-center">
                <button
                  className="rounded-full p-3 border-2 border-black-600"
                  onClick={handleFilterButtonClick}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M3 3H15V4.629C14.9999 5.02679 14.8418 5.40826 14.5605 5.6895L11.25 9V14.25L6.75 15.75V9.375L3.39 5.679C3.13909 5.40294 3.00004 5.0433 3 4.67025V3Z"
                      stroke="#111111"
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
            {/* Chips container to display chip */}
            <div className="filter-chips-container flex flex-wrap ml-4">
              {/* User Chips Code */}
              {filterChips.map((chip) => (
                <Chip
                  key={chip.id}
                  label={chip.label}
                  onDelete={() => handleDeleteChip(chip.id)}
                  variant="outlined"
                  sx={{ marginLeft: "5px", marginBottom: "10px" }}
                />
              ))}
              {/* Location Chip */}
              {locationChips.map((chip) => (
                <Chip
                  key={chip.id}
                  label={chip.label}
                  onDelete={() => handleLocationDeleteChip(chip.id)}
                  variant="outlined"
                  sx={{ marginLeft: "5px", marginBottom: "10px" }}
                />
              ))}

              {/* Location Type Chips */}
              {locationTypeChips.map((chip) => (
                <Chip
                  key={chip.id}
                  label={chip.label}
                  onDelete={() => handleDeleteLocationTypeChip(chip.id)}
                  variant="outlined"
                  sx={{ marginLeft: "5px", marginBottom: "10px" }}
                />
              ))}
              {/* Current Type Chips */}
              {currentTypeChips.map((chip) => (
                <Chip
                  key={chip.id}
                  label={chip.label}
                  onDelete={() => handleDeleteCurrentTypeChip(chip.id)}
                  variant="outlined"
                  sx={{ marginLeft: "5px", marginBottom: "10px" }}
                />
              ))}
              {/* Single Date Chip - Today,This Week , This month ,This Year */}
              {/* Single Date Chip */}
              {dateChips.map((chip) => (
                <Chip
                  key={chip.id}
                  label={chip.label}
                  onDelete={() => handleDeleteDateChip(chip.id)}
                  variant="outlined"
                  sx={{ marginLeft: "5px", marginBottom: "10px" }}
                />
              ))}
              {/* Date Range Chip Code */}
              {dateRangeChipVisible && (
                <Chip
                  label={`Date Between : ${moment(startDateFilter).format(
                    "DD-MM-YYYY"
                  )} - ${moment(endDateFilter).format("DD-MM-YYYY")}`}
                  onDelete={handleDateRangeDelete}
                  variant="outlined"
                  sx={{ marginLeft: "5px" }}
                />
              )}
              {/* Single Chip code */}
              {/* Handle range Date Chip when deleted Single Date chip shows this prevent that bug */}
              {!dateRangeChipVisible && SingleDateChips && startDateFilter && (
                <Chip
                  label={`Date : ${moment(startDateFilter).format(
                    "DD-MM-YYYY"
                  )}`}
                  onDelete={handleSingleDeleteDateChip}
                  variant="outlined"
                  sx={{ marginLeft: "5px", marginBottom: "10px" }}
                />
              )}
            </div>

            {/* Widget Section */}
            {/* <div className="flex flex-wrap md:justify-start sm:gap-2 justify-between items-center mx-7 mt-6 ">
              <Widget type="revenue" data={billedRevenue} />

              <Widget type="avRevenue" data={avRevenue} />

              <Widget type="energy" data={energyCost} />

              <Widget type="land" data={landCost} />

              <Widget type="margin" data={grossMargin} />
            </div> */}
            <div className="flex flex-wrap items-center mx-4 mt-6 gap-4 sm:gap-2">
              <div className="w-full sm:w-1/2 md:w-auto">
                <Widget type="revenue" data={billedRevenue} />
              </div>
              <div className="w-full sm:w-1/2 md:w-auto">
                <Widget type="avRevenue" data={avRevenue} />
              </div>
              <div className="w-full sm:w-1/2 md:w-auto">
                <Widget type="energy" data={energyCost} />
              </div>
              <div className="w-full sm:w-1/2 md:w-auto">
                <Widget type="land" data={landCost} />
              </div>
              <div className="w-full sm:w-1/2 md:w-auto">
                <Widget type="margin" data={grossMargin} />
              </div>
            </div>
            <div></div>
            {/* Total Revenue Graph */}
            <span>
              <p className="font-lexend mt-4 ml-12 absolute top-22 left-30 font-semibold">
                Total Revenue
              </p>

              <ResponsiveContainer
                className="border-2 border-gray-300 rounded-md m-4"
                width="94%"
                maxHeight={390}
              >
                <BarChart
                  width={300}
                  data={chartData}
                  margin={{
                    top: 55,
                    right: 30,
                    left: 20,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 1" />
                  <XAxis
                    dataKey="name"
                    tickLine={false}
                    style={{
                      fill: "rgba(0, 0, 0, 0.40)",
                      fontSize: "13px",
                      fontWeight: "600",
                      fontStyle: "normal",
                      fontFamily: "Lexend",
                      textAlign: "right",
                      lineHeight: "18px",
                      letterSpacing: "-0.12px",
                    }}
                  />
                  <YAxis
                    dataKey="Revenue"
                    tickLine={false}
                    style={{
                      fill: "rgba(0, 0, 0, 0.40)",
                      fontSize: "13px",
                      fontWeight: "600",
                      fontStyle: "normal",
                      fontFamily: "Lexend",
                      textAlign: "right",
                      lineHeight: "18px",
                      letterSpacing: "-0.12px",
                    }}
                  />
                  {/* <Tooltip /> */}
                  <Tooltip
                    wrapperStyle={{
                      backgroundColor: "#fff",
                      border: "none",
                      boxShadow: "none",
                      outline: "none",
                    }}
                    content={<CustomTooltipForTotalRevenue />}
                  />
                  <Bar
                    dataKey="Revenue"
                    fill="#1AC47D"
                    background={{ fill: "none" }}
                    width={30}
                  />
                </BarChart>
              </ResponsiveContainer>
            </span>
            {/* Cost Margin Line Graph */}
            <span>
              <p className="font-lexend mt-4 ml-14 absolute top-22 left-30 font-semibold ">
                Cost Margin Line Graph
              </p>
              <ResponsiveContainer
                className="border-2 border-gray-300 m-4 rounded-md mt-3"
                width="94%"
                maxHeight={400}
              >
                <LineChart
                  data={lineChartData}
                  margin={{
                    top: 55,
                    right: 30,
                    left: 20,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 1" />
                  <XAxis
                    tickLine={false}
                    dataKey="name"
                    style={{
                      fill: "rgba(0, 0, 0, 0.40)",
                      fontSize: "13px",
                      fontWeight: "600",
                      fontStyle: "normal",
                      fontFamily: "Lexend",
                      textAlign: "right",
                      lineHeight: "18px",
                      letterSpacing: "-0.12px",
                    }}
                  />
                  <YAxis
                    tickLine={false}
                    style={{
                      fill: "rgba(0, 0, 0, 0.40)",
                      fontSize: "13px",
                      fontWeight: "600",
                      fontStyle: "normal",
                      fontFamily: "Lexend",
                      textAlign: "right",
                      lineHeight: "18px",
                      letterSpacing: "-0.12px",
                    }}
                  />
                  {/* <Tooltip /> */}
                  <Tooltip
                    wrapperStyle={{
                      backgroundColor: "#fff",
                      border: "none",
                      boxShadow: "none",
                      outline: "none",
                    }}
                    content={<CustomTooltip />}
                  />
                  <Legend
                    width={400}
                    wrapperStyle={{
                      top: 10,
                      right: 30,
                      backgroundColor: "#f5f5f5",
                      border: "1px solid #d5d5d5",
                      borderRadius: 3,
                      lineHeight: "40px",
                    }}
                    iconSize={20} // Adjust the size of the legend icons as needed
                    content={(props) => {
                      const { payload } = props;
                      if (!payload) return null; // Handle the case when payload is undefined
                      return (
                        <ul className="flex">
                          {payload.map((entry, index) => (
                            <li
                              className="ml-5"
                              key={`item-${index}`}
                              style={{ color: entry.color }}
                            >
                              <span style={{ marginRight: "5px" }}>
                                {" "}
                                <span className="h-5 w-5">
                                  <TripOriginIcon />
                                </span>{" "}
                              </span>
                              {entry.value}
                            </li>
                          ))}
                        </ul>
                      );
                    }}
                  />

                  <Line
                    type="monotone"
                    dataKey="Revenue"
                    stroke="#8EB5FF"
                    strokeWidth={3}
                    activeDot={{ r: 6 }}
                  />

                  <Line
                    type="monotone"
                    dataKey="Energycost"
                    name="Energy Cost"
                    strokeWidth={3}
                    activeDot={{ r: 6 }}
                    stroke="#FFA38E"
                  />
                  <Line
                    type="monotone"
                    dataKey="Margin"
                    strokeWidth={3}
                    activeDot={{ r: 6 }}
                    stroke="#6AD3A7"
                  />
                </LineChart>
              </ResponsiveContainer>
            </span>
          </div>
          {/* Filter Container By Default Hidden */}
          {isFilterVisible && (
            <div className="filters lg:w-1/3 flex flex-col  border-2 border-black-400">
              <div className="filter_header z-50 sticky top-0 right-0 flex justify-between px-4 py-5 mb-2">
                <h1 className="font-semibold font-lexend text-lg">Filters</h1>
                <button
                  style={{
                    background: "#1AC47D",
                    width: "35px",
                    height: "35px",
                    padding: "5px",
                    borderRadius: "50%",
                    border: "3px solid #1AAD70",
                  }}
                  onClick={handleFilterButtonClick}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M3.33301 8.00033L6.66634 11.3337L13.333 4.66699"
                      stroke="white"
                      stroke-width="1.33333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>

              <div className="filter_main_container px-4">
                <h1 className="font-lexend text-xl font-bold ml-4">
                  Search by
                </h1>
                {/* Location Accordion  */}
                <Accordion
                  defaultExpanded
                  expanded={expanded}
                  onChange={handleExpansion}
                  slots={{ transition: Fade as AccordionSlots["transition"] }}
                  slotProps={{ transition: { timeout: 400 } }}
                  sx={{
                    "& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
                    "& .MuiAccordionDetails-root": {
                      display: expanded ? "block" : "none",
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography>
                      <h1 className="font-semibold font-lexend text-lg mb-2">
                        Location
                      </h1>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Autocomplete
                      className="mb-4"
                      freeSolo
                      id="checkboxes-tags-demo"
                      disableClearable
                      multiple
                      limitTags={-1}
                      options={allLocations}
                      // loading={loadingUsers}
                      autoHighlight
                      size="small"
                      value={locationFilter} // Set the value to control the selected Location
                      onChange={(event: any, newValue: any) => {
                        console.log("New Value", newValue);

                        setLocationChips(
                          (prevChips: { id: string; label: string }[]) => {
                            // Ensure that newValue is an array of objects with 'id' property
                            const newChips: { id: string; label: string }[] =
                              newValue.map((user: any) => ({
                                id: user.id,
                                label: `Location: ${user.label}`,
                              }));

                            const filteredChips = newChips.filter(
                              (chip) =>
                                !prevChips.some(
                                  (prevChip) => prevChip.id === chip.id
                                )
                            );

                            return [...prevChips, ...filteredChips];
                          }
                        );
                        setLocationFilter(newValue);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option: any) => option.label}
                      getOptionDisabled={(option) =>
                        filterChips.some((chip) => chip.id === option.id)
                      }
                      renderOption={(props, option) => {
                        let counter = 0;
                        const uniqueKey = `${option.id}_${
                          option.label
                        }_${counter++}`;
                        const selected = filterChips.some(
                          (chip) => chip.id === option.id
                        );
                        // console.log("selected", selected);

                        return (
                          <MenuItem key={uniqueKey} {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              checked={
                                selected ||
                                filterChips.some(
                                  (chip) => chip.id === option.id
                                )
                              }
                            />
                            <ListItemText primary={option.label} />
                          </MenuItem>
                        );
                      }}
                      renderInput={(params: any) => (
                        <TextField {...params} label="Search" />
                      )}
                    />
                  </AccordionDetails>
                </Accordion>
                {/* Location Type Accordion */}
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <h1 className="font-semibold font-lexend text-lg">
                      Location Type
                    </h1>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="flex">
                      <input
                        type="radio"
                        className="mr-3"
                        checked={locationTypeFilter === "Public Parking"}
                        onChange={() =>
                          handleLocationTypeChange("Public Parking")
                        }
                      />
                      <label htmlFor="" className="font-lexend">
                        Public Parking
                      </label>
                    </div>
                    <div className="flex">
                      <input
                        type="radio"
                        className="mr-3"
                        checked={locationTypeFilter === "Mall"}
                        onChange={() => handleLocationTypeChange("Mall")}
                      />
                      <label htmlFor="" className="font-lexend">
                        Mall
                      </label>
                    </div>
                    <div className="flex">
                      <input
                        type="radio"
                        className="mr-3"
                        checked={locationTypeFilter === "Office Building"}
                        onChange={() =>
                          handleLocationTypeChange("Office Building")
                        }
                      />
                      <label htmlFor="" className="font-lexend">
                        Office Building
                      </label>
                    </div>
                    <div className="flex">
                      <input
                        type="radio"
                        className="mr-3"
                        checked={locationTypeFilter === "Residential"}
                        onChange={() => handleLocationTypeChange("Residential")}
                      />
                      <label htmlFor="" className="font-lexend">
                        Residential
                      </label>
                    </div>
                  </AccordionDetails>
                </Accordion>
                {/* Current Type Accordion */}
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <h1 className="font-semibold font-lexend text-lg">
                      Current Type
                    </h1>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="flex">
                      <input
                        type="radio"
                        className="mr-3"
                        checked={currentTypeFilter === "AC"}
                        onChange={() => handleCurrentChange("AC")}
                      />
                      <label htmlFor="" className="font-lexend">
                        AC
                      </label>
                    </div>
                    <div className="flex">
                      <input
                        type="radio"
                        className="mr-3"
                        checked={currentTypeFilter === "DC"}
                        onChange={() => handleCurrentChange("DC")}
                      />
                      <label htmlFor="" className="font-lexend">
                        DC
                      </label>
                    </div>
                  </AccordionDetails>
                </Accordion>
                {/* User Accordion */}
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <h1 className="font-semibold font-lexend text-lg">User</h1>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* <Autocomplete
                      className="mb-4"
                      freeSolo
                      id="checkboxes-tags-demo"
                      disableClearable
                      multiple
                      limitTags={-1}
                      options={allUsers}
                      autoHighlight
                      size="small"
                      value={userFilter} // Set the value to control the selected users
                      onChange={handleUserChange}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option: any) => option.label}
                      getOptionDisabled={(option) =>
                        filterChips.some((chip) => chip.id === option.id)
                      }
                      renderOption={(props, option) => {
                        let counter = 0;
                        const uniqueKey = `${option.id}_${
                          option.label
                        }_${counter++}`;
                        const selected = filterChips.some(
                          (chip) => chip.id === option.id
                        );

                        return (
                          <MenuItem key={uniqueKey} {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              checked={
                                selected ||
                                filterChips.some(
                                  (chip) => chip.id === option.id
                                )
                              }
                            />
                            <ListItemText
                              className="font-lexend"
                              primary={option.label}
                            />
                          </MenuItem>
                        );
                      }}
                      renderInput={(params: any) => (
                        <TextField {...params} placeholder=" Search.." />
                      )}
                    /> */}
                     <Autocomplete
                className="mb-4"
                ListboxProps={{ style: { maxHeight: 210 } }}
                id="checkboxes-tags-demo"
                disableClearable
                multiple
                limitTags={-1}
                options={allUsers}
                autoHighlight
                size="small"
                value={userFilter}
                filterOptions={(options, { inputValue }) => {
                  const inputPattern = new RegExp(`^${inputValue.split(' ').map(word => `(?=.*\\b${word})`).join('')}`, 'i'); 
          
                  // Filter options based on the inputValue and ensure selected options are handled properly
                  return options.filter(option => {
                 
                    const label = option.label.toLowerCase();
                    const matchesInput = inputPattern.test(label);
                    const isDisabled = filterChips.some(chip => chip.id === option.id);
          
                    return matchesInput && !isDisabled;
                  });
                }}
                onChange={(event: any, newValue: any) => {
                  setFilterChips(
                    (prevChips: { id: string; label: string }[]) => {
                      const newChips: { id: string; label: string }[] =
                        newValue.map((user: any) => ({
                          id: user.id,
                          label: `User: ${user.label}`,
                        }));

                      const filteredChips = newChips.filter(
                        (chip) =>
                          !prevChips.some((prevChip) => prevChip.id === chip.id)
                      );

                      return [...prevChips, ...filteredChips];
                    }
                  );
                  setUserFilter(newValue);
                }}
                
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option: any) => option.label}
                getOptionDisabled={(option) =>
                  filterChips.some((chip) => chip.id === option.id)
                }

                renderOption={(props, option:any,index) => {
                 const key = `listItem-${index}-${option.id}`;
                  const selected = filterChips.some(
                    (chip) => chip.id === option.id
                  );
                  return (
                    <MenuItem {...props} key={key}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={
                          selected ||
                          filterChips.some((chip) => chip.id === option.id)
                        }
                      />
                      <Typography sx={{ fontFamily: "lexend" ,fontSize:'16px'}}>
                        {/* Formatted name function call */}
                      {capitalizeFirstLetterOfEachWord(option.label)} 
                       
                      </Typography>
                    </MenuItem>
                  );
                }}
                
                renderInput={(params: any) => (
                  <TextField {...params} placeholder="Type to search...." />
                )}
              />
                  </AccordionDetails>
                </Accordion>
                {/* Date Filter - (Today,This Week,This Month,This Year) */}
                <div className="date_filter_container mt-5 ml-4">
                  <h1 className="font-semibold font-lexend text-lg">Date</h1>
                  <div className="flex">
                    <input
                      type="radio"
                      className="mr-3"
                      checked={dateFilter === "Today"}
                      onChange={() => handleDateChange("Today")}
                    />
                    <label htmlFor="" className="font-lexend">
                      Today
                    </label>
                  </div>
                  <div className="flex">
                    <input
                      type="radio"
                      className="mr-3"
                      checked={dateFilter === "This Week"}
                      onChange={() => handleDateChange("This Week")}
                    />
                    <label htmlFor="" className="font-lexend">
                      This Week
                    </label>
                  </div>
                  <div className="flex">
                    <input
                      type="radio"
                      className="mr-3"
                      checked={dateFilter === "This Month"}
                      onChange={() => handleDateChange("This Month")}
                    />
                    <label htmlFor="" className="font-lexend">
                      This Month
                    </label>
                  </div>
                  <div className="flex">
                    <input
                      type="radio"
                      className="accent-black-500 mr-3"
                      checked={dateFilter === "This Year"}
                      onChange={() => handleDateChange("This Year")}
                    />
                    <label htmlFor="" className="font-lexend">
                      This Year
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      className="rounded-checkbox accent-black-500 mr-3"
                      checked={isSelected}
                      onChange={() => handleDateChange("Custom")}
                    />
                    <label htmlFor="" className=" font-lexend">
                      Custom Date
                    </label>
                  </div>
                </div>
                {/* When we select Custom than it will open  the date picker */}
                {isSelected && (
                  <div className="dateSec mb-4">
                    <h1 className="mt-4 mb-3 font-lexend">Custom</h1>
                    <div className="start_dateContainer mt-4">
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <MuiDatePicker
                          label="Start Date"
                          value={startDateFilter}
                          onChange={dateRangeChange}
                          disabled
                          className="mt-4"
                          renderInput={(params) => (
                            <TextField {...params} error={false} />
                          )}
                          inputFormat="DD-MM-YYYY"
                        />
                      </LocalizationProvider>
                    </div>
                    <div className="endDate_container mt-4">
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <MuiDatePicker
                          label="End Date"
                          disabled
                          value={endDateFilter}
                          onChange={(item) => {
                            setEndDateFilter(item || "");
                          }}
                          renderInput={(params) => (
                            <TextField {...params} error={false} />
                          )}
                          inputFormat="DD-MM-YYYY"
                        />
                      </LocalizationProvider>
                    </div>
                    {/* Date Range Picker Code for filtering Range date  */}
                    <div className="rangePicker_container mt-4">
                      {/* Range Date Picker */}
                      <DatePicker
                        selected={startDateFilter}
                        onChange={dateRangeChange}
                        startDate={startDateFilter}
                        endDate={endDateFilter}
                        maxDate={new Date()} // Disabled Future Date
                        selectsRange
                        inline
                        peekNextMonth
                        showMonthDropdown // month dropdown
                        showYearDropdown // year dropdown
                        dropdownMode="select"
                        minDate={new Date(2022, 0, 1)} // Min Date which user can select
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
});

export default Revenue;
