import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Stack,
} from "@mui/material";
import Select from "@mui/material/Select";
import CloseIcon from "@mui/icons-material/Close";
import { CarAddEditSchema } from "../../../ValidationSchema/ValidationSchema";

interface PropTypes {
  show: boolean;
  handleClose: any;
  type: string;
  data: any;
  refresh: any;
}
type Values = {
  id?: string,
  name: string,
  manufacturer: { id: string, name: string },
  chargeRate: number,
  range: number,
  batteryCapacity?:number,
};
interface FormErrors {
  id?: string,
  name?: string,
  manufacturer?: { id: string, name: string },
  chargeRate?: number,
  range?: number,
  batteryCapacity?:number,
}

function AddEditModal(props: PropTypes) {
  console.log("addEditData",props?.data);
  
  const initialValues = {
    id: "",
    name: "",
    manufacturer: { id: "", name: "" },
    chargeRate: "",
    range: "",
    batteryCapacity:"",
  };

  const [values, setValues] = useState(initialValues);
  
  useEffect(() => {
    if (props.show) {
      setValues({
        ...props.data,
        manufacturer: {
          id: props.data.manufacturer?.id || "",
          name: props.data.manufacturer?.name || "",
        },
      });
    }
  }, [props.show, props.data]);

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));


  
  const submitAddManufacturer = () => {
    try {
      if(values.name && values.manufacturer.name && values.chargeRate && values.range){
        let myNewObject: Parse.Object = new Parse.Object("Cars");

        myNewObject.set("Name", values.name);
        myNewObject.set("Manufacturer", values.manufacturer.name);
        myNewObject.set("ChargingRate", parseFloat(values.chargeRate));
        myNewObject.set("RangePerKW", parseFloat(values.range));
        myNewObject.set("BatteryCapacity", parseFloat(values.batteryCapacity));
        myNewObject.set("Brand", {
          __type: "Pointer",
          className: "EV_Manufacturer",
          objectId: values.manufacturer.id,
        });
    
        myNewObject.save().then((res) => {
          alert("Car added successfully");
          props.refresh();
          props.handleClose();
        });
      }else{
        alert("Please Fill all required field")
      }
    } catch (error) {
      console.log("Error",error);
    }
    
  };

  const submitEditManufacturer = () => {
    try {
      if(values.name && values.manufacturer.name && values.chargeRate && values.range){
        let myNewObject: Parse.Object = new Parse.Object("Cars");
        myNewObject.set("objectId", values.id);
        myNewObject.set("Name", values.name);
        myNewObject.set("Manufacturer", values.manufacturer.name);
        myNewObject.set("Brand", {
          __type: "Pointer",
          className: "EV_Manufacturer",
          objectId: values.manufacturer.id,
        });
        myNewObject.set("ChargingRate", parseFloat(values.chargeRate));
        myNewObject.set("RangePerKW", parseFloat(values.range));
        myNewObject.set("BatteryCapacity", parseFloat(values.batteryCapacity));
        myNewObject.save().then((res) => {
          alert("Car Edited successfully");
          props.refresh();
          props.handleClose();
        });
      }else{
        alert("Please Fill all required field")
      }
    } catch (error) {
        console.log("Error",error);
    }
   
  };

  const [allManufacturer, setAllManufaturer] = useState<any>([]);

// ! ||--------------------------------------------------------------------------------||
// ! ||                          Function to get vehicle data                          ||
// ! ||--------------------------------------------------------------------------------||
  const getAllVehiclesData = () => {
    const parseQuery = new Parse.Query("EV_Manufacturer");

    parseQuery.limit(100);
    parseQuery.find().then((result: any[]) => {
      let newRow: {
        id: number;
        name: string;
      }[] = [];

      result.forEach((item, index) => {
        let url = "/images/placeholder.png";
        let file = item.get("Logo");
        if (file != null) {
          url = file.url();
        }
        let logoUrl = "/images/placeholder.png";
        let logoFile = item.get("ManufacturerLogo");
        if (logoFile != null) {
          logoUrl = logoFile.url();
        }

        newRow.push({
          id: item.id,
          name: item.get("Name"),
        });
      });
      setAllManufaturer(newRow);
    });
  };
 
  useEffect(() => {
    getAllVehiclesData();
  }, []);
 
  return (
    <Dialog
      PaperProps={{
        sx: {
          width: 700,
          height: 400,
        },
      }}
      maxWidth={"md"}
      fullScreen={fullScreen}
      open={props.show}
      onClose={props.handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <div className="headItem">
          <span className="font-lexend text-xl">
            {" "}
            {props.type == "add" ? "Add Car" : "Edit Car"}
          </span>{" "}
          <span className="head2" onClick={props.handleClose}>
            <CloseIcon />
          </span>
        </div>
      </DialogTitle>

      <DialogContent>
        <Stack direction="column" spacing={1}>
          <label className="flex items-center mb-2" htmlFor="station name">
            <span>
              <svg
                width="16"
                height="12"
                viewBox="0 0 16 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.75 9.75C2.75 10.1478 2.90804 10.5294 3.18934 10.8107C3.47064 11.092 3.85218 11.25 4.25 11.25C4.64782 11.25 5.02936 11.092 5.31066 10.8107C5.59196 10.5294 5.75 10.1478 5.75 9.75M2.75 9.75C2.75 9.35218 2.90804 8.97064 3.18934 8.68934C3.47064 8.40804 3.85218 8.25 4.25 8.25C4.64782 8.25 5.02936 8.40804 5.31066 8.68934C5.59196 8.97064 5.75 9.35218 5.75 9.75M2.75 9.75H1.25V5.25M5.75 9.75H10.25M10.25 9.75C10.25 10.1478 10.408 10.5294 10.6893 10.8107C10.9706 11.092 11.3522 11.25 11.75 11.25C12.1478 11.25 12.5294 11.092 12.8107 10.8107C13.092 10.5294 13.25 10.1478 13.25 9.75M10.25 9.75C10.25 9.35218 10.408 8.97064 10.6893 8.68934C10.9706 8.40804 11.3522 8.25 11.75 8.25C12.1478 8.25 12.5294 8.40804 12.8107 8.68934C13.092 8.97064 13.25 9.35218 13.25 9.75M13.25 9.75H14.75V6.75C14.75 6.35218 14.592 5.97064 14.3107 5.68934C14.0294 5.40804 13.6478 5.25 13.25 5.25H12.5M1.25 5.25L2.75 1.5H9.5L12.5 5.25M1.25 5.25H12.5M8 5.25V1.5"
                  stroke="#111111"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>{" "}
            <span className="font-lexend ml-2">Model Name</span><span className="text-red-500 text-l ml-1">*</span>
          </label>

          <TextField
            id="input-with-sx"
            variant="standard"
            placeholder="Enter Model name"
            value={values.name}
            onChange={(e) =>
              setValues({
                ...values,
                name: e.target.value,
              })
            }
          />

          <label className="flex items-center mb-2" htmlFor="station name">
            <span>
              <svg
                width="16"
                height="18"
                viewBox="0 0 16 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.9062 4.70219C14.164 4.84879 14.3781 5.06142 14.5264 5.31821C14.6747 5.575 14.7519 5.86666 14.75 6.16319V11.6262C14.75 12.2329 14.4177 12.7924 13.8815 13.0872L8.819 16.2897C8.56802 16.4275 8.28632 16.4997 8 16.4997C7.71368 16.4997 7.43198 16.4275 7.181 16.2897L2.1185 13.0872C1.85612 12.9438 1.63709 12.7326 1.48431 12.4755C1.33152 12.2185 1.2506 11.9252 1.25 11.6262V6.16244C1.25 5.55569 1.58225 4.99694 2.1185 4.70219L7.181 1.71719C7.4394 1.57472 7.72967 1.5 8.02475 1.5C8.31983 1.5 8.6101 1.57472 8.8685 1.71719L13.931 4.70219H13.9062Z"
                  stroke="#354052"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.625 7.06619C10.859 7.20119 11.0023 7.45244 11 7.72319V10.1809C11 10.4539 10.8523 10.7059 10.6138 10.8387L8.36375 12.2802C8.25245 12.3419 8.12727 12.3743 8 12.3743C7.87273 12.3743 7.74755 12.3419 7.63625 12.2802L5.38625 10.8387C5.26904 10.7737 5.17138 10.6785 5.10345 10.5629C5.03553 10.4474 4.9998 10.3157 5 10.1817V7.72319C5 7.45019 5.14775 7.19819 5.3855 7.06544L7.6355 5.72294C7.86875 5.59244 8.153 5.59244 8.3855 5.72294L10.6355 7.06544H10.625V7.06619Z"
                  stroke="#354052"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>{" "}
            <span className="font-lexend ml-2">Manufacturer <span className="text-red-500 text-l ml-1">*</span></span>
          </label>

          <FormControl variant="standard">
            <Select
              defaultValue="Select"
              inputProps={{ "aria-label": "Without label" }}
              sx={{ width: 650 }}
              
              value={values?.manufacturer?.name}
              onChange={(event) => {
                const selectedManufacturer = allManufacturer.find(
                  (item: any) => item.id === event.target.value
                );
                if (selectedManufacturer) {
                  setValues({
                    ...values,
                    manufacturer: selectedManufacturer,
                  });
                }
              }}
              renderValue={(selected) => {
                if (!selected) {
                  return <em>Placeholder</em>;
                }
                return selected; // Assuming selected is the manufacturer object
              }}
            >
              <MenuItem disabled value="">
                <em>Manufacturer</em>
              </MenuItem>
              {allManufacturer.map((item: any) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <br />
          <Stack direction="row" justifyContent='space-between' spacing={1}>
            <FormControl variant="standard" sx={{ width: '33%' }}>
              <label className="flex items-center mb-2" htmlFor="station name">
                <span>
                  <svg
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 2.75C2 2.35218 2.15804 1.97064 2.43934 1.68934C2.72064 1.40804 3.10218 1.25 3.5 1.25H11.75C12.1478 1.25 12.5294 1.40804 12.8107 1.68934C13.092 1.97064 13.25 2.35218 13.25 2.75V3.125C13.25 3.22446 13.2895 3.31984 13.3598 3.39017C13.4302 3.46049 13.5255 3.5 13.625 3.5C13.7245 3.5 13.8198 3.53951 13.8902 3.60983C13.9605 3.68016 14 3.77554 14 3.875V6.125C14 6.22446 13.9605 6.31984 13.8902 6.39017C13.8198 6.46049 13.7245 6.5 13.625 6.5C13.5255 6.5 13.4302 6.53951 13.3598 6.60983C13.2895 6.68016 13.25 6.77554 13.25 6.875V7.25C13.25 7.64782 13.092 8.02936 12.8107 8.31066C12.5294 8.59196 12.1478 8.75 11.75 8.75H8.375M3.5 12.5V10.25M2 7.25V5.375M5 7.25V5.375M1.25 7.25H5.75V8.75C5.75 9.14782 5.59196 9.52936 5.31066 9.81066C5.02936 10.092 4.64782 10.25 4.25 10.25H2.75C2.35218 10.25 1.97064 10.092 1.68934 9.81066C1.40804 9.52936 1.25 9.14782 1.25 8.75V7.25Z"
                      stroke="#354052"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>{" "}
                <span className="font-lexend ml-2">Charging Rate <span className="text-red-500 text-l ml-1">*</span></span>
              </label>
              <TextField
                id="input-with-sx"
                variant="standard"
                placeholder="Enter Charging Rate      /kWh"
                value={values.chargeRate}
                onChange={(e) =>
                  setValues({
                    ...values,
                    chargeRate: e.target.value,
                  })
                }
              />
            </FormControl>
            <FormControl variant="standard" sx={{ width: "33%" }}>
              <label className="flex items-center mb-2" htmlFor="station name">
                <span>
                  <svg
                    width="14"
                    height="10"
                    viewBox="0 0 14 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 1.25H10.75C11.1478 1.25 11.5294 1.40804 11.8107 1.68934C12.092 1.97064 12.25 2.35218 12.25 2.75V3.125C12.25 3.22446 12.2895 3.31984 12.3598 3.39017C12.4302 3.46049 12.5255 3.5 12.625 3.5C12.7245 3.5 12.8198 3.53951 12.8902 3.60983C12.9605 3.68016 13 3.77554 13 3.875V6.125C13 6.22446 12.9605 6.31984 12.8902 6.39017C12.8198 6.46049 12.7245 6.5 12.625 6.5C12.5255 6.5 12.4302 6.53951 12.3598 6.60983C12.2895 6.68016 12.25 6.77554 12.25 6.875V7.25C12.25 7.64782 12.092 8.02936 11.8107 8.31066C11.5294 8.59196 11.1478 8.75 10.75 8.75H9.25M4 1.25H2.5C2.10218 1.25 1.72064 1.40804 1.43934 1.68934C1.15804 1.97064 1 2.35218 1 2.75V7.25C1 7.64782 1.15804 8.02936 1.43934 8.31066C1.72064 8.59196 2.10218 8.75 2.5 8.75H3.25M7 2L5.5 5H7.75L6.25 8"
                      stroke="#354052"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>{" "}
                <span className="font-lexend ml-2">Range <span className="text-red-500 text-l ml-1">*</span></span>
              </label>
              <TextField
                id="input-with-sx"
                variant="standard"
                placeholder="Enter Range                KM"
                value={values.range}
                onChange={(e) =>
                  setValues({
                    ...values,
                    range: e.target.value,
                  })
                }
              />
            </FormControl>
            <FormControl variant="standard" sx={{ width: '33%' }}>
              <label className="flex items-center mb-2" htmlFor="Battery Capacity">
                <span>
                  <svg
                    width="14"
                    height="10"
                    viewBox="0 0 14 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 1.25H10.75C11.1478 1.25 11.5294 1.40804 11.8107 1.68934C12.092 1.97064 12.25 2.35218 12.25 2.75V3.125C12.25 3.22446 12.2895 3.31984 12.3598 3.39017C12.4302 3.46049 12.5255 3.5 12.625 3.5C12.7245 3.5 12.8198 3.53951 12.8902 3.60983C12.9605 3.68016 13 3.77554 13 3.875V6.125C13 6.22446 12.9605 6.31984 12.8902 6.39017C12.8198 6.46049 12.7245 6.5 12.625 6.5C12.5255 6.5 12.4302 6.53951 12.3598 6.60983C12.2895 6.68016 12.25 6.77554 12.25 6.875V7.25C12.25 7.64782 12.092 8.02936 11.8107 8.31066C11.5294 8.59196 11.1478 8.75 10.75 8.75H9.25M4 1.25H2.5C2.10218 1.25 1.72064 1.40804 1.43934 1.68934C1.15804 1.97064 1 2.35218 1 2.75V7.25C1 7.64782 1.15804 8.02936 1.43934 8.31066C1.72064 8.59196 2.10218 8.75 2.5 8.75H3.25M7 2L5.5 5H7.75L6.25 8"
                      stroke="#354052"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>{" "}
                <span className="font-lexend ml-2">Battery Capacity</span>
              </label>
              <TextField
                id="input-with-sx"
                variant="standard"
                placeholder="Enter Battery Capacity"
                value={values.batteryCapacity}
                onChange={(e) =>
                  setValues({
                    ...values,
                    batteryCapacity: e.target.value,
                  })
                }
              />
            </FormControl>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        {/* Submit Button  */}
        <button
                className="flex justify-around items-center px-3 py-2 rounded-full mr-3 mb-5"
                style={{ background: "#1AC47D", border: "2px solid #1AAD70" }}
                onClick={() => {
                  props.type === "add"
                    ? submitAddManufacturer()
                    : submitEditManufacturer();
                }}
              >
                <span className="mr-2">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.75 9L7.5 12.75L15 5.25"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <span className="font-lexend" style={{ color: "white" }}>
                  Submit
                </span>
              </button>
       
      </DialogActions>
    </Dialog>
  );
}

export default AddEditModal;
