import React, { memo, useState, useEffect } from "react";
import "./widget.scss";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { now } from "moment";
import moment from "moment";
import { parse } from "node:path/win32";
type widgetProps = {
  type: String;

  data: any;
};

const Widget = memo((props: widgetProps) => {
  const { type, data } = props;

  let content;

  // const diff = 20;
  switch (props.type) {
    case "revenue":
      content = {
        title: "Total Consumption",
        thisMonth: data.thisMonth.toFixed(2),
        lastMonth: data.lastMonth.toFixed(2),
        total: data.total.toFixed(2) + " " + "kW",
        isMoney: false,
        link: "",
        icon: <svg className="icon" width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 1H14C14.5304 1 15.0391 1.21071 15.4142 1.58579C15.7893 1.96086 16 2.46957 16 3V3.5C16 3.63261 16.0527 3.75979 16.1464 3.85355C16.2402 3.94732 16.3674 4 16.5 4C16.6326 4 16.7598 4.05268 16.8536 4.14645C16.9473 4.24021 17 4.36739 17 4.5V7.5C17 7.63261 16.9473 7.75979 16.8536 7.85355C16.7598 7.94732 16.6326 8 16.5 8C16.3674 8 16.2402 8.05268 16.1464 8.14645C16.0527 8.24021 16 8.36739 16 8.5V9C16 9.53043 15.7893 10.0391 15.4142 10.4142C15.0391 10.7893 14.5304 11 14 11H12M5 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V9C1 9.53043 1.21071 10.0391 1.58579 10.4142C1.96086 10.7893 2.46957 11 3 11H4M9 2L7 6H10L8 10" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        ,
      };
      break;
    case "avRevenue":
      content = {
        title: "Average Energy Consumed per Session",
        thisMonth: 0,
        lastMonth: 0,
        total: data.toFixed(2) + " " + "kW",
        isMoney: false,
        link: "",
        icon: <svg  className="icon" width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 3C2 2.46957 2.21071 1.96086 2.58579 1.58579C2.96086 1.21071 3.46957 1 4 1H15C15.5304 1 16.0391 1.21071 16.4142 1.58579C16.7893 1.96086 17 2.46957 17 3V3.5C17 3.63261 17.0527 3.75979 17.1464 3.85355C17.2402 3.94732 17.3674 4 17.5 4C17.6326 4 17.7598 4.05268 17.8536 4.14645C17.9473 4.24021 18 4.36739 18 4.5V7.5C18 7.63261 17.9473 7.75979 17.8536 7.85355C17.7598 7.94732 17.6326 8 17.5 8C17.3674 8 17.2402 8.05268 17.1464 8.14645C17.0527 8.24021 17 8.36739 17 8.5V9C17 9.53043 16.7893 10.0391 16.4142 10.4142C16.0391 10.7893 15.5304 11 15 11H9.5M1 11H3.333C5.91 11 8 8.697 8 5.857V5H5.667C3.09 5 1 7.303 1 10.143V11ZM1 11V14" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
         
      };
      break;
    case "energy":
      content = {
        title: "Total Cost",
        thisMonth: 0,
        lastMonth: 0,
        total: "₹" + " " + data.toFixed(2),
        isMoney: false,
        link: "",
        icon: <svg className="icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 6H7H8C8.79565 6 9.55871 6.31607 10.1213 6.87868C10.6839 7.44129 11 8.20435 11 9C11 9.79565 10.6839 10.5587 10.1213 11.1213C9.55871 11.6839 8.79565 12 8 12H7L10 15M7 9H13M1 10C1 11.1819 1.23279 12.3522 1.68508 13.4442C2.13738 14.5361 2.80031 15.5282 3.63604 16.364C4.47177 17.1997 5.46392 17.8626 6.55585 18.3149C7.64778 18.7672 8.8181 19 10 19C11.1819 19 12.3522 18.7672 13.4442 18.3149C14.5361 17.8626 15.5282 17.1997 16.364 16.364C17.1997 15.5282 17.8626 14.5361 18.3149 13.4442C18.7672 12.3522 19 11.1819 19 10C19 8.8181 18.7672 7.64778 18.3149 6.55585C17.8626 5.46392 17.1997 4.47177 16.364 3.63604C15.5282 2.80031 14.5361 2.13738 13.4442 1.68508C12.3522 1.23279 11.1819 1 10 1C8.8181 1 7.64778 1.23279 6.55585 1.68508C5.46392 2.13738 4.47177 2.80031 3.63604 3.63604C2.80031 4.47177 2.13738 5.46392 1.68508 6.55585C1.23279 7.64778 1 8.8181 1 10Z" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      };
      break;
    case "land":
      content = {
        title: "Average Cost per kW",
        thisMonth: (50.0345).toFixed(2),
        lastMonth: (20.0345).toFixed(2),
        total: "₹" + " " + data.toFixed(2),
        icon: <svg  className="icon" width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 1H1H4C5.06087 1 6.07828 1.42143 6.82843 2.17157C7.57857 2.92172 8 3.93913 8 5C8 6.06087 7.57857 7.07828 6.82843 7.82843C6.07828 8.57857 5.06087 9 4 9H1L7 15M1 5H12" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        
      };
      break;

    case "margin":
      content = {
        title: "Gross Margin",

        total: "₹" + " " + data.toFixed(2),
        isMoney: true,
        link: "",
        icon: <PriceChangeIcon className="icon" />,
      };
      break;
    case "average":
      content = {
        title: "Avg Revenue per Session",
        thisMonth: data.thisMonth,
        lastMonth: data.lastMonth,
        total: data.total,
        isMoney: true,
        link: "",
        icon: <PriceChangeIcon className="icon" />,
      };
      break;
    default:
      break;
  }
  return (
    <div className="energyConsumption_widget_container px-4">
       <div className="flex items-center">
        <span className="icon_widget_transaction px-3">
        {content?.icon}

        </span>
        <span className="font-lexend font-semibold text-lg text-left ml-3 ">
        {content?.title}
        </span>
        </div>
      <span className="content font-lexend">{content?.total}</span>
    </div>
  );
});

export default Widget;
