import React, { memo, useState, useEffect } from "react";
import "./widget.scss";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { now } from "moment";
import moment from "moment";
import { parse } from "node:path/win32";
type widgetProps = {
  type: String;

  data: any;
};

const Widget = memo((props: widgetProps) => {
  const { type, data } = props;

  let content;

  // const diff = 20;
  switch (props.type) {
    case "revenue":
      content = {
        title: "Total Revenue",
        thisMonth: data.thisMonth.toFixed(2),
        lastMonth: data.lastMonth.toFixed(2),
        total: "₹" + " " + data.total.toFixed(2),
        isMoney: false,
        link: "",
        icon: <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 6.26953H7H8C8.79565 6.26953 9.55871 6.5856 10.1213 7.14821C10.6839 7.71082 11 8.47388 11 9.26953C11 10.0652 10.6839 10.8282 10.1213 11.3909C9.55871 11.9535 8.79565 12.2695 8 12.2695H7L10 15.2695M7 9.26953H13M1 10.2695C1 11.4514 1.23279 12.6218 1.68508 13.7137C2.13738 14.8056 2.80031 15.7978 3.63604 16.6335C4.47177 17.4692 5.46392 18.1322 6.55585 18.5844C7.64778 19.0367 8.8181 19.2695 10 19.2695C11.1819 19.2695 12.3522 19.0367 13.4442 18.5844C14.5361 18.1322 15.5282 17.4692 16.364 16.6335C17.1997 15.7978 17.8626 14.8056 18.3149 13.7137C18.7672 12.6218 19 11.4514 19 10.2695C19 9.08763 18.7672 7.91731 18.3149 6.82538C17.8626 5.73345 17.1997 4.7413 16.364 3.90557C15.5282 3.06984 14.5361 2.40691 13.4442 1.95462C12.3522 1.50232 11.1819 1.26953 10 1.26953C8.8181 1.26953 7.64778 1.50232 6.55585 1.95462C5.46392 2.40691 4.47177 3.06984 3.63604 3.90557C2.80031 4.7413 2.13738 5.73345 1.68508 6.82538C1.23279 7.91731 1 9.08763 1 10.2695Z" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        ,
      };
      break;
    case "avRevenue":
      content = {
        title: "Avg. Revenue per Session",
        thisMonth: 0,
        lastMonth: 0,
        total: "₹" + " " + data.toFixed(2),
        isMoney: false,
        link: "",
        icon: <AttachMoneyIcon className="icon" />,
      };
      break;
    case "energy":
      content = {
        title: "Energy Cost",
        thisMonth: 0,
        lastMonth: 0,
        total: "₹" + " " + data.toFixed(2),
        isMoney: false,
        link: "",
        icon: <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 1H14C14.5304 1 15.0391 1.21071 15.4142 1.58579C15.7893 1.96086 16 2.46957 16 3V3.5C16 3.63261 16.0527 3.75979 16.1464 3.85355C16.2402 3.94732 16.3674 4 16.5 4C16.6326 4 16.7598 4.05268 16.8536 4.14645C16.9473 4.24021 17 4.36739 17 4.5V7.5C17 7.63261 16.9473 7.75979 16.8536 7.85355C16.7598 7.94732 16.6326 8 16.5 8C16.3674 8 16.2402 8.05268 16.1464 8.14645C16.0527 8.24021 16 8.36739 16 8.5V9C16 9.53043 15.7893 10.0391 15.4142 10.4142C15.0391 10.7893 14.5304 11 14 11H12M5 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V9C1 9.53043 1.21071 10.0391 1.58579 10.4142C1.96086 10.7893 2.46957 11 3 11H4M9 2L7 6H10L8 10" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        ,
      };
      break;
    case "land":
      content = {
        title: "Average Charging Tariff ",
        total: data.total ? `${data.total.toFixed(2)} ₹/kW` : "-",
        thisMonth: (50.0345).toFixed(2),
        lastMonth: (20.0345).toFixed(2),
        icon: <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 4V8M7 4V8M10 4V8M3 1H14C14.5304 1 15.0391 1.21071 15.4142 1.58579C15.7893 1.96086 16 2.46957 16 3V3.5C16 3.63261 16.0527 3.75979 16.1464 3.85355C16.2402 3.94732 16.3674 4 16.5 4C16.6326 4 16.7598 4.05268 16.8536 4.14645C16.9473 4.24021 17 4.36739 17 4.5V7.5C17 7.63261 16.9473 7.75979 16.8536 7.85355C16.7598 7.94732 16.6326 8 16.5 8C16.3674 8 16.2402 8.05268 16.1464 8.14645C16.0527 8.24021 16 8.36739 16 8.5V9C16 9.53043 15.7893 10.0391 15.4142 10.4142C15.0391 10.7893 14.5304 11 14 11H3C2.46957 11 1.96086 10.7893 1.58579 10.4142C1.21071 10.0391 1 9.53043 1 9V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1Z" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        ,
      };
      break;

    case "margin":
      content = {
        title: "Gross Margin",

        total: "₹" + " " + data.toFixed(2),
        isMoney: true,
        link: "",
        icon: <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 1H1H4C5.06087 1 6.07828 1.42143 6.82843 2.17157C7.57857 2.92172 8 3.93913 8 5C8 6.06087 7.57857 7.07828 6.82843 7.82843C6.07828 8.57857 5.06087 9 4 9H1L7 15M1 5H12" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        ,
      };
      break;
    case "average":
      content = {
        title: "Avg Revenue per Session",
        thisMonth: data.thisMonth,
        lastMonth: data.lastMonth,
        total: data.total,
        isMoney: true,
        link: "",
        icon: <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 3C2 2.46957 2.21071 1.96086 2.58579 1.58579C2.96086 1.21071 3.46957 1 4 1H15C15.5304 1 16.0391 1.21071 16.4142 1.58579C16.7893 1.96086 17 2.46957 17 3V3.5C17 3.63261 17.0527 3.75979 17.1464 3.85355C17.2402 3.94732 17.3674 4 17.5 4C17.6326 4 17.7598 4.05268 17.8536 4.14645C17.9473 4.24021 18 4.36739 18 4.5V7.5C18 7.63261 17.9473 7.75979 17.8536 7.85355C17.7598 7.94732 17.6326 8 17.5 8C17.3674 8 17.2402 8.05268 17.1464 8.14645C17.0527 8.24021 17 8.36739 17 8.5V9C17 9.53043 16.7893 10.0391 16.4142 10.4142C16.0391 10.7893 15.5304 11 15 11H9.5M1 11H3.333C5.91 11 8 8.697 8 5.857V5H5.667C3.09 5 1 7.303 1 10.143V11ZM1 11V14" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        ,
      };
      break;
    default:
      break;
  }
  return (
    <div className="revenue-widget-container">
      <div className="flex items-center">
        <span className="icon_widget_transaction px-3">
        {content?.icon}

        </span>
        <span className="font-lexend font-bold text-lg text-left ml-3 ">
        {content?.title}
        </span>
        </div>
      <span className="font-lexend font-bold text-left text-xl">{content?.total}</span>
    </div>
  );
});

export default Widget;
