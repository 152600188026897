import React, { useEffect, useState, memo } from "react";
import "./cpoTable.scss";
import { DataGrid } from "@mui/x-data-grid";
import AddEditModal from "../addEditModal";
import Button from "@mui/material/Button/Button";
import { Tooltip } from "@mui/material";
interface tableProps {
  dataRow: any;
  refresh: any;
  loading: boolean;
}

const DatabaseTable = memo((props: tableProps) => {
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [addEditData, setAddEditData] = useState({});
  const { dataRow } = props;

  const columns = [
    {
      field: "sNo",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          S.NO
        </span>
      ),
      renderCell: (params: any) => {
        console.log("data param S.NO",params)
 
        return (
        <span className="font-lexend">{params?.row?.sNo}</span>
        );
      },
      width: 60,
    },
    {
      field: "companyName",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Company Name
        </span>
      ),
      renderCell: (params: any) => {
        return (
        <span className="font-lexend">{params?.row?.companyName}</span>
        );
      },
      width: 290,
      editable: true,
    },
    {
      field: "tradeName",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Trade Name
        </span>
      ),
      renderCell: (params: any) => {
        return (
        <span className="font-lexend">{params?.row?.tradeName}</span>
        );
      },
      width: 180,
      editable: true,
    },

    {
      field: "companyAddress",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Address
        </span>
      ),
      renderCell: (params: any) => {
        return (
        <span className="font-lexend">{params?.row?.companyAddress}</span>
        );
      },
      width: 410,
      editable: true,
    },

    {
      field: "contactName",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Contact Name
        </span>
      ),
      renderCell: (params: any) => {
        return (
        <span className="font-lexend">{params?.row?.contactName}</span>
        );
      },
      width: 180,
      editable: true,
    },
    {
      field: "contactNumber",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Contact Number
        </span>
      ),
      renderCell: (params: any) => {
        return (
        <span className="font-lexend">{params?.row?.contactNumber}</span>
        );
      },
      width: 180,
      editable: true,
    },
    {
      field: "contactEmail",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
         Contact Email
        </span>
      ),
      renderCell: (params: any) => {
        return (
        <span className="font-lexend">{params?.row?.contactEmail}</span>
        );
      },
      width: 250,
      editable: true,
    },
    {
      field: "companyDescription",
      headerName: "Company Description",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
         Company Description
        </span>
      ),
      renderCell: (params: any) => {
        return (
        <span className="font-lexend">{params?.row?.companyDescription}</span>
        );
      },
      width: 250,
      editable: true,
    },
    {
      field: "Edit CPO",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
        Edit CPO
        </span>
      ),
      width: 220,
      renderCell: (params: any) => {
        return (
          <Tooltip title="Edit CPO">
          <button
            style={{
              background: "#1AC47D",
              width: "35px",
              height: "35px",
              padding: "5px",
              borderRadius: "50%",
              border: "3px solid #1AAD70",
              marginLeft:'9px'
            }}
            onClick={() => {
                  setShowAddEditModal(true);
                  setAddEditData({
                    id: params.row.id,
                    companyName: params.row.companyName,
                    tradeName: params.row.tradeName,
                    companyAddress: params.row.companyAddress,
                    contactName: params.row.contactName,
                    contactNumber: params.row.contactNumber,
                    contactEmail: params.row.contactEmail,
                    companyDescription: params.row.companyDescription,
                    isWhiteLabel: params.row.isWhiteLabel,
    
                    accountName: params.row.accountName,
                    accountNumber: params.row.accountNumber,
                    bankName: params.row.bankName,
                    ifsc: params.row.ifsc,
                    upi: params.row.upi,
                    upiCode: params.row.upiCode,
                  });
                }}
          >
            <svg
              className="ml-1"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.33268 2.66635L9.33268 6.66635M0.666016 8.66635L8.46602 0.866353C8.59063 0.744201 8.75818 0.675781 8.93268 0.675781C9.10718 0.675781 9.27473 0.744201 9.39935 0.866353L11.1327 2.59969C11.2548 2.72431 11.3233 2.89185 11.3233 3.06635C11.3233 3.24085 11.2548 3.4084 11.1327 3.53302L3.33268 11.333H0.666016V8.66635Z"
                stroke="white"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </Tooltip>
        
        );
      },
    },
  ];

  return (
    <div className="booking-table mt-7">

      <DataGrid
        rows={dataRow}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[5]}
        checkboxSelection={false}
        disableSelectionOnClick
        autoHeight
        loading={props.loading}
      />
      <AddEditModal
        show={showAddEditModal}
        type="edit"
        handleClose={() => setShowAddEditModal(false)}
        refresh={() => props.refresh()}
        data={addEditData}
      />
    </div>
  );
});

export default DatabaseTable;
