import { Tooltip } from "@mui/material";
import { memo, useEffect, useState } from "react";
import AddEditModal from "./addEditModal";
import fallBackCarImage from "../../icons/vector-red-car-260nw-96458090.jpg"
import "./electricVehicle.scss";

const Ev = memo(() => {
  //Tabel
  const [tableData, setTableData] = useState<any>([]);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [addEditData, setAddEditData] = useState<any>({
    id: "",
    name: "",
    // manufacturer: "",
    manufacturer: { id: "", name: "" },
    chargeRate: "",
    range: "",
    batteryCapacity: "",
  });
  const [typeModal, setTypeModal] = useState("");

// ! ||--------------------------------------------------------------------------------||
// ! ||                           Function to get all vehicle                          ||
// ! ||--------------------------------------------------------------------------------||
  const getAllVehiclesData = () => {
    setTableLoading(true);
    const parseQuery = new Parse.Query("Cars");
    parseQuery.include("Brand");
    parseQuery.descending("Brand");
    parseQuery.limit(100);

    parseQuery
      .find()
      .then((result: any[]) => {
        let newRow: {
          id: number;
          name: string;
          imageURL: string;
          manufacturer: string;
          manufacturerID: string;
          chargeRate: string;
          rangeAdded: string;  // RangeAdded
          batteryCapacity: number; //Battery Capacity
          logoURL: string;
          obj: any;
        }[] = [];

        result.forEach((item) => {
          let url = "/images/placeholder.png";
          let file = item.get("Image");
          if (file) {
            url = file.url();
          }

          let logoUrl = "/images/placeholder.png";
          let brand = item.get("Brand");
          let brandName = brand ? brand.get("Name") : "Unknown Brand";
          let brandID = brand ? brand.id : "Unknown ID";

          if (brand && brand.get("Logo")) {
            logoUrl = brand.get("Logo").url();
          }

          newRow.push({
            id: item.id,
            name: item.get("Name") || "Unknown Name",
            imageURL: url,
            manufacturer: brandName,
            manufacturerID: brandID,
            batteryCapacity: item.get("BatteryCapacity") || "NAN",
            chargeRate: item.get("ChargingRate") || "NAN",
            rangeAdded: item.get("RangePerKW") || "NAN",
            logoURL: logoUrl,
            obj: item,
          });

          console.log("Processed car:", {
            id: item.id,
            name: item.get("Name"),
            brandName,
            brandID,
          });
        });

        setTableLoading(false);
        setTableData(newRow);
      })
      .catch((error) => {
        console.error("Error fetching car data:", error);
        setTableLoading(false);
      });
  };

  useEffect(() => {
    getAllVehiclesData();
  }, []);
  console.log("Table Data", tableData);
  console.log("addEditData", addEditData);
  // Modal Code For Edit and Add
  const handleOpenModal = (type: string, data: any) => {
    console.log("Type", type);
    console.log("Data", data);
    setTypeModal(type);
    if (type == "edit") {
      setAddEditData({
        id: data?.id,
        name: data?.name,
        manufacturer: { id: data?.manufacturerID, name: data?.manufacturer },
        chargeRate: data?.chargeRate,
        range: data?.rangeAdded,
        batteryCapacity: data?.batteryCapacity,
      });
    } else {
      setAddEditData({
        id: "",
        name: "",
        manufacturer: { id: "", name: "" },
        chargeRate: "",
        range: "",
        batteryCapacity: "",
      });
    }
    setShowAddEditModal(true);
  };
  return (
    <div className="ev-container">
      <div className="mt-5 mx-5 flex justify-between">
        <h1 className="font-lexend text-xl font-bold">Vehicles</h1>
        <div className="add-car">
          <button
            className="flex justify-around items-center w-max px-3 py-2 rounded-full"
            style={{ background: "#1AC47D", border: "2px solid #1AAD70" }}
            onClick={() => {
              handleOpenModal("add", {});
              setShowAddEditModal(true);
            }}
          >
            <span className="mr-2 ml-5 ">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.75 9H11.25M9 6.75V11.25M2.25 9C2.25 9.88642 2.42459 10.7642 2.76381 11.5831C3.10303 12.4021 3.60023 13.1462 4.22703 13.773C4.85382 14.3998 5.59794 14.897 6.41689 15.2362C7.23583 15.5754 8.11358 15.75 9 15.75C9.88642 15.75 10.7642 15.5754 11.5831 15.2362C12.4021 14.897 13.1462 14.3998 13.773 13.773C14.3998 13.1462 14.897 12.4021 15.2362 11.5831C15.5754 10.7642 15.75 9.88642 15.75 9C15.75 8.11358 15.5754 7.23583 15.2362 6.41689C14.897 5.59794 14.3998 4.85382 13.773 4.22703C13.1462 3.60023 12.4021 3.10303 11.5831 2.76381C10.7642 2.42459 9.88642 2.25 9 2.25C8.11358 2.25 7.23583 2.42459 6.41689 2.76381C5.59794 3.10303 4.85382 3.60023 4.22703 4.22703C3.60023 4.85382 3.10303 5.59794 2.76381 6.41689C2.42459 7.23583 2.25 8.11358 2.25 9Z"
                  stroke="white"
                  stroke-width="1.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span style={{ color: "white" }} className="mr-5">
              <span className="font-lexend ">Add Car</span>
            </span>
          </button>
        </div>
      </div>
      {/* Add and Edit Car Modal */}
      <AddEditModal
        show={showAddEditModal}
        handleClose={() => setShowAddEditModal(false)}
        type="add"
        data={{
          id: "",
          name: "",
          manufacturer: { id: "", name: "" },
          chargeRate: "",
          range: "",
        }}
        refresh={() => getAllVehiclesData()}
      />
      <div className="container mx-auto ">
        <div className=" mx-5 grid mt-6  items-stretch grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4">
          {tableData?.map((item: any) => (
            <div
              style={{ background: "#F6F8FA" }}
              key={item?.objectId}
              className="w-46 flex flex-col justify-between p-4"
            >
              <div className="w-18 h-18" style={{ aspectRatio: "1/1" }}>
                <img
                  style={{
                    mixBlendMode: "normal",
                    width: "100%",
                    height: "100",
                  }}
                  // src={item?.imageURL}
                  src={item?.imageURL !== "/images/placeholder.png" ? item.imageURL : fallBackCarImage}
                  
                  alt={item?.name}
                  loading="lazy"
                />
              </div>
              {/* Car Details */}
              <div className="flex justify-between">
                <div className="car_details">
                  <h1 className="font-lexend text-xl">{item?.manufacturer}</h1>
                  <h1 className="font-lexend text-l text-gray-400">
                    {item?.name}
                  </h1>
                </div>
                <div className="car_logo">
                  <div className="w-12 h-12">
                    <img
                      src={item?.logoURL}
                      alt={item?.logoURL}
                      style={{ mixBlendMode: "normal" }}
                    />
                  </div>
                </div>
              </div>

              <hr className="my-4" />

              {/* Cars Range and Charging Rate */}
              <div>
                {/* Charging Rate */}
                <div className="flex justify-between">
                  <span className="font-lexend text-l text-gray-400">
                    Charging Rate
                  </span>
                  <span className="font-lexend text-l text-gray-400">
                    {item?.chargeRate}
                  </span>
                </div>
                {/* Cars Range */}
                <div className="flex justify-between mt-1">
                  <span className="font-lexend text-l text-gray-400">
                    Range per KW
                  </span>
                  <span className="font-lexend text-l text-gray-400">
                    {parseFloat(item?.rangeAdded)?.toFixed(2)}
                  </span>
                </div>
                {/* Battery Capacity */}
                <div className="flex justify-between mt-1">
                  <span className="font-lexend text-l text-gray-400">
                    Battery Capacity
                  </span>
                  <span className="font-lexend text-l text-gray-400">
                    {parseFloat(item?.batteryCapacity? item?.batteryCapacity : "-")}
                  </span>
                </div>
              </div>

              <div className="flex justify-between mt-4">
                {/* Edit Car Button */}
                <Tooltip title="Edit Car Details">
                  <button
                    className="flex items-center justify-center"
                    onClick={() => {
                      handleOpenModal("edit", item);
                      setShowAddEditModal(true);
                    }}
                  >
                    <span>
                      <svg
                        width="13"
                        height="13"
                        viewBox="0 0 13 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.66667 2.99057L9.66667 6.99057M1 8.99057L8.8 1.19057C8.92462 1.06842 9.09216 1 9.26667 1C9.44117 1 9.60871 1.06842 9.73333 1.19057L11.4667 2.9239C11.5888 3.04852 11.6572 3.21607 11.6572 3.39057C11.6572 3.56507 11.5888 3.73262 11.4667 3.85724L3.66667 11.6572H1V8.99057Z"
                          stroke="#1877D3"
                          stroke-width="1.33333"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    <span
                      className="font-lexend ml-1"
                      style={{ color: "#1877D3" }}
                    >
                      Edit
                    </span>
                  </button>
                </Tooltip>

                {/* Edit Pic */}
                <Tooltip title="Upload Image">
                  <button
                    className="h-12 w-12  rounded-full"
                    style={{
                      cursor: "default",
                      background: "#1AC47D",
                      border: "2px solid #1AAD70",
                    }}
                  >
                    <label
                      className="flex h-full"
                      style={{ cursor: "pointer" }}
                      htmlFor={item.id}
                    >
                      <svg
                        className="mx-auto mt-3"
                        width="17"
                        height="17"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.35742 8.57171V3.33242L3.68599 5.00385L2.78599 4.07171L6.00028 0.857422L9.21456 4.07171L8.31456 5.00385L6.64314 3.33242V8.57171H5.35742ZM0.857422 11.1431V7.92885H2.14314V9.85742H9.85742V7.92885H11.1431V11.1431H0.857422Z"
                          fill="white"
                        />
                      </svg>
                    </label>
                    <input
                      accept="image/jpg, image/png, image/jpeg"
                      type="file"
                      id={item?.id}
                      style={{ display: "none" }}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const file = event.target.files?.[0];

                        if (file) {
                          const parseFile = new Parse.File("photo", file);
                          parseFile.save().then(
                            () => {
                              // Assuming item holds the Parse object ID (objectId)
                              const objectId = item.id; // Adjust this according to your actual data structure
                              if (objectId) {
                                // Retrieve the Parse object using its ID
                                const parseObject = new Parse.Query(
                                  Parse.Object.extend("Cars")
                                ).get(objectId);
                                parseObject.then(
                                  (result) => {
                                    result.set("Image", parseFile);
                                    result.save().then(
                                      () => {
                                        alert("Image Uploaded");
                                        getAllVehiclesData();
                                      },
                                      (error: any) => {
                                        alert(
                                          "Failed to save new object, with error code: " +
                                            error.message
                                        );
                                      }
                                    );
                                  },
                                  (error) => {
                                    alert(
                                      "Error fetching Parse object: " +
                                        JSON.stringify(error)
                                    );
                                  }
                                );
                              } else {
                                alert("Parse object ID not found in item.");
                              }
                            },
                            (error) => {
                              alert("Error Faced" + error);
                            }
                          );
                        }
                      }}
                    />
                  </button>
                </Tooltip>
              </div>
            </div>
          ))}
        </div>
      </div>
      <AddEditModal
        show={showAddEditModal}
        type={typeModal}
        handleClose={() => setShowAddEditModal(false)}
        refresh={() => getAllVehiclesData()}
        data={addEditData}
      />
    </div>
  );
});

export default Ev;
