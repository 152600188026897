import React, { useEffect, useState, ChangeEvent } from "react";
import PropTypes from "prop-types";
import {
  useMediaQuery,
  useTheme,
  Dialog,
  DialogContent,
  DialogTitle,
  alpha,
  InputBase,
  styled,
  Stack,
  Select,
  Checkbox,
  TextField,
  MenuItem,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

interface PropTypes {
  show: boolean;
  handleClose: any;
  type: string;
  data: any;
  refresh: any;
  allCPO: [];
}
interface FormValues {
  id: string;
  name: string;
  email: string;
  phone: string;
  cpo: string;
  access: string[];
  password: string;
}

const initialValues: FormValues = {
  id: "",
  name: "",
  email: "",
  phone: "",
  cpo: "",
  access: [],
  password: "",
};

// All Access Role for User
let allRoles = sessionStorage.getItem("roles");
console.log("All Roles: ", allRoles);

function AddEditModal(props: PropTypes) {
  const [values, setValues] = useState<FormValues>(initialValues);
  useEffect(() => {
    if (props.show && props.type == "edit") {
      setValues(props.data);
    }
  }, [props.show]);
  useEffect(() => {
    if (props.show && props.type == "add") {
      setValues(initialValues);
    }
  }, [props.show]);
  const theme = useTheme();
  console.log("Selected USer access", values.access);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const submitAddUser = () => {
    if (
      values.name &&
      values.email &&
      values.phone &&
      values.password &&
      values.access.length &&
      !error.phone &&
      !error.email
    ) {
      let myNewObject: Parse.Object = new Parse.Object("_User");
      myNewObject.set("CPO", {
        __type: "Pointer",
        className: "ChargePointOperators",
        objectId: values.cpo,
      });
      myNewObject.set("FullName", values.name);
      myNewObject.set("email", values.email);
      myNewObject.set("password", values.password);
      myNewObject.set("Phone", values.phone);
      myNewObject.set("UserType", "Cloud");
      myNewObject.set("RoleAssigned", values.access);
      myNewObject.set("username", values.email);
      myNewObject.set("allowDashboard", true);

      myNewObject
        .save()
        .then(() => {
          alert("Role Added successfully");
          props.handleClose();
          props.refresh();
        })
        .catch((error) => alert(error));
    } else {
      alert("Please Enter All Mandatory Fields");
    }
  };

  const submitEditUser = () => {
    try {
      const params = {
        userId: values.id,
        roles: values.access,
        name: values.name,
        email: values.email,
        phone: values.phone,
      };
      Parse.Cloud.run("updateUserRole", params).then(
        (status) => {
          alert("User Updated");
          props.handleClose();
          props.refresh();
        },
        (error: any) => {
          alert("Failed to add the user: " + error.message);
          props.handleClose();
          props.refresh();
        }
      );
    } catch (error: any) {
      alert("Failed to add the user " + error.message);
    }
  };

  const [error, setError] = useState({ phone: false, email: false });

  function isEmail(emailAddress: any) {
    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (emailAddress.match(regex)) {
      setError({ ...error, email: false });
    } else {
      setError({ ...error, email: true });
    }
  }

  useEffect(() => {
    if (values.email) isEmail(values.email);
  }, [values.email]);

  function phoneNumber(phoneValue: any) {
    var phoneNo = /^\d{10}$/;
    if (phoneValue.match(phoneNo)) {
      setError({ ...error, phone: false });
    } else {
      setError({ ...error, phone: true });
    }
  }
  useEffect(() => {
    if (values.phone) phoneNumber(values.phone);
  }, [values.phone]);

  const handleCheckboxChange = (
    e: ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    const checked = e.target.checked;
    setValues((prevValues) => {
      if (checked) {
        // If checkbox is checked, add the value to the array
        return {
          ...prevValues,
          access: [...prevValues.access, value],
        };
      } else {
        // If checkbox is unchecked, remove the value from the array
        return {
          ...prevValues,
          access: prevValues.access.filter((item) => item !== value),
        };
      }
    });
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          width: 900,
          height: 700,
        },
      }}
      maxWidth={"lg"}
      fullScreen={fullScreen}
      open={props.show}
      onClose={props.handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <div className="headItem">
          <span className="head1">
            {" "}
            {props.type == "add" ? (
              <span className="font-lexend font-bold ml-5">Add New Role</span>
            ) : (
              <span className="font-lexend font-bold ml-5">Edit Role</span>
            )}
          </span>{" "}
          <span className="head2" onClick={props.handleClose}>
            <CloseIcon />
          </span>
        </div>
      </DialogTitle>

      <DialogContent>
        <div></div>
        <Stack direction="column" spacing={1}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "17px",
            }}
          >
            <label
              className="flex items-center mb-2 mt-5"
              htmlFor="station name"
            >
              <span>
                <svg
                  width="15"
                  height="17"
                  viewBox="0 0 15 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.5 14.75V13.25C1.5 12.4544 1.81607 11.6913 2.37868 11.1287C2.94129 10.5661 3.70435 10.25 4.5 10.25H7.125M3 4.25C3 5.04565 3.31607 5.80871 3.87868 6.37132C4.44129 6.93393 5.20435 7.25 6 7.25C6.79565 7.25 7.55871 6.93393 8.12132 6.37132C8.68393 5.80871 9 5.04565 9 4.25C9 3.45435 8.68393 2.69129 8.12132 2.12868C7.55871 1.56607 6.79565 1.25 6 1.25C5.20435 1.25 4.44129 1.56607 3.87868 2.12868C3.31607 2.69129 3 3.45435 3 4.25ZM10.815 10.7075C10.9613 10.5612 11.1349 10.4452 11.326 10.3661C11.5171 10.2869 11.7219 10.2462 11.9288 10.2462C12.1356 10.2462 12.3404 10.2869 12.5315 10.3661C12.7226 10.4452 12.8962 10.5612 13.0425 10.7075C13.1888 10.8537 13.3048 11.0274 13.3839 11.2185C13.4631 11.4096 13.5038 11.6144 13.5038 11.8212C13.5038 12.0281 13.4631 12.2329 13.3839 12.424C13.3048 12.6151 13.1888 12.7887 13.0425 12.935L10.5 15.5H8.25V13.25L10.815 10.7075Z"
                    stroke="#111111"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>{" "}
              <span className="font-lexend ml-2">Name</span>
            </label>
            <TextField
              id="input-with-sx"
              variant="standard"
              placeholder="Enter Name"
              value={values.name}
              onChange={(e: any) =>
                setValues({
                  ...values,
                  name: e.target.value,
                })
              }
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "17px",
            }}
          >
            <label
              className="flex items-center mb-2 mt-5"
              htmlFor="station name"
            >
              <span>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.25 14.75L3.125 12.875M12.875 3.125L14.75 1.25M6.5 7.25L5 8.75M8.75 9.5L7.25 11M4.25 8L8 11.75L6.875 12.875C6.63025 13.1278 6.33763 13.3294 6.01419 13.468C5.69075 13.6066 5.34296 13.6795 4.99108 13.6824C4.6392 13.6852 4.29028 13.618 3.96463 13.4847C3.63898 13.3513 3.34313 13.1545 3.0943 12.9057C2.84548 12.6569 2.64866 12.361 2.51532 12.0354C2.38198 11.7097 2.31478 11.3608 2.31764 11.0089C2.32049 10.657 2.39335 10.3092 2.53196 9.98581C2.67057 9.66237 2.87217 9.36975 3.125 9.125L4.25 8ZM11.75 8L8 4.25L9.125 3.125C9.36975 2.87217 9.66237 2.67057 9.98581 2.53196C10.3092 2.39335 10.657 2.32049 11.0089 2.31764C11.3608 2.31478 11.7097 2.38198 12.0354 2.51532C12.361 2.64866 12.6569 2.84548 12.9057 3.0943C13.1545 3.34313 13.3513 3.63898 13.4847 3.96463C13.618 4.29028 13.6852 4.6392 13.6824 4.99108C13.6795 5.34296 13.6066 5.69075 13.468 6.01419C13.3294 6.33763 13.1278 6.63025 12.875 6.875L11.75 8Z"
                    stroke="#111111"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>{" "}
              <span className="font-lexend ml-2">CPO</span>
            </label>
            <Select
              variant="standard"
              inputProps={{ "aria-label": "Without label" }}
              value={values.cpo}
            >
              {props.allCPO.map((item: any, idx) => (
                <MenuItem
                  key={idx}
                  value={item.id}
                  onClick={() =>
                    item.isWhiteLabel
                      ? setValues({
                          ...values,
                          access: [
                            "Home",
                            "Charge Sessions",
                            "Users",
                            "Transactions",
                            "Reports",
                            "Payout",
                            "Bookings",
                            "Station List",
                            "Revenue",
                            "Energy Consumption",
                            "Push Notifications",
                            "Promo Codes",
                            "Assign Roles",
                          ],
                          cpo: item.id,
                        })
                      : setValues({
                          ...values,
                          access: [
                            "Home",
                            "Charge Sessions",
                            "Reports",
                            "Payout",
                            "Bookings",
                            "Station List",
                            "Revenue",
                            "Energy Consumption",
                            "Assign Roles",
                          ],
                          cpo: item.id,
                        })
                  }
                >
                  {item.companyName}
                </MenuItem>
              ))}
            </Select>
          </div>

          <br />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "17px",
            }}
          >
            {props.type == "add" ? (
              <>
                <label className="flex items-center mb-2 " htmlFor="password">
                  <span>
                    <svg
                      width="12"
                      height="17"
                      viewBox="0 0 12 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.25 14.75H2.25C1.85218 14.75 1.47064 14.592 1.18934 14.3107C0.908035 14.0294 0.75 13.6478 0.75 13.25V8.75C0.75 8.35218 0.908035 7.97064 1.18934 7.68934C1.47064 7.40804 1.85218 7.25 2.25 7.25H9.75C10.0137 7.2499 10.2728 7.31931 10.5011 7.45125C10.7294 7.58318 10.9189 7.77298 11.0505 8.0015M3 7.25V4.25C3 3.45435 3.31607 2.69129 3.87868 2.12868C4.44129 1.56607 5.20435 1.25 6 1.25C6.79565 1.25 7.55871 1.56607 8.12132 2.12868C8.68393 2.69129 9 3.45435 9 4.25V7.25M11.25 11V13.25M11.25 15.5V15.5075M5.25 11C5.25 11.1989 5.32902 11.3897 5.46967 11.5303C5.61032 11.671 5.80109 11.75 6 11.75C6.19891 11.75 6.38968 11.671 6.53033 11.5303C6.67098 11.3897 6.75 11.1989 6.75 11C6.75 10.8011 6.67098 10.6103 6.53033 10.4697C6.38968 10.329 6.19891 10.25 6 10.25C5.80109 10.25 5.61032 10.329 5.46967 10.4697C5.32902 10.6103 5.25 10.8011 5.25 11Z"
                        stroke="#111111"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>{" "}
                  <span className="font-lexend ml-2">Password</span>
                </label>

                <TextField
                  variant="standard"
                  name="password"
                  placeholder="Enter Password"
                  value={values.password}
                  type="password"
                  onChange={(e: any) =>
                    setValues({
                      ...values,
                      password: e.target.value,
                    })
                  }
                />
              </>
            ) : (
              ""
            )}
          </div>

          <div className="flex justify-between">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "48%",
                marginLeft: "17px",
              }}
            >
              <label className="flex items-center mb-2 mt-5" htmlFor="email">
                <span>
                  <svg
                    className="ml-2"
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.25 2.25C1.25 1.85218 1.40804 1.47064 1.68934 1.18934C1.97064 0.908035 2.35218 0.75 2.75 0.75H13.25C13.6478 0.75 14.0294 0.908035 14.3107 1.18934C14.592 1.47064 14.75 1.85218 14.75 2.25M1.25 2.25V9.75C1.25 10.1478 1.40804 10.5294 1.68934 10.8107C1.97064 11.092 2.35218 11.25 2.75 11.25H13.25C13.6478 11.25 14.0294 11.092 14.3107 10.8107C14.592 10.5294 14.75 10.1478 14.75 9.75V2.25M1.25 2.25L8 6.75L14.75 2.25"
                      stroke="#111111"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>{" "}
                <span className="font-lexend ml-2">Email</span>
              </label>
              <TextField
                id="input-with-sx"
                variant="standard"
                placeholder="Enter Email"
                helperText={error.email && "Please enter valid email"}
                value={values.email}
                onChange={(e: any) =>
                  setValues({
                    ...values,
                    email: e.target.value,
                  })
                }
                error={error.email}
              />
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", width: "48%" }}
            >
              <label className="flex items-center mb-2 mt-5" htmlFor="phone">
                <span>
                  <svg
                    className="ml-2"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.75 1H5.75L7.25 4.75L5.375 5.875C6.17822 7.50365 7.49635 8.82178 9.125 9.625L10.25 7.75L14 9.25V12.25C14 12.6478 13.842 13.0294 13.5607 13.3107C13.2794 13.592 12.8978 13.75 12.5 13.75C9.57445 13.5722 6.81512 12.3299 4.74262 10.2574C2.67013 8.18489 1.42779 5.42555 1.25 2.5C1.25 2.10218 1.40804 1.72064 1.68934 1.43934C1.97064 1.15804 2.35218 1 2.75 1Z"
                      stroke="#111111"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>{" "}
                <span className="font-lexend ml-2">Phone</span>
              </label>
              <TextField
                id="input-with-sx"
                placeholder="Enter Phone Number"
                variant="standard"
                helperText={error.phone && "Please Enter Valid Number"}
                value={values.phone}
                onChange={(e: any) =>
                  setValues({
                    ...values,
                    phone: e.target.value,
                  })
                }
                error={error.phone}
              />
            </div>
          </div>

          <br />
          <p className="pl-4 pb-2 font-lexend text-lg font-bold">
            Provide access from the following:
          </p>
          {/* Access Main Container */}
          <div className="flex justify-between">
            {/* 1st Column */}
            <div>
              {/* Dashboard */}
              <div className="flex flex-col h-20">
                <div className="flex items-center ml-3">
                  {" "}
                  <span>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.636 10.0002L3.125 12.125M8 1.25V5M8 1.25C8.88642 1.25 9.76417 1.42459 10.5831 1.76381C11.4021 2.10303 12.1462 2.60023 12.773 3.22703C13.3998 3.85382 13.897 4.59794 14.2362 5.41689C14.5754 6.23583 14.75 7.11358 14.75 8M8 1.25C7.11358 1.25 6.23583 1.42459 5.41689 1.76381C4.59794 2.10303 3.85382 2.60023 3.22703 3.22703C2.60023 3.85382 2.10303 4.59794 1.76381 5.41689C1.42459 6.23583 1.25 7.11358 1.25 8C1.25 8.88642 1.42459 9.76417 1.76381 10.5831C2.10303 11.4021 2.60023 12.1462 3.22703 12.773C3.85382 13.3998 4.59794 13.897 5.41689 14.2362C6.23583 14.5754 7.11358 14.75 8 14.75C8.88642 14.75 9.76417 14.5754 10.5831 14.2362C11.4021 13.897 12.1462 13.3998 12.773 12.773C13.3998 12.1462 13.897 11.4021 14.2362 10.5831C14.5754 9.76417 14.75 8.88642 14.75 8M8 5C8.79565 5 9.55871 5.31607 10.1213 5.87868C10.6839 6.44129 11 7.20435 11 8M8 5C7.20435 5 6.44129 5.31607 5.87868 5.87868C5.31607 6.44129 5 7.20435 5 8C5 8.79565 5.31607 9.55871 5.87868 10.1213C6.44129 10.6839 7.20435 11 8 11C8.79565 11 9.55871 10.6839 10.1213 10.1213C10.6839 9.55871 11 8.79565 11 8M11 8H14.75M9.66425 10.496L11.75 13.625"
                        stroke="#111111"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>{" "}
                  <span className="font-lexend ml-2 text-lg font-semibold">
                    Dashboard
                  </span>{" "}
                </div>
                <div>
                  {values.access.includes("Home") ? (
                    <div>
                      {" "}
                      <Checkbox
                        checked={values.access.includes("Home")}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Home")}
                      />{" "}
                      <span className="font-lexend">Home</span>
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <Checkbox
                        checked={false}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Home")}
                      />{" "}
                      <span className="font-lexend">Home</span>
                    </div>
                  )}
                  {values.access.includes("Charge Sessions") ? (
                    <div>
                      {" "}
                      <Checkbox
                        checked={values.access.includes("Charge Sessions")}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Charge Sessions")}
                      />{" "}
                      <span className="font-lexend">Charge Sessions</span>
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <Checkbox
                        checked={false}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Charge Sessions")}
                      />{" "}
                      <span className="font-lexend">Charge Sessions</span>
                    </div>
                  )}
                </div>
              </div>

              {/* Quick Menu */}
              <div className="flex flex-col mt-12">
                <div className="flex items-center ml-3">
                  <span>
                    <svg
                      width="15"
                      height="17"
                      viewBox="0 0 12 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.25 7H11.25M9.75 5.5V8.5M1.5 1H4.5C4.69891 1 4.88968 1.07902 5.03033 1.21967C5.17098 1.36032 5.25 1.55109 5.25 1.75V12.25C5.25 12.4489 5.17098 12.6397 5.03033 12.7803C4.88968 12.921 4.69891 13 4.5 13H1.5C1.30109 13 1.11032 12.921 0.96967 12.7803C0.829018 12.6397 0.75 12.4489 0.75 12.25V1.75C0.75 1.55109 0.829018 1.36032 0.96967 1.21967C1.11032 1.07902 1.30109 1 1.5 1Z"
                        stroke="#111111"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span className="ml-2 font-lexend text-lg font-semibold">
                    Quick Menu
                  </span>
                </div>

                <div>
                  {values.access.includes("Users") ? (
                    <div>
                      {" "}
                      <Checkbox
                        checked={values.access.indexOf("Users") > -1}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Users")}
                      />{" "}
                      <span className="font-lexend">Users</span>
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <Checkbox
                        checked={false}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Users")}
                      />{" "}
                      <span className="font-lexend">Users</span>
                    </div>
                  )}
                  {values.access.includes("Transactions") ? (
                    <div>
                      {" "}
                      <Checkbox
                        checked={values.access.indexOf("Transactions") > -1}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Transactions")}
                      />{" "}
                      <span className="font-lexend"></span>
                      Transactions
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <Checkbox
                        checked={false}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Transactions")}
                      />{" "}
                      <span className="font-lexend">Transactions</span>
                    </div>
                  )}
                  {values.access.includes("Reports") ? (
                    <div>
                      {" "}
                      <Checkbox
                        checked={values.access.indexOf("Reports") > -1}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Reports")}
                      />{" "}
                      <span className="font-lexend">Reports</span>
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <Checkbox
                        checked={false}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Reports")}
                      />{" "}
                      <span className="font-lexend">Reports</span>
                    </div>
                  )}
                  {values.access.includes("Payout") ? (
                    <div>
                      {" "}
                      <Checkbox
                        checked={values.access.indexOf("Payout") > -1}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Payout")}
                      />{" "}
                      <span className="font-lexend">Payout</span>
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <Checkbox
                        checked={false}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Payout")}
                      />
                      <span className="font-lexend">Payout</span>
                    </div>
                  )}
                  {values.access.includes("Invoices") ? (
                    <div>
                      {" "}
                      <Checkbox
                        checked={values.access.indexOf("Invoices") > -1}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Invoices")}
                      />{" "}
                      <span className="font-lexend">Invoices</span>
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <Checkbox
                        checked={false}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Invoices")}
                      />
                      <span className="font-lexend">Invoices</span>
                    </div>
                  )}
                  {values.access.includes("Bookings") ? (
                    <div>
                      {" "}
                      <Checkbox
                        checked={values.access.indexOf("Bookings") > -1}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Bookings")}
                      />{" "}
                      <span className="font-lexend">Bookings</span>
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <Checkbox
                        checked={false}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Bookings")}
                      />
                      <span className="font-lexend">Bookings</span>
                    </div>
                  )}

                  {values.access.includes("Fleet") ? (
                    <div>
                      {" "}
                      <Checkbox
                        checked={values.access.indexOf("Fleet") > -1}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Fleet")}
                      />{" "}
                      <span className="font-lexend">Fleet</span>
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <Checkbox
                        checked={false}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Fleet")}
                      />
                      <span className="font-lexend">Fleet</span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* 2nd Column */}
            <div>
              {/* Electric Vehicles */}
              <div className="flex flex-col">
                <div className="flex items-center ml-3">
                  <span>
                    <svg
                      width="17"
                      height="13"
                      viewBox="0 0 16 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.75 9.75C2.75 10.1478 2.90804 10.5294 3.18934 10.8107C3.47064 11.092 3.85218 11.25 4.25 11.25C4.64782 11.25 5.02936 11.092 5.31066 10.8107C5.59196 10.5294 5.75 10.1478 5.75 9.75M2.75 9.75C2.75 9.35218 2.90804 8.97064 3.18934 8.68934C3.47064 8.40804 3.85218 8.25 4.25 8.25C4.64782 8.25 5.02936 8.40804 5.31066 8.68934C5.59196 8.97064 5.75 9.35218 5.75 9.75M2.75 9.75H1.25V5.25M5.75 9.75H10.25M10.25 9.75C10.25 10.1478 10.408 10.5294 10.6893 10.8107C10.9706 11.092 11.3522 11.25 11.75 11.25C12.1478 11.25 12.5294 11.092 12.8107 10.8107C13.092 10.5294 13.25 10.1478 13.25 9.75M10.25 9.75C10.25 9.35218 10.408 8.97064 10.6893 8.68934C10.9706 8.40804 11.3522 8.25 11.75 8.25C12.1478 8.25 12.5294 8.40804 12.8107 8.68934C13.092 8.97064 13.25 9.35218 13.25 9.75M13.25 9.75H14.75V6.75C14.75 6.35218 14.592 5.97064 14.3107 5.68934C14.0294 5.40804 13.6478 5.25 13.25 5.25H12.5M1.25 5.25L2.75 1.5H9.5L12.5 5.25M1.25 5.25H12.5M8 5.25V1.5"
                        stroke="#111111"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span className="ml-2 font-lexend text-lg font-semibold">
                    Electric Vehicles
                  </span>
                </div>
                <div>
                  {values.access.includes("Vehicles") ? (
                    <div>
                      {" "}
                      <Checkbox
                        checked={values.access.indexOf("Vehicles") > -1}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Vehicles")}
                      />{" "}
                      <span className="font-lexend">Vehicles</span>
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <Checkbox
                        checked={false}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Vehicles")}
                      />{" "}
                      <span className="font-lexend">Vehicles</span>
                    </div>
                  )}
                  {values.access.includes("Manufacturers") ? (
                    <div>
                      {" "}
                      <Checkbox
                        checked={values.access.indexOf("Manufacturers") > -1}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Manufacturers")}
                      />{" "}
                      <span className="font-lexend">Manufacturers</span>
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <Checkbox
                        checked={false}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Manufacturers")}
                      />{" "}
                      <span className="font-lexend">Manufacturers</span>
                    </div>
                  )}
                </div>
              </div>
              {/* Stations */}
              <div className="flex flex-col mt-4">
                <div className="flex items-center ml-3">
                  <span>
                    <svg
                      width="15"
                      height="17"
                      viewBox="0 0 14 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.75 7.24978C4.75 7.84652 4.98705 8.41882 5.40901 8.84077C5.83097 9.26273 6.40326 9.49978 7 9.49978C7.59674 9.49978 8.16903 9.26273 8.59099 8.84077C9.01295 8.41882 9.25 7.84652 9.25 7.24978C9.25 6.65305 9.01295 6.08075 8.59099 5.65879C8.16903 5.23684 7.59674 4.99978 7 4.99978C6.40326 4.99978 5.83097 5.23684 5.40901 5.65879C4.98705 6.08075 4.75 6.65305 4.75 7.24978Z"
                        stroke="#111111"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.2428 11.4925L8.0605 14.6748C7.77924 14.9558 7.39794 15.1136 7.00038 15.1136C6.60282 15.1136 6.22151 14.9558 5.94025 14.6748L2.75725 11.4925C1.91817 10.6534 1.34675 9.58431 1.11527 8.42043C0.883782 7.25655 1.00262 6.05017 1.45676 4.95383C1.91089 3.85749 2.67993 2.92044 3.66661 2.26116C4.6533 1.60189 5.81333 1.25 7 1.25C8.18668 1.25 9.3467 1.60189 10.3334 2.26116C11.3201 2.92044 12.0891 3.85749 12.5433 4.95383C12.9974 6.05017 13.1162 7.25655 12.8847 8.42043C12.6533 9.58431 12.0818 10.6534 11.2428 11.4925Z"
                        stroke="#111111"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span className="ml-2 font-lexend text-lg font-semibold">
                    Stations
                  </span>
                </div>

                <div>
                  {values.access.includes("Station Map") ? (
                    <div>
                      {" "}
                      <Checkbox
                        checked={values.access.indexOf("Station Map") > -1}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Station Map")}
                      />{" "}
                      <span className="font-lexend">Station Map</span>
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <Checkbox
                        checked={false}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Station Map")}
                      />{" "}
                      <span className="font-lexend">Station Map</span>
                    </div>
                  )}
                  {values.access.includes("Station List") ? (
                    <div>
                      {" "}
                      <Checkbox
                        checked={values.access.indexOf("Station List") > -1}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Station List")}
                      />{" "}
                      <span className="font-lexend">Station List</span>
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <Checkbox
                        checked={false}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Station List")}
                      />{" "}
                      <span className="font-lexend">Station List</span>
                    </div>
                  )}
                </div>
              </div>
              {/* Analytics */}
              <div className="flex flex-col mt-4">
                <div className="flex items-center ml-3">
                  <span>
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.5 1V2.5M2.5 6.25V13M7 1V8.5M7 12.25V13M11.5 1V1.75M11.5 6.25V13M1 3.25C1 3.05109 1.07902 2.86032 1.21967 2.71967C1.36032 2.57902 1.55109 2.5 1.75 2.5H3.25C3.44891 2.5 3.63968 2.57902 3.78033 2.71967C3.92098 2.86032 4 3.05109 4 3.25V5.5C4 5.69891 3.92098 5.88968 3.78033 6.03033C3.63968 6.17098 3.44891 6.25 3.25 6.25H1.75C1.55109 6.25 1.36032 6.17098 1.21967 6.03033C1.07902 5.88968 1 5.69891 1 5.5V3.25ZM5.5 9.25C5.5 9.05109 5.57902 8.86032 5.71967 8.71967C5.86032 8.57902 6.05109 8.5 6.25 8.5H7.75C7.94891 8.5 8.13968 8.57902 8.28033 8.71967C8.42098 8.86032 8.5 9.05109 8.5 9.25V11.5C8.5 11.6989 8.42098 11.8897 8.28033 12.0303C8.13968 12.171 7.94891 12.25 7.75 12.25H6.25C6.05109 12.25 5.86032 12.171 5.71967 12.0303C5.57902 11.8897 5.5 11.6989 5.5 11.5V9.25ZM10 2.5C10 2.30109 10.079 2.11032 10.2197 1.96967C10.3603 1.82902 10.5511 1.75 10.75 1.75H12.25C12.4489 1.75 12.6397 1.82902 12.7803 1.96967C12.921 2.11032 13 2.30109 13 2.5V5.5C13 5.69891 12.921 5.88968 12.7803 6.03033C12.6397 6.17098 12.4489 6.25 12.25 6.25H10.75C10.5511 6.25 10.3603 6.17098 10.2197 6.03033C10.079 5.88968 10 5.69891 10 5.5V2.5Z"
                        stroke="#111111"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span className="ml-2 font-lexend text-lg font-semibold">
                    Analytics
                  </span>
                </div>

                <div>
                  {values.access.includes("Revenue") ? (
                    <div>
                      {" "}
                      <Checkbox
                        checked={values.access.indexOf("Revenue") > -1}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Revenue")}
                      />
                      <span className="font-lexend">Revenue</span>
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <Checkbox
                        checked={false}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Revenue")}
                      />
                      <span className="font-lexend">Revenue</span>
                    </div>
                  )}
                  {values.access.includes("Energy Consumption") ? (
                    <div>
                      {" "}
                      <Checkbox
                        checked={
                          values.access.indexOf("Energy Consumption") > -1
                        }
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Energy Consumption")}
                      />
                      <span className="font-lexend">Energy Consumption</span>
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <Checkbox
                        checked={false}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Energy Consumption")}
                      />
                      <span className="font-lexend">Energy Consumption</span>
                    </div>
                  )}
                </div>
              </div>
              {/* Projection */}
              <div className="flex flex-col mt-4">
                <div className="flex items-center ml-3">
                  <span>
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.5 1V2.5M2.5 6.25V13M7 1V8.5M7 12.25V13M11.5 1V1.75M11.5 6.25V13M1 3.25C1 3.05109 1.07902 2.86032 1.21967 2.71967C1.36032 2.57902 1.55109 2.5 1.75 2.5H3.25C3.44891 2.5 3.63968 2.57902 3.78033 2.71967C3.92098 2.86032 4 3.05109 4 3.25V5.5C4 5.69891 3.92098 5.88968 3.78033 6.03033C3.63968 6.17098 3.44891 6.25 3.25 6.25H1.75C1.55109 6.25 1.36032 6.17098 1.21967 6.03033C1.07902 5.88968 1 5.69891 1 5.5V3.25ZM5.5 9.25C5.5 9.05109 5.57902 8.86032 5.71967 8.71967C5.86032 8.57902 6.05109 8.5 6.25 8.5H7.75C7.94891 8.5 8.13968 8.57902 8.28033 8.71967C8.42098 8.86032 8.5 9.05109 8.5 9.25V11.5C8.5 11.6989 8.42098 11.8897 8.28033 12.0303C8.13968 12.171 7.94891 12.25 7.75 12.25H6.25C6.05109 12.25 5.86032 12.171 5.71967 12.0303C5.57902 11.8897 5.5 11.6989 5.5 11.5V9.25ZM10 2.5C10 2.30109 10.079 2.11032 10.2197 1.96967C10.3603 1.82902 10.5511 1.75 10.75 1.75H12.25C12.4489 1.75 12.6397 1.82902 12.7803 1.96967C12.921 2.11032 13 2.30109 13 2.5V5.5C13 5.69891 12.921 5.88968 12.7803 6.03033C12.6397 6.17098 12.4489 6.25 12.25 6.25H10.75C10.5511 6.25 10.3603 6.17098 10.2197 6.03033C10.079 5.88968 10 5.69891 10 5.5V2.5Z"
                        stroke="#111111"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span className="ml-2 font-lexend text-lg font-semibold">
                  Predictive Analytics
                  </span>
                </div>

                <div>
                  {values.access.includes("Revenue Projection") ? (
                    <div>
                      {" "}
                      <Checkbox
                        checked={values.access.indexOf("Revenue Projection") > -1}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Revenue Projection")}
                      />
                      <span className="font-lexend">Revenue Projection</span>
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <Checkbox
                        checked={false}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Revenue Projection")}
                      />
                      <span className="font-lexend">Revenue Projection</span>
                    </div>
                  )}
                  {values.access.includes("Energy Consumption Projection") ? (
                    <div>
                      {" "}
                      <Checkbox
                        checked={
                          values.access.indexOf("Energy Consumption Projection") > -1
                        }
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Energy Consumption Projection")}
                      />
                      <span className="font-lexend">Energy Consumption Projection</span>
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <Checkbox
                        checked={false}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Energy Consumption Projection")}
                      />
                      <span className="font-lexend">Energy Consumption Projection</span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* 3rd Column */}
            <div>
              {/* CRM Tools */}
              <div className="flex flex-col">
                <div className="flex items-center ml-3">
                  <span>
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.25 14.75V13.25C1.25 12.4544 1.56607 11.6913 2.12868 11.1287C2.69129 10.5661 3.45435 10.25 4.25 10.25H7.25C7.97 10.25 8.63 10.5035 9.1475 10.9258M11 1.3475C11.6453 1.51273 12.2173 1.88803 12.6257 2.41424C13.0342 2.94044 13.2559 3.58763 13.2559 4.25375C13.2559 4.91988 13.0342 5.56706 12.6257 6.09327C12.2173 6.61948 11.6453 6.99478 11 7.16M11 13.25H15.5M13.25 11V15.5M2.75 4.25C2.75 5.04565 3.06607 5.80871 3.62868 6.37132C4.19129 6.93393 4.95435 7.25 5.75 7.25C6.54565 7.25 7.30871 6.93393 7.87132 6.37132C8.43393 5.80871 8.75 5.04565 8.75 4.25C8.75 3.45435 8.43393 2.69129 7.87132 2.12868C7.30871 1.56607 6.54565 1.25 5.75 1.25C4.95435 1.25 4.19129 1.56607 3.62868 2.12868C3.06607 2.69129 2.75 3.45435 2.75 4.25Z"
                        stroke="#111111"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span className="ml-2 font-lexend text-lg font-semibold">
                    CRM Tools
                  </span>
                </div>
                <div>
                  {values.access.includes("Push Notifications") ? (
                    <div>
                      {" "}
                      <Checkbox
                        checked={
                          values.access.indexOf("Push Notifications") > -1
                        }
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Push Notifications")}
                      />
                      <span className="font-lexend">Push Notifications</span>
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <Checkbox
                        checked={false}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Push Notifications")}
                      />
                      <span className="font-lexend">Push Notifications</span>
                    </div>
                  )}
                  {values.access.includes("Promo Codes") ? (
                    <div>
                      {" "}
                      <Checkbox
                        checked={values.access.indexOf("Promo Codes") > -1}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Promo Codes")}
                      />
                      <span className="font-lexend">Promo Codes</span>
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <Checkbox
                        checked={false}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Promo Codes")}
                      />
                      <span className="font-lexend">Promo Codes</span>
                    </div>
                  )}
                </div>
              </div>

              {/* Admin Tools */}
              <div className="flex flex-col mt-4">
                <div className="flex items-center ml-3">
                  <span>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.00011 8C7.80119 8 7.61043 7.92098 7.46978 7.78033C7.32912 7.63968 7.25011 7.44891 7.25011 7.25C7.25011 7.05109 7.32912 6.86032 7.46978 6.71967C7.61043 6.57902 7.80119 6.5 8.00011 6.5C8.19902 6.5 8.38978 6.57902 8.53044 6.71967C8.67109 6.86032 8.75011 7.05109 8.75011 7.25C8.75011 7.44891 8.67109 7.63968 8.53044 7.78033C8.38978 7.92098 8.19902 8 8.00011 8ZM8.00011 8V9.875M8.00012 1.25C9.75199 2.79993 12.0385 3.60692 14.3751 3.5C14.7153 4.65726 14.8194 5.8711 14.6812 7.06939C14.543 8.26767 14.1654 9.42595 13.5707 10.4754C12.976 11.5248 12.1764 12.444 11.2195 13.1784C10.2625 13.9127 9.16771 14.4472 8.00012 14.75C6.83253 14.4472 5.73773 13.9127 4.78078 13.1784C3.82383 12.444 3.02425 11.5248 2.42957 10.4754C1.83488 9.42595 1.45722 8.26767 1.31903 7.06939C1.18083 5.8711 1.28493 4.65726 1.62512 3.5C3.96177 3.60692 6.24825 2.79993 8.00012 1.25Z"
                        stroke="#111111"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span className="ml-2 font-lexend text-lg font-semibold">
                    Admin Tools
                  </span>
                </div>
                <div>
                  {values.access.includes("Assign Roles") ? (
                    <div>
                      {" "}
                      <Checkbox
                        checked={values.access.indexOf("Assign Roles") > -1}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Assign Roles")}
                      />
                      <span className="font-lexend">Assign Roles</span>
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <Checkbox
                        checked={false}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Assign Roles")}
                      />
                      <span className="font-lexend">Assign Roles</span>
                    </div>
                  )}
                  {values.access.includes("Create CPO") ? (
                    <div>
                      {" "}
                      <Checkbox
                        checked={values.access.indexOf("Create CPO") > -1}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Create CPO")}
                      />
                      <span className="font-lexend">Create CPO</span>
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <Checkbox
                        checked={false}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Create CPO")}
                      />
                      <span className="font-lexend">Create CPO</span>
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-12">
                <button
                  className="flex justify-around items-center px-3 py-2 rounded-full ml-6"
                  style={{ background: "#1AC47D", border: "2px solid #1AAD70" }}
                  onClick={() => {
                    props.type === "add" ? submitAddUser() : submitEditUser();
                  }}
                >
                  <span className="mr-2">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.75 9L7.5 12.75L15 5.25"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span className="font-lexend pr-2" style={{ color: "white" }}>
                    Submit
                  </span>
                </button>
              </div>
            </div>
          </div>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default AddEditModal;
